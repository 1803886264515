import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
const FooterInterView = () => {
    return (
        <div>
            <Container fluid className='px-4 mt-2 py-4 footer-position'>
                <Row>
                    <Col className='footer-link'>
                        <div className='d-flex justify-content-between align-items-center flex-direction-mob'>
                            <div>
                                <p>Copyright © 2022 <span>projectR</span>. All Rights Reserved.</p>
                            </div>
                            {/* <div>
                                <div className='d-flex gap-4'>
                                    <div>
                                        <p>help</p>
                                    </div>
                                    <div>
                                        <p>Settings</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FooterInterView