import React, { useState, useLayoutEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import ModalOfferDetails from '../ModalPopup/ModalOfferDetails';
import Moment from 'moment';
import { postRequest } from '../Services/api';
import Spinner from 'react-bootstrap/Spinner';

const SearchedData = () => {
    const [show, setShow] = useState(false);
    const [emailOffered, setViewOffers] = useState([]);
    const [candidateName, setCandidateName] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleShow = (email, candidateName) => {
        setShow(true);
        setViewOffers(email);
        setCandidateName(candidateName);
    }
    const handleClose = () => setShow(false);
    const [searchList, getSearchList] = useState([]);
    useLayoutEffect(() => {
        getSearch();
    }, []);
    const getSearch = async () => {
        var res = await postRequest('api/Profile/GetSearchProfile?isDashboard=true');
        getSearchList(JSON.parse(res?.data?.Data));
        setLoading(!loading);
    }

    return (
        <div>
            <div >
                <Container fluid className=''>
                    <Row>
                        <Col className='bg-white'>
                            <div className='searched-table-heading'>
                                <h3>Recently Searched Profile</h3>
                            </div>

                            <Table striped responsive borderless className='searched-table'>
                                <thead>
                                    <tr>
                                        <th>Searched On</th>
                                        <th>Candidate name</th>
                                        <th className='text-center'>current offer</th>
                                        <th>mobile number</th>
                                        <th>email </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ?
                                        <tr>
                                            <td class="text-center" colSpan={10}> <div class="positioning-spinner">
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden text-center">Loading...</span>
                                                </Spinner>
                                            </div></td>
                                        </tr>
                                        :
                                        searchList.length == 0 ?
                                            <tr>
                                                <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                                            </tr>



                                            :
                                            searchList.map((item) => (
                                                <tr>
                                                    <td>{Moment(item.SearchedDate).format('MM/DD/YYYY HH:mm:ss') + ' ' + item.TimeZone}</td>
                                                    <td> <Link to="/dashboard" className='search-name-text-color' onClick={() => handleShow(item.Email + item.PhoneNumber, item.CandidateName)}>{item.CandidateName}</Link></td>
                                                    <td className='text-center'>{item.OfferCount}</td>
                                                    <td>{item.PhoneNumber}</td>
                                                    <td>{item.Email}</td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ModalOfferDetails candidateName={candidateName} email={emailOffered} show={show} onClose={handleClose} />

        </div>
    )
}

export default SearchedData