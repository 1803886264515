import React, { useEffect, useState, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { FaAddressCard, FaUserAlt, FaPhoneAlt, FaEnvelope, FaCalendarAlt, FaPenSquare, FaRegCheckCircle } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill1Wave, faSackDollar, faCheckToSlot, faBriefcase, faClipboardList, faRectangleList } from '@fortawesome/free-solid-svg-icons'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FaChevronRight } from 'react-icons/fa'
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { postRequest, postFormData } from '../Services/api';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import Moment from 'moment';
import { result } from 'lodash';
import SideNavbar from '../Dashboard/SideNavbar';
import Footer from '../Dashboard/Footer';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import { EditorState } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import InterviewListTable from './InterviewListTable';
import { Table } from 'react-bootstrap';
import InterviewNotes from '../Profiles/Components/InterviewNotes'
import RecentNotes from './Components/RecentNotes';
import InterviewDocuments from './Components/InterviewDocuments';
import InterviewVideo from './Components/InterviewVideo';
import Accordion from './Common/Accordion';
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import InterviewLocation from './Components/InterviewLocation';

const InterviewProfileview = () => {
    const childRef = useRef();
    const location = useLocation()
    const path = location.pathname.split('/');
    let id = parseInt(path[3]);
    const navigate = useNavigate();

    const init = {
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        Email: "",
        JobTitle: "",
        currency: "",
        SalaryValue: "",
        ExperienceValue: "",
        JoinDate: "",
        Status: "Pending",
        EmployementType: "",
        ProfileDocument: "",
        UserId: 1,
        Notes: "",
        CompanyId: 1,
        InterviewLevel: "",
        Expected_start_Date: "",
        CreatedOn: "",
        countvalue: "",
        IsMessage: "",
        maxInterviewLinkId: ""
    }
    const [formData, setData] = useState(init);
    const [loading, setLoading] = useState(false);
    // const [shareProfileupdate, setShareProfileUpdate] = useState();
    const [categoryData, setCategoryData] = useState([]);
    // console.log(jobSalaryListFilter)

    const [formDataCategory, setFormDataCategory] = useState("")
    const [interviewLevelProof, setInterviewLevelProof] = useState("")
    useEffect(() => {
        getCategory()
    }, [formDataCategory])
    let isLoggedIn = JSON.parse(localStorage.getItem('token'))
    const getCategory = async () => {
        var res = await postRequest(`api/Interview/GetCompanyById?CompanyId=${isLoggedIn?.CompanyId}`);
        let resultCategory = JSON.parse(res?.data?.Data)
        const filteredCategories = resultCategory.filter((item) => item?.CategoryId == formDataCategory);
        setCategoryData(filteredCategories[0]?.CategoryName);
    }
    const getMyProfile = async () => {
        setData(() => "")

        var res = await postRequest(`api/ScheduleInterview/GetScheduleInterviewDetails?ProfileId=${id}`);


        let result = JSON.parse(res?.data?.Data)[0];
        // let date = result?.Expected_start_Date == null ? "" :  result?.Expected_start_Date
        //     ?.slice(0, 10)
        setFormDataCategory(result?.CategoryId)

        setData({
            FirstName: result.FirstName == "undefined" ? "" : result.FirstName,
            LastName: result.LastName == "undefined" ? "" :result.LastName,
            PhoneNumber: result.PhoneNumber,
            Email: result.Email,
            JobTitle: result?.JobTitle == "null" || result?.JobTitle == "undefined" ? "" : result.JobTitle,
            ExperienceValue: result?.ExperienceValue,
            Expected_start_Date: result?.JoinDate,
            EmployementType: result?.EmployementType == "undefined" ? "" : result?.EmployementType,
            // JoinDate: date,
            CreatedOn: result?.CreatedOn,
            currency: result?.CurrencyId,
            countvalue: result?.countvalue,
            SalaryValue: result?.SalaryValue == "null" ? "" : result?.SalaryValue,
            InterviewLevel: result?.InterviewLevel == "null" || result?.InterviewLevel == "undefined" ? "" : result.InterviewLevel,
            // document: result?.ProfileDocument.split('/')[1],
            Category: result?.CategoryId == "null" ? "" : result?.CategoryId,
            IsMessage: result?.IsMessage === 1 || result?.IsMessage == null  ? true : false,
            maxInterviewLinkId: result?.maxInterviewLinkId == "null" ? "" : result?.maxInterviewLinkId,
            Notes: result?.Notes == "null" || result?.Notes == "undefined" ? "" : result.Notes,
        })
        setInterviewLevelProof(result?.InterviewLevel == "null" || result?.InterviewLevel == "undefined" ? "" : result.InterviewLevel)
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const [recentNotes, setRecentNotes] = useState([]);

    const [initialEditorState, setInitialEditorState] = useState(
        () => EditorState.createEmpty()
    );
    const [editorState, setEditorState] = useState(initialEditorState);
    const [notesStatus, setNotesStatus] = useState(false)
    const [notesUpdateStatus, setNotesUpdateStatus] = useState(false)
    const [notesStatusDelete, setNotesStatusDelete] = useState(false)
    const [NotesupdateButton, setNotesUpdateButton] = useState(false)
    const [replyStatus, setReplyStatus] = useState(false)
    const [notesId, setNotesId] = useState(null)
    useEffect(() => {
        getScheduleRecentNotes();
        setNotesUpdateStatus(false)
        setNotesStatusDelete(false)
        setNotesStatus(false)
    }, [id, notesStatus, notesStatusDelete, notesUpdateStatus]);



    const getScheduleRecentNotes = async () => {
        var res = await postRequest(`api/ScheduleInterview/GetNotesByProfileId?ProfileId=${id}`);
        var result = JSON.parse(res?.data?.Data);
        setRecentNotes(result);
    }
    const DeleteNotes = async (notesIdDelete, CompanyId, UserId) => {
        var res = await postRequest(`api/ScheduleInterview/DeleteNotes?InterviewNotesId=${notesIdDelete}&CompanyId=${CompanyId}&userId=${UserId}`);
        if (res?.data?.SuccessMessage === "Success") {
            setNotesStatusDelete(true)
            // setEditorState(initialEditorState)
        }
    }

    const html = convertToHTML(editorState.getCurrentContent());
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    const AddNotes = () => {
        if (html != "<p></p>") {
            const data = {
                "ProfileId": id,
                "Notes": html,
                "CompanyId": isLoggedIn?.CompanyId
            }

            postRequest('api/ScheduleInterview/InsertNotes', data).then((res) => {
                if (res?.data?.SuccessMessage === "Success") {
                    setNotesStatus(true)
                    setEditorState(initialEditorState)
                }
            })

        }
    }
    const ReplyNotes = () => {
        setReplyStatus(true)

    }
    const ReplyNotesAdd = () => {
        if (html != "") {
            const data = {
                "ProfileId": id,
                "Notes": html,
                "CompanyId": isLoggedIn?.CompanyId
            }

            postRequest('api/ScheduleInterview/InsertNotes', data).then((res) => {
                if (res?.data?.SuccessMessage === "Success") {
                    setNotesStatus(true)
                    setReplyStatus(false)
                    setEditorState(initialEditorState)
                }
            })

        }
    }
    const NotesEdit = async (notesId) => {
        var res = await postRequest(`api/ScheduleInterview/SelectNotes?InterviewNotesId=${notesId}`);
        var result = JSON.parse(res?.data?.Data)[0];
        const htmlContent = result?.Notes == "null" || result?.Notes == "undefined" ? "" : result.Notes;
        const contentState = ContentState.createFromBlockArray(convertFromHTML(htmlContent));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
        setNotesUpdateButton(true)
        setNotesId(notesId)
    }
    const UpdateNotes = async () => {
        if (html != "<p></p>") {
            const data = {
                "InterviewNotesId": notesId,
                "ProfileId": id,
                "Notes": html,
                "UpdatedBy": isLoggedIn?.userId,
                "CompanyId": isLoggedIn?.CompanyId
            }

            await postRequest('api/ScheduleInterview/UpdateNotes', data).then((res) => {
                if (res?.data?.SuccessMessage === "Success") {
                    setNotesUpdateStatus(true)
                    setEditorState(initialEditorState)
                    setNotesUpdateButton(false)
                }
            })

        }
    }
    const [mapData, setMapData] = useState({})
    const [videoData, setVideoData] = useState({})
    // console.log(videoData)
    const getLocation = async () => {
        await postRequest(`api/ScheduleInterview/GetLocation?ProfileId=${id}`).then((res) => {
            let data = JSON.parse(res?.data?.Data)[0]
            setMapData(data)
            //    console.log(Object.keys(data))

        })
    }
    const getVideo = async () => {
        setLoading(true)
        if (formData?.IsMessage ) {

            await postRequest(`api/ScheduleInterview/GetVideoDocumentInterView?ProfileId=${id}&IsMessage=${true}&InterviewLinkId=${formData?.maxInterviewLinkId}`).then((res) => {
                
                if (res?.data?.Status) {
                    let data = JSON.parse(res?.data?.Data)
                    setVideoData(data)
                    setLoading(false)
                }

                //    console.log(Object.keys(data))

            })
        }

    }
    const handleVideoDelete = async (interviewid, MergeVideoPath) => {
        await postRequest(`api/ScheduleInterview/DeleteVideo?InterviewLinkId=${interviewid}&ProfileId=${id}`).then((res) => {
           
            if (res?.data?.Status) {
                getVideo()
                toast.success("The interview video has been deleted successfully")
            }

            //    console.log(Object.keys(data))

        })
    }
    useEffect(() => {
        getVideo()
        getMyProfile()
        getLocation()

    }, [])
    useEffect(() => {
        if (formData?.IsMessage ) {
            getVideo()
        }
    }, [formData?.IsMessage])
    const latitude = 11.0825084;
    const longitude = 76.9673231;
    const sections = [
        {
            title: "Interview Documents",
            content: <InterviewDocuments profileId={id} interviewLevelProof={interviewLevelProof} />,
        },
        {
            title: "Interview Video",
            content: <InterviewVideo profileId={id} videoData={videoData} loading={loading} handleVideoDelete={handleVideoDelete} />,
        },
        {
            title: "Interview Location",
            content: <InterviewLocation profileId={id} latitude={latitude} longitude={longitude} mapData={mapData} />,
        },
    ];
    return (
        <SideNavbar>
            <div>
                <Container fluid className='p-0 g-0' >
                    <Row className='g-0'>
                        <Col lg={6} xs={12} sm={12} className="mob-none">
                            <div className='breadcrumps-box'>
                                <div className='breadcrump-heading'>
                                    <p>{!id ? "Interview profile view" : "Interview profile view"}</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} sm={12} className='breadcrumps-box  d-flex justify-content-end '>
                            <Breadcrumbs separator={<FaChevronRight className='text-white-breadcrums ' style={{ fontSize: "15px" }} />} >
                                <Link to='/' className='breadcrumps-links breadcrumps-links'>Dashboard</Link>
                                <Link to='/schedule-interview' className='breadcrumps-links breadcrumps-links'>Schedule interview</Link>
                                <Typography className='text-white-breadcrums cus-mr-5' >{!id ? "Interview profile view" : "Interview profile view"}</Typography>
                            </Breadcrumbs>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className=' '>
                    <Row>
                        <Col >


                            <div className='bg-white title-interview-docs desktop-viewdetails-table p-4 mt-2'>
                                <h4>Candidate Information</h4>
                                <Row>
                                    <Col lg={4} sm={12}>

                                        <Table responsive borderless className='searched-table' >
                                            <tr>
                                                <td className='font-weight-bold-cus'>Candidate name</td>
                                                <td>:</td>
                                                <td>{formData.FirstName === undefined ? "" : formData.FirstName } { formData.LastName === undefined ? "" : formData.LastName}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Mobile number</td>
                                                <td>:</td>
                                                <td>{formData.PhoneNumber}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Email Id</td>
                                                <td>:</td>
                                                <td>{formData.Email}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Job Title</td>
                                                <td>:</td>
                                                <td>{formData.JobTitle}</td>
                                            </tr>
                                            {/* <tr>
                                                <td className='font-weight-bold-cus'>Currency</td>
                                                <td>:</td>
                                                <td>Dollar</td>
                                            </tr> */}
                                            <tr>
                                                <td className='font-weight-bold-cus'>Level</td>
                                                <td>:</td>
                                                <td>{formData.InterviewLevel}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Skill category</td>
                                                <td>:</td>
                                                <td>{categoryData} </td>
                                            </tr>

                                        </Table>
                                    </Col>
                                    <Col lg={4} sm={12}>
                                        <Table responsive borderless className='searched-table' >
                                            <tr>
                                                <td className='font-weight-bold-cus'>Expected start Date</td>
                                                <td>:</td>
                                                <td>{formData.Expected_start_Date == null ? "" : Moment(formData.Expected_start_Date).format('MM/DD/YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Experience</td>
                                                <td>:</td>
                                                <td>{formData?.ExperienceValue}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Salary</td>
                                                <td>:</td>
                                                <td>{formData.SalaryValue}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Created On</td>
                                                <td>:</td>
                                                <td>{Moment(formData.CreatedOn).format('MM/DD/YYYY ')}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus '>Employment</td>
                                                <td>:</td>
                                                <td className='text-capitalize'>{formData.EmployementType}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Number of attempts  </td>
                                                <td>:</td>
                                                <td>{formData.countvalue}</td>
                                            </tr>
                                        </Table>
                                    </Col>
                                </Row>

                            </div>
                            <div className='bg-white title-interview-docs mob-viewdetails-table p-4 mt-2'>
                                <h4>Candidate Information</h4>
                                <Row>
                                    <Col lg={4} sm={12}>

                                        <Table responsive borderless className='searched-table' >
                                            <tr>
                                                <td className='font-weight-bold-cus'>Candidate name</td>
                                                <td>:</td>
                                                <td>{formData.FirstName + " " + formData.LastName}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Mobile number</td>
                                                <td>:</td>
                                                <td>{formData.PhoneNumber}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Email Id</td>
                                                <td>:</td>
                                                <td>{formData.Email}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Job Title</td>
                                                <td>:</td>
                                                <td>{formData.JobTitle}</td>
                                            </tr>
                                            {/* <tr>
                                                <td className='font-weight-bold-cus'>Currency</td>
                                                <td>:</td>
                                                <td>Dollar</td>
                                            </tr> */}
                                            <tr>
                                                <td className='font-weight-bold-cus'>Level</td>
                                                <td>:</td>
                                                <td>{formData.InterviewLevel}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Skill category</td>
                                                <td>:</td>
                                                <td>{categoryData} </td>
                                            </tr>

                                            <tr>
                                                <td className='font-weight-bold-cus'>Expected start Date</td>
                                                <td>:</td>
                                                <td>{Moment(formData.JoinDate).format('MM/DD/YYYY ')}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Experience</td>
                                                <td>:</td>
                                                <td>{formData?.ExperienceValue}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Salary</td>
                                                <td>:</td>
                                                <td>{formData.SalaryValue}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Created On</td>
                                                <td>:</td>
                                                <td>{Moment(formData.CreatedOn).format('MM/DD/YYYY ')}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus '>Employment</td>
                                                <td>:</td>
                                                <td className='text-capitalize'>{formData.EmployementType}</td>
                                            </tr>
                                            <tr>
                                                <td className='font-weight-bold-cus'>Number of attempts  </td>
                                                <td>:</td>
                                                <td>{formData.countvalue}</td>
                                            </tr>
                                        </Table>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className='bg-white p-4 mt-2'>
                                <InterviewNotes profileId={id} editorState={editorState} setEditorState={setEditorState} AddNotes={AddNotes} initialEditorState={initialEditorState} UpdateNotes={UpdateNotes} NotesupdateButton={NotesupdateButton} replyStatus={replyStatus} ReplyNotesAdd={ReplyNotesAdd} setReplyStatus={setReplyStatus} setNotesUpdateButton={setNotesUpdateButton} />

                                <RecentNotes profileId={id} recentNotes={recentNotes} NotesEdit={NotesEdit} DeleteNotes={DeleteNotes} ReplyNotes={ReplyNotes} UserId={isLoggedIn?.userId} />
                            </div>
                        </Col>
                        <Col>
                            <div className='bg-white p-4 mt-2'>
                                <Accordion sections={sections} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </SideNavbar>

    )
}

export default InterviewProfileview