import React, { useEffect, useState, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { FaAddressCard, FaUserAlt, FaPhoneAlt, FaEnvelope, FaCalendarAlt, FaPenSquare, FaRegCheckCircle } from "react-icons/fa";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill1Wave, faSackDollar, faCheckToSlot, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import SharedProfileTable from '../Profiles/SharedProfileTable';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FaChevronRight } from 'react-icons/fa'
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';
import { postRequest, postFormData } from '../Services/api';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import Moment from 'moment';
import { result } from 'lodash';
import SideNavbar from '../Dashboard/SideNavbar';
import Footer from '../Dashboard/Footer';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const SharedProfile = () => {
    const childRef = useRef();
    const location = useLocation()
    //console.log(location.pathname)
    const path = location.pathname.split('/');
    let id = parseInt(path[2]);
    const navigate = useNavigate();
    // console.log(id)

    // const [filePath, setFilePath] = useState([])


    const init = {
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        Email: "",
        JobTitle: "",
        currency: "",
        SalaryId: "",
        ExperienceId: "",
        JoinDate: "",
        Status: "Created",
        EmployementType: "",
        ProfileDocument: "",
        UserId: 1,
        Notes: "",
        CompanyId: 1
    }
    const [formData, setData] = useState(init);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    // const [shareProfileupdate, setShareProfileUpdate] = useState();
    const [selectedFile, setSelectedFile] = useState([]);
    const [JobProfileList, getJobProfileList] = useState([]);
    const [JobTitleList, getJobTitleList] = useState([]);

    const [jobSalaryList, getSalaryList] = useState([]);
    const [jobSalaryListFilter, getSalaryListFilter] = useState([]);
    const [jobExperienceList, getExperienceList] = useState([]);
    const [jobCurrencyList, getCurrencyList] = useState([])
    const [dateApi, setDateApi] = useState()
    const [salaryIdDis, setSalaryId] = useState()
    const [joinDateClear, setJoinDateClear] = useState()
    // console.log(jobSalaryListFilter)
    const [initialEditorState, setInitialEditorState] = useState(
        () => EditorState.createEmpty()
    );
    const [editorState, setEditorState] = useState(initialEditorState);

    const html = convertToHTML(editorState.getCurrentContent());
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }
    useEffect(() => {
        getMyProfile();
        setData({
            ProfileDocument: ""
        })



        $("#customFile")
            .next(".custom-file-label")
            .html("Select a file").val("");
        setSelectedFile("")

    }, [id, salaryIdDis])
    useEffect(() => {
        if (id.Length == undefined) {

            setData({
                FirstName: "",
                LastName: "",
                PhoneNumber: "",
                Email: "",
                JobTitle: "",
                currency: "",
                SalaryId: "",
                ExperienceId: "",
                JoinDate: "",
                Status: "Created",
                EmployementType: "",
                ProfileDocument: "",
                UserId: 1,
                Notes: "",
                CompanyId: 1
            })
            setMobValue("")
        }
    }, [id])
    const getMyProfile = async () => {
        setData(() => "")
        setData({
            ProfileDocument: ""
        })
        errorMessageMob("")
        setFormErrors({});
        var res = await postRequest(`api/Profile/GetSharedProfile?profileId=${id}`);

        let result = JSON.parse(res?.data?.Data)[0];
        let date = result?.JoinDate?.slice(0, 10)
        // console.log(result?.EmployementType);
        setData({
            FirstName: result.FirstName,
            LastName: result.LastName,
            PhoneNumber: result.PhoneNumber,
            Email: result.Email,
            JobTitle: result?.JobTitle == "null" || result?.JobTitle == "undefined" ? "" : result.JobTitle,
            ExperienceId: result.ExperienceId,
            // JoinDate: result.JoinDate,
            EmployementType: result?.EmployementType == "undefined" ? "" : result?.EmployementType,
            JoinDate: date,
            currency: result?.CurrencyId,
            SalaryId: result?.SalaryId == "null" ? "" : result?.SalaryId,

            // document: result?.ProfileDocument.split('/')[1],
            // Notes: result?.Notes == "null" || result?.Notes == "undefined" ? "" : result.Notes,
        })
        $("#customFile").val("")
        // const htmlContent = result?.Notes == "null" || result?.Notes == "undefined" ? "" : result.Notes;
        // const contentState = ContentState.createFromBlockArray(convertFromHTML(htmlContent));
        // const editorState = EditorState.createWithContent(contentState);
        // setEditorState(editorState)
        setJoinDateClear(result.JoinDate)
        setDialCode(result.PhoneCode)
        setSalaryId(result?.SalaryId)
        setMobValue(`${result.PhoneCode}${result.PhoneNumber}`)
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    let [errorMob, setErrorMob] = React.useState("");
    const [dialCode, setDialCode] = useState()
    const [countryCodePhone, setCountryCodePhone] = useState();
    const [mobValue, setMobValue] = useState()
    const [PhoneNumber, setPhoneNumber] = useState();
    // console.log(mobValue)
    const handleChangeMob = (mobValue, data, event) => {

        setMobValue(mobValue);
        setDialCode(data.dialCode)
        setCountryCodePhone(data.countryCode)
        // console.log(mobValue.slice(data.dialCode.length))
        let Phone = mobValue.slice(data.dialCode.length)
        // console.log(Phone)
        setPhoneNumber(Phone)


    }
    const errorMessageMob = (message) => {
        setErrorMob(message);
        // setTimeout(() => setError(""), 2000);
    }

    const handleUpdate = (event) => {
        event.preventDefault();
        setFormErrors(validate(formData));
        setLoading(true);
        // console.log(formData)
        if (Object.keys(validate(formData)).length === 0) {
            const data = new FormData()
            const capitalizedFirstName = formData.FirstName.charAt(0).toUpperCase() + formData.FirstName.slice(1);
            const capitalizedLastName = formData.LastName.charAt(0).toUpperCase() + formData.LastName.slice(1);
            data.append('ProfileDocument', selectedFile)
            data.append('FirstName', capitalizedFirstName)
            data.append('LastName', capitalizedLastName)
            data.append('Email', formData.Email)
            data.append('PhoneNumber', PhoneNumber)
            data.append('PhoneCode', dialCode)
            data.append('CountryCode', countryCodePhone)
            data.append('JobTitle', formData.JobTitle)
            data.append('Status', 'Created')
            data.append('SalaryId', formData.SalaryId == null ? "" : formData.SalaryId)
            data.append('ExperienceId', formData.ExperienceId == null ? "" : formData.ExperienceId)
            data.append('JoinDate', formData.JoinDate == undefined ? "" : formData.JoinDate)
            data.append('EmployementType', formData.EmployementType == undefined ? "" : formData.EmployementType)
            data.append('Notes', html)
            data.append('ProfileId', id)
            debugger;
            postFormData('api/Profile/SharedProfileUpdate', data).then((res) => {
                setLoading(false);
                if (!res?.data?.Status) {
                    if (res?.data?.ErrorMessage == 'Already Exist') {
                        toast.error('The email or mobile number already exists');

                    }
                    else {
                        toast.error(res?.data?.ErrorMessage ? res?.data?.ErrorMessage : res?.data?.message);
                    }
                }
                else {
                    let result = JSON.parse(res?.data?.Data)
                    if (html != "<p></p>") {
                        let dataNotes = {
                            "ProfileId": id,
                            "Notes": html,
                            "CompanyId": result?.CompanyId
                        }
                        postRequest('api/ScheduleInterview/InsertNotes', dataNotes).then((res) => {

                        })
                    }
                    toast.success('Profile updated successfully');
                    setData(initialvalueVal
                    );
                    setEditorState(initialEditorState);
                    navigate('/shareprofile');
                    setMobValue(() => "")
                    childRef.current.getSharedProfileChild();
                }
            });

        }
        else {
            setLoading(false);
        }
    }

    const handleCurrencyChange = (event, values) => {
        const { name, value } = event.target
        setData(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }

        });

        getSalaryListFilter(jobSalaryList.filter((x) => x.CurrencyId === event.target.value));
    }

    // console.log(jobSalaryList)

    useEffect(() => {
        getSalaryListFilter(jobSalaryList.filter((x) => x.CurrencyId === formData?.currency))
        // console.log(id)
    }, [formData, id])


    const handleChange = (event, values) => {
        const { name, value } = event.target
        setData(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        });
    }

    const ref = React.useRef();

    const handleInputFile = (event) => {
        setSelectedFile(event.target.files[0]);
        const MIN_FILE_SIZE = 1024
        const fileSizeKiloBytes = selectedFile.size / 1024
        if (fileSizeKiloBytes > MIN_FILE_SIZE) {
            toast.error("Selected file is greater then 1MB");
            event.preventDefault();
        }
        let filetype = event.target.files[0].name.split('.')[event.target.files[0].name.split('.').length - 1];
        if (filetype != 'pdf') {
            toast.error("Selected file is not valid");
            event.preventDefault();
        }

    }
    const [instance, setInstance] = useState();
    useEffect(() => {
        const generateAndLoadDocument = async () => {
            const buffer = await instance.Core.officeToPDFBuffer(selectedFile);
            const blob = new Blob([buffer], { type: 'application/pdf' });
        };
        if (instance) {
            generateAndLoadDocument();
        }


    }, [instance, selectedFile]);

    const initialvalueVal = {
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        Email: "",
        JobTitle: "",
        currency: "",
        SalaryId: "",
        ExperienceId: "",
        JoinDate: "",
        Status: "Created",
        EmployementType: "",
        ProfileDocument: "",
        Notes: ""
    }


    const handleSubmit = (event) => {
        event.preventDefault();


        setFormErrors(validate(formData));


        if (mobValue == undefined || mobValue.length == 0) {
            errorMessageMob("Mobile number is required");
            return;
        }

        if (PhoneNumber.length < 8) {
            errorMessageMob("Invalid mobile number");
            return;
        }
        else {
            errorMessageMob(" ");
            setLoading(true);

            if (Object.keys(validate(formData)).length === 0) {
                errorMessageMob("")
                const capitalizedFirstName = formData.FirstName.charAt(0).toUpperCase() + formData.FirstName.slice(1);
                const capitalizedLastName = formData.LastName.charAt(0).toUpperCase() + formData.LastName.slice(1);
                const data = new FormData()

                data.append('ProfileDocument', selectedFile)
                data.append('FirstName', capitalizedFirstName)
                data.append('LastName', capitalizedLastName)
                data.append('Email', formData.Email)
                data.append('PhoneNumber', PhoneNumber)
                data.append('PhoneCode', dialCode)
                data.append('CountryCode', countryCodePhone)
                data.append('JobTitle', formData.JobTitle)
                data.append('Status', 'Created')
                data.append('SalaryId', formData.SalaryId == undefined ? "" : formData.SalaryId)
                data.append('ExperienceId', formData.ExperienceId == undefined ? "" : formData.ExperienceId)
                data.append('JoinDate', formData.JoinDate == undefined ? "" : formData.JoinDate)
                data.append('EmployementType', formData.EmployementType)
                data.append('Notes', html)
                postFormData('api/Profile/SharedProfile', data).then((res) => {
                    setLoading(false);
                    if (!res?.data?.Status) {
                        if (res?.data?.ErrorMessage == 'Already Exist') {
                            toast.error('Mobile number / Email already exists');
                        }
                        else {
                            toast.error(res?.data?.ErrorMessage);
                        }
                    }
                    else {
                        let result = JSON.parse(res?.data?.Data)
                        if (html != "<p></p>") {
                            let dataNotes = {
                                "ProfileId": result?.ProfileId,
                                "Notes": html,
                                "CompanyId": result?.CompanyId
                            }
                            postRequest('api/ScheduleInterview/InsertNotes', dataNotes).then((res) => {

                            })
                        }

                        toast.success('Thank you for sharing profile');
                        setData(initialvalueVal
                        );
                        setEditorState(initialEditorState);
                        childRef.current.getSharedProfileChild();
                        setMobValue(() => "")
						window.location.reload();
                    }
                });

            }
            else {
                setLoading(false);
            }
        }


    }
    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
        const lastName = /^[A-Za-z ]+$/;
        const dateFor = /^\d{2}\/\d{2}\/\d{4}$/

        if (!values.FirstName) {
            errors.FirstName = "First name is required";
        }
        if (!values.LastName) {
            errors.LastName = "Last name is required";
        } else if (!lastName.test(values.LastName)) {
            errors.LastName = "Enter valid last name";
        }
        if (!values.Email) {
            errors.Email = "Email is required";
        } else if (!regex.test(values.Email)) {
            errors.Email = "Invalid email";
        }
        // if (!dateFor.test(values.JoinDate)){
        //     errors.JoinDate = "Invalid date";
        // }
        // if (!values.PhoneNumber) {
        //     errors.PhoneNumber = "Mobile Number is required";
        // }
        // else if (!phone.test(values.PhoneNumber)) {
        //     errors.PhoneNumber = "Invalid Mobile Number";
        // }

        return errors;
    };




    const [display, setDisplay] = useState(false)
    const displays = () => {
        setDisplay(!display)
    }

    useEffect(() => {
        getJobProfile();
        getJobTitle();
        getSalary();
        getExperience();
        getCurrency();


    }, []);

    const getCurrency = async () => {
        var res = await postRequest('/api/Master/GetCurrency');
        getCurrencyList(JSON.parse(res?.data?.Data));
    }

    const getJobProfile = async () => {
        var res = await postRequest('api/Master/GetJobProfile');
        getJobProfileList(JSON.parse(res?.data?.Data));
    }

    const getJobTitle = async () => {
        var res = await postRequest('api/Master/GetJobTitle');
        getJobTitleList(JSON.parse(res?.data?.Data));
    }

    const getSalary = async () => {
        var res = await postRequest('api/Master/GetSalary');
        getSalaryList(JSON.parse(res?.data?.Data));

    }

    const getExperience = async () => {
        var res = await postRequest('api/Master/GetExperience');
        getExperienceList(JSON.parse(res?.data?.Data));
    }
    const interviewDateRef = useRef();
    const handleInterviewDateClick = () => {
        interviewDateRef.current.focus();
    }


    useEffect(() => {
        // use jquery here if you must, this way the component is loaded 
        //and the dom matches whats in react (or should)
        $(document).ready(function () {
            $('input[type="file"]').on("change", function () {
                let filenames = [];
                let files = document.getElementById("customFile").files;
                if (files.length > 1) {
                    filenames.push("Total Files (" + files.length + ")");
                } else {
                    for (let i in files) {
                        if (files.hasOwnProperty(i)) {
                            filenames.push(files[i].name);
                        }
                    }
                }
                $(this).next(".custom-file-label")
                    .html(filenames);

                $('#reset').on('click', function (e) {
                    $("#customFile")
                        .next(".custom-file-label")
                        .html("Select a file");
                });
            });
        });


    });
    useEffect(() => {
        if (Object.keys(validate(formData)).length === 0) {
            $('.reset').on('click', function (e) {
                $("#customFile")
                    .next(".custom-file-label")
                    .html("Select a file");
            });
        }

    })

    useEffect(() => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;
        $('#date_picker').attr('min', today);
    }, [])


    const handleKeyPressalpha = (e) => {
        var key = e.key;
        var regex = /^[a-zA-Z]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
            e.toUpperCase();
        }

    }
    const handleKeyPressalphaLastName = (e) => {
        var key = e.key;
        var regex = /^[a-zA-Z ]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }

    }

    const handleKeyPressDate = (e) => {
        var key = e.key;
        var regex = /^\d\d\/\d\d\/\d\d\d\d$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }


    }

    //   const shareProfileupdatedetails = () =>{
    //     if(shareProfileupdate){
    //         return (
    //             {
    //                 shareProfileupdate.map(()=>{

    //                 })
    //             }
    //         )
    //     }
    //   }

    const handleCancel = () => {
        navigate('/shareprofile')
    }

    return (
        <SideNavbar>
            <div>
                <Container fluid className='p-0 g-0' >
                    <Row className='g-0'>
                        <Col lg={6} xs={12} sm={12} className="mob-none">
                            <div className='breadcrumps-box'>
                                <div className='breadcrump-heading'>
                                    <p>{!id ? "Share Profile" : "Update Profile"}</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} sm={12} className='breadcrumps-box  d-flex justify-content-end '>
                            <Breadcrumbs separator={<FaChevronRight className='text-white-breadcrums ' style={{ fontSize: "15px" }} />} >
                                <Link to='/' className='breadcrumps-links breadcrumps-links'>Dashboard</Link>
                                <Typography className='text-white-breadcrums cus-mr-5' >{!id ? "Share Profile" : "Update Profile"}</Typography>
                            </Breadcrumbs>
                        </Col>
                    </Row>
                </Container>
                <div>
                    <Container fluid className='p-4'>
                        <Row>
                            <Col lg={12}>

                                <div className='bg-white p-4'>
                                    <form autocomplete="off" >
                                        <div className='search-profile-form'>
                                            <div className="did-floating-label-content   col-lg-6 col-sm-12" >
                                                <input className="did-floating-input first-upper-cus" autocomplete="new-password" onChange={handleChange} onKeyDown={(e) => handleKeyPressalpha(e)} value={formData.FirstName} name="FirstName" maxLength="25" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaUserAlt className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">First Name *</label>
                                                <p className="mb-1 form-error form-error">{formErrors.FirstName}</p>
                                            </div>
                                            <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                <input className="did-floating-input first-upper-cus" autocomplete="new-password" onChange={handleChange} value={formData.LastName} onKeyDown={(e) => handleKeyPressalphaLastName(e)} maxLength="25" name="LastName" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaUserAlt className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Last Name *</label>
                                                <p className="mb-1 form-error">{formErrors.LastName}</p>
                                            </div>
                                            <div className="did-floating-label-content align-myprofile-form-phone  col-lg-6 col-sm-12" >
                                                <PhoneInput
                                                    label="MobileNumber"
                                                    onChange={handleChangeMob}
                                                    placeholder="Mobile Number *"
                                                    value={mobValue}
                                                    defaultCountry={'us'}
                                                    autoCorrect="off"
                                                    country={'us'}
                                                    disabled={id ? true : false}
                                                />
                                                {id ? <div className='position-mob-verify-icon'> <FaRegCheckCircle className='veryfied-icon' /> </div> : ""}
                                                <p className="mb-lg-1 form-error">{errorMob}</p>
                                                {/* <input className="did-floating-input" autocomplete="new-password" onChange={handleChange}  value={formData.PhoneNumber} maxLength="12" onKeyPress={(e) => handleKeyPressnumber(e)} name="PhoneNumber" type="text" placeholder=" " readOnly={id ? true : false} />
                                            <div className='icon-background' > <FaPhoneAlt className='user-input-icon' /></div>
                                            <span className='iconchange'></span>
                                            <label className="did-floating-label">Mobile Number *</label>
                                            <p className="mb-1 form-error">{formErrors.PhoneNumber}</p> */}
                                            </div>
                                            <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} value={formData.Email} name="Email" maxLength="75" type="text" placeholder=" " readOnly={id ? true : false} />
                                                <div className='icon-background' > <FaEnvelope className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Email *</label>
                                                {id ? <div className='position-mob-verify-icon'><FaRegCheckCircle className='veryfied-icon' /> </div> : ""}
                                                <p className="mb-1 form-error">{formErrors.Email}</p>
                                            </div>
                                            {/* <div className="did-floating-label-content autocomplete-root  col-lg-6 col-sm-12" >
                                            <Stack spacing={2} >
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    disableClearable
                                                    getOptionLabel={(JobProfileList) => JobProfileList.JobProfileValue}
                                                    options={JobProfileList} 
                                                    name="JobProfile"
                                                    onInputChange={(event, value) => {
                                                        setData(prevForm => {
                                                            return {
                                                                ...prevForm,
                                                                ["JobProfile"]: value
                                                            }
                                                        });
                                                    }}
                                                    sx={{ width: 300 }}
                                                    freeSolo
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.JobProfile === value.JobProfile
                                                    }

                                                    renderOption={(props, JobProfileList) => (
                                                        <Box component="li" {...props} key={JobProfileList.JobProfileId} >
                                                            {JobProfileList.JobProfileValue}

                                                        </Box>

                                                    )}
                                                    renderInput={(params) => <TextField {...params} label="Job Profile *"

                                                    />}
                                                />
                                                <div className='icon-background' > <FaAddressCard className='user-input-icon' /></div>
                                            </Stack>
                                            <p className="mb-1 form-error">{formErrors.JobProfile}</p>
                                        </div> */}


                                            <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} value={formData.JobTitle} name="JobTitle" maxLength="75" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaAddressCard className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Job Title</label>
                                                <p className="mb-1 form-error form-error">{formErrors.JobTitle}</p>
                                            </div>
                                            {/* <div className="did-floating-label-content autocomplete-root  col-lg-6 col-sm-12" >                                            
                                            <Stack spacing={2} >
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    disableClearable
                                                    getOptionLabel={(JobTitleList) => JobTitleList.JobTitleValue}
                                                    options={JobTitleList}
                                                    name="JobTitle" 
                                                    onInputChange={(event, value) => {
                                                        setData(prevForm => {
                                                            return {
                                                                ...prevForm,
                                                                ["JobTitle"]: value
                                                            }

                                                        });
                                                    }}
                                                    sx={{ width: 300 }}
                                                    freeSolo
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.JobTitle === value.JobTitle
                                                    }

                                                    renderOption={(props, JobTitleList) => (
                                                        <Box component="li" {...props} key={JobTitleList.JobTitleId} >
                                                            {JobTitleList.JobTitleValue}

                                                        </Box>

                                                    )}
                                                    renderInput={(params) => <TextField {...params} label="Job Title *"

                                                    />}
                                                />
                                                <div className='icon-background' > <FaAddressCard className='user-input-icon' /></div>

                                            </Stack>
                                            <p className="mb-1 form-error">{formErrors.JobTitle}</p>
                                        </div>                                        */}
                                            <div class="col-lg-6 col-sm-12 mb-4 mui-overide mui-select-override">
                                                <FormControl fullWidth className='b-0 '>
                                                    <InputLabel id="demo-simple-select-label1" className='material-ui-select '>Year of Experience</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label1"
                                                        id="demo-simple-select1"
                                                        label="Year of Experience"
                                                        className=''
                                                        variant="outlined"
                                                        name='ExperienceId'
                                                        value={formData.ExperienceId}
                                                        onChange={handleChange}
                                                    >
                                                        {
                                                            jobExperienceList.map(job => (<MenuItem key={job.ExperienceId} value={job.ExperienceId}>{job.ExperienceValue}</MenuItem>))
                                                        }
                                                    </Select>
                                                    <div className='icon-background' > <FontAwesomeIcon icon={faCheckToSlot} className='user-input-icon' /></div>
                                                </FormControl>
                                                <p className="mb-1 form-error">{formErrors.ExperienceId}</p>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 mb-4 mui-overide mui-select-override">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" className='material-ui-select'>Choose Currency type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Choose Currency type"
                                                        value={formData?.currency}
                                                        className="select-dropdown-focus"
                                                        name="currency"
                                                        onChange={handleCurrencyChange}
                                                    >
                                                        {
                                                            jobCurrencyList.map(job => (<MenuItem key={job.CurrencyId} value={job.CurrencyId}>{job.CurrencyName}</MenuItem>))
                                                        }
                                                    </Select>
                                                    <div className='icon-background' ><FontAwesomeIcon icon={faMoneyBill1Wave} className='user-input-icon' /></div>
                                                </FormControl>
                                                <p className="mb-1 form-error">{formErrors.currency}</p>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 mb-4 mui-overide mui-select-override">
                                                <FormControl fullWidth className='b-0 '>
                                                    <InputLabel id="demo-simple-select-label1" className='material-ui-select '>Salary Range Annually</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label1"
                                                        id="demo-simple-select1"
                                                        label="Salary Range Annually"
                                                        className=''
                                                        value={formData?.SalaryId}
                                                        variant="outlined"
                                                        name="SalaryId"
                                                        onChange={handleChange}

                                                    >
                                                        {
                                                            jobSalaryListFilter.map(job => (<MenuItem key={job?.SalaryId} value={job?.SalaryId} >{job?.SalaryValue}</MenuItem>))

                                                        }

                                                    </Select>
                                                    <div className='icon-background' > <FontAwesomeIcon icon={faSackDollar} className='user-input-icon' /></div>
                                                </FormControl>
                                                <p className="mb-1 form-error">{formErrors.SalaryId}</p>

                                            </div>


                                            <div className="did-floating-label-content  col-lg-6 col-sm-12" onChange={handleInterviewDateClick} >


                                                <input className="did-floating-input unstyled" onChange={handleChange} value={formData?.JoinDate == null ? "" : formData?.JoinDate} onKeyDown={(e) => handleKeyPressDate(e)} name="JoinDate" id="date_picker" type="date" ref={interviewDateRef} />
                                                <div className='icon-background' > <FaCalendarAlt className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label"> Expected Start Date</label>
                                                <p className="mb-1 form-error">{formErrors.JoinDate}</p>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 mb-4 mui-overide mui-select-override">
                                                <FormControl fullWidth className='b-0 '>
                                                    <InputLabel id="demo-simple-select-label1" className='material-ui-select '>Type of Employment</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label1"
                                                        id="demo-simple-select1"
                                                        label="Type of Employment"
                                                        className=''
                                                        value={formData?.EmployementType == "null" ? "" : formData?.EmployementType}
                                                        variant="outlined"
                                                        name="EmployementType"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="full time">Full Time</MenuItem>
                                                        <MenuItem value="part time">Part Time</MenuItem>
                                                        <MenuItem value="contract">Contract </MenuItem>
                                                        <MenuItem value="temporary">Temporary </MenuItem>
                                                    </Select>
                                                    <div className='icon-background' > <FontAwesomeIcon icon={faBriefcase} className='user-input-icon' /></div>
                                                </FormControl>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 mb-1">
                                                <div class="custom-file">
                                                    <input type="file" value={formData?.ProfileDocument} name="ProfileDocument" title='' accept=".pdf,.doc" multiple="" onChange={handleInputFile} class="custom-file-input form-control" id="customFile" ref={ref} />
                                                    <label class="custom-file-label" for="customFile">{formData?.document || "Select a file"}</label>
                                                </div>
                                            </div>
                                            {/* <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} value={formData.Notes} name="Notes" maxLength="250" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaPenSquare className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Notes</label>
                                                <p className="mb-1 form-error form-error">{formErrors.Notes}</p>
                                            </div> */}
                                            <div class="col-lg-12 col-sm-12 mb-4 ">
                                                <Editor
                                                    placeholder="Add Notes"
                                                    defaultEditorState={editorState}
                                                    editorState={editorState}
                                                    onEditorStateChange={setEditorState}
                                                    wrapperClassName="wrapper-class"
                                                    editorClassName="editor-class"
                                                    toolbarClassName="toolbar-class"
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'fontSize', 'list', 'link', 'textAlign', 'history'],
                                                        inline: { inDropdown: false },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        history: { inDropdown: true },
                                                    }}
                                                    initialEditorState={initialEditorState}
                                                />
                                            </div>
                                        </div>
                                        <div className="search-reset-btn mt-1">

                                            {id ? <button className='search-btn reset' onClick={handleUpdate} disabled={loading}> {loading ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span></> : null
                                            } Update </button> : <button className='search-btn reset' onClick={handleSubmit} disabled={loading}> {loading ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span></> : null
                                            } Create </button>}
                                            <input type="reset" className='reset-btn reset' id="reset" value="Cancel" onClick={() => {
                                                setData(() => ""); setFormErrors({}); setMobValue(() => ""); setSelectedFile(() => ""); ref.current.value = ""; navigate('/shareprofile'); errorMessageMob(""); handleCancel(); setEditorState(initialEditorState);
                                            }} />
                                        </div>
                                    </form>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div >

                <div>
                    <Container fluid>
                        <Row>
                            <Col>

                                <SharedProfileTable ref={childRef} reset={handleCancel} />
                            </Col>
                        </Row>
                    </Container>
                </div>


                <Footer />
            </div >
        </SideNavbar>
    )
}

export default SharedProfile