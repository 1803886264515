import React, {useState,useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import Moment from 'moment';
import { postRequest } from '../Services/api';
import Spinner from 'react-bootstrap/Spinner';

const ModalOfferDetails = (props) => {
    const {candidateName, email, show, onClose } = props;
    const [loading, setLoading] = useState(true)
    const [searchList, getSearchList] = useState([]);  
        useEffect(() => {
          getSearch();
          
        }, [email,candidateName]);
        const getSearch = async () => {
            setLoading(true); 
            if(email != "" && email.length >0){
                await postRequest('api/Profile/GetOfferDetail?email='+ email).then((res) => {
                    getSearchList(JSON.parse(res?.data?.Data)); 
                    setLoading(false); 
                }); 
            } 
        }  
         
    return (
        <div>
          
            <Modal
                show={show} onHide={onClose} 
                size="xl"
                dialogClassName="modal-100w"
                centered
                aria-labelledby="example-custom-modal-styling-title-lg"
            >
                <Modal.Header closeButton  className='bg-theme'>
                    <Modal.Title id="example-custom-modal-styling-title-lg" className='modal-heading'>
                    {candidateName} employment details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
        <Table striped responsive borderless className='searched-table'>
        <thead>
                                    <tr>         
                                        <th>Job Title</th>
                                        <th>Expected start Date</th>
                                        <th>Experience</th>
                                        <th>Salary</th>
                                        <th>updated date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                { loading ?
                                       <tr> 
                                       <td class="text-center" colSpan={10}> <div class="positioning-spinner">
                                           <Spinner animation="border" role="status">
                                               <span className="visually-hidden text-center">Loading...</span>
                                           </Spinner>
                                       </div></td> 
                                   </tr>
                                    :
                                         searchList.map((item) =>(
                                            <tr>                                              
                                                 <td>{item.JobTitle == "undefined" || item.JobTitle == "null" ? "":item.JobTitle}</td>
                                                <td>{ item.JoinDate == null ? null :
                                                Moment(item.JoinDate).format('MM/DD/YYYY')}</td>
                                                <td>{item.ExperienceValue}</td>
                                                <td>{item.SalaryValue}</td> 
                                                <td>{ item.UpdatedDate == null ? null :
                                                Moment(item.UpdatedDate).format('MM/DD/YYYY')}</td>
                                                <td style={{textTransform:"capitalize"}}>{item.Status}</td> 
                                            </tr>
                                            
                                            ))
                                    }   
                                </tbody>
                            </Table>
    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalOfferDetails