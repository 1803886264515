
import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import TestVideo from '../../assets/video/test.mp4';
import '../Profiles/Components/InterviewVideos.css'
import playButton from '../../assets/img/Vector.svg'
import pauseButton from '../../assets/img/pause.svg'
import Expand from '../../assets/img/expand.svg'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
const VideoPreview = (props) => {
    const { showImg, onCloseImg, imgView, loading, videoData, VideoPreviewFile } = props;
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setVolume] = useState(50);
    const [isBuffering, setIsBuffering] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const waitingIntervalRef = useRef(null);
    const [waitingPercentage, setWaitingPercentage] = useState(0);
    const [bufferingPercentage, setBufferingPercentage] = useState(0);
    const bufferingIntervalRef = useRef(null);
    const isEventListenersAddedRef = useRef(false);
    const handleVolumeChange = (event) => {
        const newVolume = event.target.value;
        setVolume(newVolume);
        videoRef.current.volume = newVolume / 100;
    };
    const videoRef = useRef(null);
    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${String(seconds).padStart(2, '0')}`;
    }

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;
        video.addEventListener('timeupdate', handleTimeUpdate);
        return () => {
            video.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, []);

    const handlePlayClick = () => {
        const video = videoRef.current;
        if (!video) return;
        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = (event) => {
        setCurrentTime(event.target.currentTime);
    };
    const handleTimelineClick = (event) => {
        const video = videoRef.current;
        if (!video) return;
        const timeline = event.currentTarget;
        const rect = timeline.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const ratio = x / rect.width;
        video.currentTime = ratio * video.duration;
    };

    useEffect(() => {
        const handleWaiting = () => {
            setIsWaiting(true);
            startWaitingTimer();
        };

        const handlePlaying = () => {
            setIsWaiting(false);
            clearInterval(waitingIntervalRef.current);
            setWaitingPercentage(100);
        };

        const startWaitingTimer = () => {
            let startTime = performance.now();
            let waitingTime = 0;

            waitingIntervalRef.current = setInterval(() => {
                const currentTime = performance.now();
                const elapsedTime = currentTime - startTime;

                // Calculate waiting time in seconds
                const waitingSeconds = Math.floor(elapsedTime / 1000);

                // Calculate the waiting percentage
                const maxWaitingTime = videoRef.current ? videoRef.current.duration : 0;
                const currentWaitingPercentage = Math.min(
                    Math.floor((waitingSeconds / maxWaitingTime) * 100),
                    100
                );

                // Update waiting percentage if it's less than the current value
                if (currentWaitingPercentage > waitingTime) {
                    waitingTime = currentWaitingPercentage;
                    setWaitingPercentage(waitingTime);
                }
            }, 1000); // Adjust the interval duration as needed
        };

        const videoElement = videoRef.current;

        if (videoElement && !isEventListenersAddedRef.current) {
            videoElement.addEventListener("waiting", handleWaiting);
            videoElement.addEventListener("playing", handlePlaying);
            isEventListenersAddedRef.current = true;
        }

        return () => {
            clearInterval(waitingIntervalRef.current);
            if (videoElement && isEventListenersAddedRef.current) {
                videoElement.removeEventListener("waiting", handleWaiting);
                videoElement.removeEventListener("playing", handlePlaying);
                isEventListenersAddedRef.current = false;
            }
        };
    }, [videoRef.current, isWaiting, isPlaying]);


    return (
        <div>
            <Modal
                show={showImg} onHide={onCloseImg}
                size="lg"
                dialogClassName="modal-100w"
                centered
                aria-labelledby="example-custom-modal-styling-title-lg"
            >
                {/* <Modal.Header closeButton className='bg-theme'>
                    <Modal.Title id="example-custom-modal-styling-title-lg" className='modal-heading'>
                       
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="video-player">
                        {
                            isWaiting ? <div className='d-flex video-percentage-main' >
                                <div style={{ width: 50, height: 50 }}>
                                    <CircularProgressbar value={waitingPercentage} text={`${waitingPercentage}%`}
                                    />
                                </div>

                            </div> : ""
                        }
                        <video ref={videoRef} onPlay={() => setIsPlaying(true)} onPause={() => setIsPlaying(false)} onTimeUpdate={handleTimeUpdate} onClick={handlePlayClick} style={{maxHeight:"500px"}}>
                           { /*<source src={'https://dev.knila.com:8016/StaticFiles/' + VideoPreviewFile} type="video/mp4" />*/}
                           <source src={'https://gateway.knila.com/projectr_api/StaticFiles/' + VideoPreviewFile} type="video/mp4" />
                        </video>
                        <div className="controls">
                            <div className="play-button" onClick={handlePlayClick}>
                                {isPlaying ? <img src={pauseButton} className='pausebutton-icon' /> : <img src={playButton} className='playbutton-icon' />}
                            </div>


                            <div className='text-white'>{formatTime(currentTime)}</div>
                            <div className="timeline" onClick={handleTimelineClick}>
                                <div
                                    className="timeline-progress"
                                    style={{ width: `${(currentTime / (videoRef.current ? videoRef.current.duration : 0)) * 100}%` }}
                                />
                            </div>

                            <div className='text-white'>{formatTime(videoRef.current && !isNaN(videoRef.current.duration) ? videoRef.current.duration : 0)}</div>
                            <div className="volume-control">
                                <input
                                    type="range"
                                    min={0}
                                    max={100}
                                    value={volume}
                                    onChange={handleVolumeChange}
                                    className="volume-slider"
                                    style={{
                                        background: `-webkit-linear-gradient(left, #ffa500 0%, #ffa500 ${volume}%, #fff ${volume}%, #fff 100%)`
                                    }}
                                />
                            </div>
                            <div onClick={onCloseImg} className="fullscreen-button"><img src={Expand} /></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default VideoPreview