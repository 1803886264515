import React, { useState, useRef, useEffect } from 'react'
import SideNavbar from '../Dashboard/SideNavbar'
import { Modal, Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FaChevronRight } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaAddressCard, FaUserAlt, FaPhoneAlt, FaUsers, FaEnvelope, FaCalendarAlt, FaPenSquare, FaKey, FaInfoCircle, FaEyeSlash, FaEye } from "react-icons/fa";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { postRequest } from '../Services/api';
import SubuserTable from './SubuserTable';
import { toast } from 'react-toastify';
import { postVerify } from '../Services/api';
import CountDown from '../SignUp/CountDown'
import OtpInput from "react-otp-input";
import { useSelector } from 'react-redux';
import Footer from '../Dashboard/Footer';

const UserManagement = () => {
    let navigate = useNavigate()
    const [passwordShown, setPasswordShown] = useState(false);
    const [iconChange, setIconChange] = useState(false);
    const [passwordShown1, setPasswordShown1] = useState(false);
    const [iconChange1, setIconChange1] = useState(false);
    const iconToggle = () => {
        setIconChange(!iconChange);
        setPasswordShown(!passwordShown);
    }
    const iconToggle1 = () => {
        setIconChange1(!iconChange1);
        setPasswordShown1(!passwordShown1);
    }
    let authToken = JSON.parse(localStorage.getItem('token'))
    // console.log(authToken)
    let tokens = useSelector((state) => state.userAuth.value);
    useEffect(() => {
        // setAuthToken(tokens)
        tokens = tokens;
    }, [tokens]);
    let UserID = authToken?.userId
    // const ref = React.useRef();
    const childRef = useRef();
    const init = {
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        Email: "",
        JobTitle: "",
        CompanyName: "",
        Password: "",
        ConfirmPassword: ""
    }
    const [formData, setData] = useState(init);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [mobValue, setMobValue] = useState()
    const [PhoneNumber, setPhoneNumber] = useState();
    const [allowCreate, setAllowCreate] = useState({});
    const [PhoneCode, setPhoneCode] = useState();
    const [phoneNumberVerifiedform, setPhoneNumberVerified] = useState(false);
    const [emailVerifiedform, setEmailverified] = useState(false)
    const [dialCode, setDialCode] = useState()
    const [countryCodePhone, setCountryCodePhone] = useState();
    const [verifyEmail, setVerifyEmail] = useState(false)

    const handleChange = (event, values) => {
        const { name, value } = event.target
        setData(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        });
    }

    const handleChangeEmail = (event) => {
        const { name, value } = event.target
        setData(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        });
        setVerifyEmail(false)
        setEmailverified(false);
    }
    console.log(formData.Email);
    const handleChangeMob = (mobValue, data, event) => {
        setMobValue(mobValue);
        setDialCode(data.dialCode)
        setCountryCodePhone(data.countryCode)
        let Phone = mobValue.slice(data.dialCode.length)
        // let concat = mobValue?.phoneNumber
        // let remove = concat?.toString()
        // let Phone = remove?.replace(/[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/ ]/gi, '')
        setPhoneNumber(Phone)
        setVerifyPhone(false)
        setPhoneNumberVerified(false)
        // setPhoneCode(mobValue?.countryCode);
    }
    useEffect(() => {
        getSubUserLists()
        setAllowCreate()
        getOrganization();
    }, [])
    const getSubUserLists = async () => {
        var res = await postRequest(`/api/Profile/GetSubUser?UserId=${UserID}`);
        var result = JSON.parse(res?.data?.Data);
        setAllowCreate(result)
    }
    const [organization, setOrganization] = useState()
    const getOrganization = async () => {
        var res = await postRequest(`/api/Verification/GetMyProfile`);
        var result = JSON.parse(res?.data?.Data);
        setOrganization(result?.CompanyName)
    }
    // console.log(allowCreate)

    const handleSubmit = (event) => {
        event.preventDefault();
        if (allowCreate?.length >= 3) {
            toast.error("Maximum subusers created already")
            setData({
                FirstName: "",
                LastName: "",
                PhoneNumber: "",
                Email: "",
                JobTitle: "",
                CompanyName: "",
                Password: "",
                ConfirmPassword: ""
            })

            setMobValue(() => (""))
            errorMessage("");
            setFormErrors({});
            setVerifyEmail(false);
            setVerifyPhone(false);
        }
        else {


            setFormErrors(validate(formData));
            if (PhoneNumber == null) {
                errorMessageMob("Mobile number is required");
                return;
            }
            if (PhoneNumber.length < 8 && PhoneNumber.length != "") {
                errorMessageMob("Invalid mobile number");
                return;
            }
            if (mobValue.length == "") {
                errorMessageMob("Mobile number is required");
                return;
            }
            else {
                errorMessage("");
                errorMessageMob("")
            }
            if (phoneNumberVerifiedform === false) {
                toast.error("Verify mobile number")
            }
            else if (emailVerifiedform === false) {
                toast.error("Verify email")
            }


            else {


                if (mobValue.length == "") {
                    errorMessageMob("Mobile number is required");
                    return;
                }

                else {
                    errorMessage("");
                    errorMessageMob("")
                }
                if (Object.keys(validate(formData)).length === 0) {
                    setVerifyPhone(false)
                    errorMessage("");
                    const capitalizedFirstName = formData.FirstName.charAt(0).toUpperCase() + formData.FirstName.slice(1);
                    const capitalizedLastName = formData.LastName.charAt(0).toUpperCase() + formData.LastName.slice(1);
                    const data = {
                        PhoneNumber: PhoneNumber,
                        Email: formData.Email,
                        FirstName: capitalizedFirstName,
                        LastName: capitalizedLastName,
                        CompanyName: formData.CompanyName,
                        JobTitle: formData.JobTitle,
                        Password: formData.Password,
                        PhoneCode: dialCode,
                        CountryCode: countryCodePhone
                    };
                    postRequest('api/Verification/AdminCreateUser', data).then((res) => {
                        if (res.data.SuccessMessage = "Successs") {
                            toast.success("Sub user created successfully")
                            setData({
                                FirstName: "",
                                LastName: "",
                                PhoneNumber: "",
                                Email: "",
                                JobTitle: "",
                                CompanyName: "",
                                Password: "",
                                ConfirmPassword: ""
                            })
                            setMobValue(() => "")
                            setPhoneNumberVerified(false)
                            setEmailverified(false)
                            navigate('/usermanagement')
                            childRef.current.getSubUserListChild();
                            setVerifyEmail(false)
                            setIconChange(false);
                            setPasswordShown(false);
                            setIconChange1(false);
                            setPasswordShown1(false);
                            setPhoneNumber("")
                        }
                    });
                }
            }


        }

    }

    const handleKeyPressalpha = (e) => {
        var key = e.key;
        var regex = /^[a-zA-Z]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }

    }
    const handleKeyPressalphaLastName = (e) => {
        var key = e.key;
        var regex = /^[a-zA-Z ]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }

    }

    const handleKeyPressnumber = (e) => {
        var key = e.key;
        var regex = /^[0-9]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }

    }
    const validateEmail = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        if (!regex.test(values.Email)) {
            setShowEmail(false);
            errors.Email = "";
        }

        return errors;
    };
    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const nameregex = /^[A-Za-z]+$/;
        const passregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!values.FirstName) {
            errors.FirstName = "First name is required";
        } else if (!nameregex.test(values.FirstName)) {
            errors.FirstName = "Invalid first name";
        }
        // if (!values.CompanyName) {
        //     errors.CompanyName = "Organization name is required";
        // }

        if (!values.Email) {
            errors.Email = "Email is required";
        } else if (!regex.test(values.Email)) {
            errors.Email = "Invalid email ";
        }
        if (!values.Password) {
            errors.Password = "Password is required"
        }
        if (!values.ConfirmPassword) {
            errors.ConfirmPassword = "Confirm password is required"
        }
        else if (!passregex.test(values.Password)) {
            errors.Password = "Please strengthen the password"
        }
        if (values.Password != values.ConfirmPassword) {
            errors.ConfirmPassword = "Confirm password do not match"
        }



        return errors;
    };
    const [mins, setMinutes] = useState("15")
    const [over, setOver] = React.useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showEmail, setShowEmail] = useState(false);
    const handleCloseEmail = () => setShowEmail(false);
    const [code, setCode] = useState("");
    const [codeEmail, setCodeEmail] = useState("");
    const handleChangeEmailOTP = (codeEmail) => setCodeEmail(codeEmail);
    const handleChangeMobOTP = (code) => setCode(code);
    let [error, setError] = React.useState("");
    let [errorMob, setErrorMob] = React.useState("");
    const [verifyPhone, setVerifyPhone] = useState(false)
    const [phoneNumberDisplay, setPhoneNumberDisplay] = useState()
    const [mobOTPdisplay, setMobOTPdisplay] = useState({})
    const [EmailDisplay, setEmailDisplay] = useState()
    const [emailOTPdisplay, setEmailOTPdisplay] = useState({})
    const [verifiedMobileNumber, setVerifiedMobileNumber] = useState(false)
    const [emailVerfiy, setEmailVerify] = useState();
    const [verifiedEmail, setVerifiedEmail] = useState(false)
    const [mpbShowDisable, setMobShowDisable] = useState(false)
    const errorMessage = (message) => {
        setError(message);
        // setTimeout(() => setError(""), 2000);
    }
    const errorMessageMob = (message) => {
        setErrorMob(message);
        // setTimeout(() => setError(""), 2000);
    }
    const verifyPhoneNumberOTP = (phoneNumber) => {
        errorMessage("")
        setPhoneNumberDisplay(phoneNumber)
        // if (mobValue?.hasError === true) {
        //     errorMessageMob("Invalid Mobile number");
        // }
        if (phoneNumber.length != "") {
            errorMessageMob("");
            postVerify(`api/Verification/PhoneNumberExists?phoneNumber=${phoneNumber}`, phoneNumber).then((existsMob) => {
                if (existsMob.data.Status) {
                    setShow(true);
                    const value = {
                        VerificationValue: phoneNumber,
                        VerificationType: "m"
                    }
                    postVerify('/api/Verification/SendVerification', value).then((res) => {
                        if (res) {
                            let result = JSON.parse(res?.data?.Data)
                            setMobOTPdisplay(result)
                            toast.success("Mobile verification SMS sent successfully")
                        }

                    });
                }
                else if (existsMob.data.ErrorMessage == "Already exists" && PhoneNumber.length != " ") {
                    setShow(false);
                    toast.error("Mobile number already exists")
                }
                else {
                    setShow(false);
                    toast.error("Enter mobile number")
                }
            });
        }
    };
    const OTPverifyMob = (e) => {
        e.preventDefault();
        if (code.length == " ") {
            errorMessage("Enter code");
            return;
        }
        else if (code.length !== 6) {
            errorMessage("Enter valid code");
            return;
        }

        if (code === mobOTPdisplay.VerificationCode) {
            toast.success("Mobile number verified successfully")
            errorMessage(" ");
            setShow(false);
            // setVerifiedEmail(true)
            setVerifyPhone(true)
            setCode("")
            setMobShowDisable(true)
            setData(prevForm => {
                return {
                    ...prevForm,
                    PhoneNumberVerified: true
                }
            });
            setPhoneNumberVerified(true)





        }
        else {
            errorMessage("Enter valid code");
        }
    }
    const [resetOTP, setResetOTP] = useState(false)
    useEffect(() => {
        resendMobOTP()
    }, [])
    const resendMobOTP = async (phoneNumber) => {
        setOver(false)
        setCode("")
        errorMessage(" ");
        setResetOTP(!resetOTP)
        const PhoneInput = {
            VerificationValue: phoneNumber,
            VerificationType: "m"
        }
        await postVerify('/api/Verification/SendVerification', PhoneInput).then((res) => {



            if (res) {
                let result = JSON.parse(res?.data?.Data)
                toast.success("Mobile verification SMS resent successfully")
                setMobOTPdisplay(result)

            }

        });
    }
    const [ishowDisable, setShowDisable] = useState(false)
    const verifyEmailOTP = (Email) => {
        errorMessage("")
        // setFormErrors(validateEmail(formData));
        setEmailDisplay(Email)
        setShowEmail(false);
        // setErrorMob("")
        if (Object.keys(validateEmail(formData)).length === 0)
            postVerify(`api/Verification/EmailExists?email=${Email}`, Email).then((existsEmail) => {
                if (existsEmail.data.Status) {
                    setShowEmail(true);
                    const value = {
                        VerificationValue: Email,
                        VerificationType: "e"
                    }
                    postVerify('/api/Verification/SendVerification', value).then((res) => {
                        if (res) {
                            let result = JSON.parse(res?.data?.Data)
                            toast.success("Email verification sent successfully")
                            setEmailOTPdisplay(result)
                        }

                    });
                }
                else if (existsEmail.data.ErrorMessage == "Already exists" || emailVerfiy === true) {
                    setShowEmail(false);
                    toast.error("Email already exists")
                }
            });

    };
    const resendEmailOTP = async (Email) => {
        setOver(false)
        errorMessage(" ");
        setCodeEmail("")
        setResetOTP(!resetOTP)
        const EmailInput = {
            VerificationValue: Email,
            VerificationType: "e"
        }
        await postVerify('/api/Verification/SendVerification', EmailInput).then((res) => {



            if (res) {
                let result = JSON.parse(res?.data?.Data)

                setEmailOTPdisplay(result)
                toast.success("Email verification resent successfully")
            }

        });
    }

    const OTPverifyEmail = (e) => {
        e.preventDefault();
        if (codeEmail.length == " ") {
            errorMessage("Enter code");
            return;
        }
        else if (codeEmail.length !== 6) {
            errorMessage("Enter valid code");
            return;
        }

        // console.log(otpValidate(code))
        if (codeEmail === emailOTPdisplay.VerificationCode) {
            toast.success("Email verified successfully")
            errorMessage(" ");
            setShowEmail(false);
            setShowDisable(true);
            setVerifiedMobileNumber(true)
            setVerifyEmail(true)
            setCodeEmail("")
            setData(prevForm => {
                return {
                    ...prevForm,
                    EmailVerified: "true"
                }
            });
            setEmailverified(true)





        }
        else {
            errorMessage("Enter valid code");
        }
    }


    useEffect(() => {

    }, [mobValue])

    return (
        <div>
            <SideNavbar>
                <div>
                    <Container fluid className='p-0 g-0' >
                        <Row className='g-0'>
                            <Col lg={6} xs={12} sm={12} className="mob-none">
                                <div className='breadcrumps-box'>
                                    <div className='breadcrump-heading'>
                                        <p>User Management</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} xs={12} sm={12} className='breadcrumps-box  d-flex justify-content-end '>
                                <Breadcrumbs separator={<FaChevronRight className='text-white-breadcrums ' style={{ fontSize: "15px" }} />} >
                                    <Link to='/' className='breadcrumps-links breadcrumps-links'>Dashboard</Link>
                                    <Typography className='text-white-breadcrums cus-mr-5' >User Management</Typography>
                                </Breadcrumbs>
                            </Col>
                        </Row>
                    </Container>

                    {/* add sub user form  */}
                    <div>
                        <Container fluid className='p-4'>
                            <Row>
                                <Col lg={12}>

                                    <div className='bg-white p-4'>
                                        <form autocomplete="off" >
                                            <div className='search-profile-form'>
                                                <div className="  col-lg-6 col-sm-12 " >


                                                    <div className='align-subuser-form '>
                                                        <div className='col-lg-9 col-sm-12'>
                                                            <PhoneInput
                                                                label="MobileNumber"
                                                                onChange={handleChangeMob}
                                                                placeholder="Mobile Number *"
                                                                value={mobValue}
                                                                defaultCountry={'us'}
                                                                autoCorrect="off"
                                                                country={'us'}

                                                            />
                                                            <p className="mb-4 form-error">{errorMob}</p>
                                                        </div>
                                                        <div className='col-lg-3 col-sm-12'>
                                                            <button className={!verifyPhone ? 'btn-verify-subuser mb-3' : "btn-verified-subuser-mobile mb-3"} type="button" disabled={phoneNumberVerifiedform ? true : false} onClick={() => verifyPhoneNumberOTP(PhoneNumber)} style={{}}>{!verifyPhone ? "Send verification SMS" : "Verified"}</button>

                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-lg-6 col-sm-12 align-subuser-form'>
                                                    <div className="did-floating-label-content col-lg-9 col-sm-12 mb-sm-0-cus" >
                                                        <input className="did-floating-input" autocomplete="new-password" onChange={handleChangeEmail} value={formData.Email} name="Email" maxLength="75" type="text" placeholder=" " style={{ width: "calc(100% - 1px)" }} />
                                                        <div className='icon-background' > <FaEnvelope className='user-input-icon' /></div>
                                                        <span className='iconchange'></span>
                                                        <label className="did-floating-label">Email *</label>
                                                        <p className="mb-lg-1 form-error">{formErrors.Email}</p>

                                                    </div>
                                                    <div className="col-lg-3 col-sm-12" >
                                                        <button className={!verifyEmail ? "btn-verify-subuser-email mb-3" : "btn-verified-subuser-email mb-3"} disabled={emailVerifiedform ? true : false} type="button" onClick={() => verifyEmailOTP(formData.Email)} style={{}}>{!verifyEmail ? "Send verification email" : "Verified"}</button>
                                                    </div>
                                                </div>




                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input first-upper-cus" autocomplete="new-password" onChange={handleChange} onKeyPress={(e) => handleKeyPressalpha(e)} value={formData.FirstName} name="FirstName" maxLength="25" type="text" placeholder=" " />
                                                    <div className='icon-background' > <FaUserAlt className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">First Name *</label>
                                                    <p className="mb-1 form-error form-error">{formErrors.FirstName}</p>
                                                </div>
                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input first-upper-cus" autocomplete="new-password" onChange={handleChange} value={formData.LastName} onKeyPress={(e) => handleKeyPressalphaLastName(e)} maxLength="25" name="LastName" type="text" placeholder=" " />
                                                    <div className='icon-background' > <FaUserAlt className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Last Name </label>
                                                    <p className="mb-1 form-error">{formErrors.LastName}</p>
                                                </div>
                                                <div className="did-floating-label-content col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} value={organization} name="CompanyName" type="text" placeholder=" " readOnly={true} />
                                                    <div className='icon-background' > <FaUsers className='user-input-icon styel-organization-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Organization Name *</label>
                                                    {/* <p className="mb-1 form-error form-error">{formErrors.CompanyName}</p> */}
                                                </div>
                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} value={formData.JobTitle} name="JobTitle" maxLength="75" type="text" placeholder=" " />
                                                    <div className='icon-background' > <FaAddressCard className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Job Title</label>
                                                    <p className="mb-1 form-error form-error">{formErrors.JobTitle}</p>
                                                </div>
                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} value={formData.Password} name="Password" maxLength="20" type={passwordShown ? "text" : "password"} placeholder=" " />
                                                    <div className='icon-background'> <FaKey className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Password *</label>
                                                    <p className="mb-1 form-error form-error">{formErrors.Password}</p>
                                                    {iconChange ? <div className='icon-login-mob'> <FaEyeSlash className='user-input-icon-eye-subuser' onClick={iconToggle} /> </div> : <div className='icon-login-mob'><FaEye className='user-input-icon-eye-subuser' onClick={iconToggle} /></div>}
                                                    <OverlayTrigger
                                                        placement="top"

                                                        overlay={<Tooltip className='text-left' > Password must contain minimum 8-20 characters, <br />at least one lowercase,<br /> at least one uppercase, <br />at least one number and<br /> at least one special character</Tooltip>}>
                                                        <span className="tooltip-alignpassword">
                                                            {<FaInfoCircle />}
                                                        </span>
                                                    </OverlayTrigger>
                                                </div>

                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} name="ConfirmPassword" value={formData.ConfirmPassword} maxLength="20" type={passwordShown1 ? "text" : "password"} placeholder=" " />
                                                    <div className='icon-background'> <FaKey className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Confirm Password *</label>
                                                    <p className="mb-1 form-error form-error">{formErrors.ConfirmPassword}</p>
                                                    {iconChange1 ? <div className='icon-login-mob'> <FaEyeSlash className='user-input-icon-eye-subuser' onClick={iconToggle1} /> </div> : <div className='icon-login-mob'><FaEye className='user-input-icon-eye-subuser' onClick={iconToggle1} /></div>}
                                                </div>
                                            </div>
                                            <div className="search-reset-btn mt-1">

                                                <button className='search-btn reset' onClick={handleSubmit} disabled={loading}> {loading ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span></> : null
                                                } Create </button>
                                                <input type="reset" className='reset-btn reset' id="reset" value="Cancel" onClick={() => {
                                                    setData(() => ""); setFormErrors({}); setError(""); setMobValue(() => ""); setVerifyPhone(false); errorMessageMob(""); setVerifyEmail(false); setIconChange(false);
                                                    setPasswordShown(false); setIconChange1(false); setPasswordShown1(false); setEmailverified(false); setPhoneNumberVerified(false); navigate('/usermanagement'); setPhoneNumber("")
                                                }} />
                                            </div>
                                        </form>
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div >
                    <div>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <SubuserTable ref={childRef} setAllowCreate={setAllowCreate} allowCreate={allowCreate} onDelete={() => getSubUserLists()} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Modal show={show} onHide={handleClose}

                        size="xl"
                        dialogClassName="modal-100w"
                        centered
                        aria-labelledby="example-custom-modal-styling-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title-lg" className='modal-heading'>
                                Verify Mobile Number {mobOTPdisplay.VerificationCode}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={OTPverifyMob}>
                                <div className='verification-layout-css align-verify'>
                                    <div className='mobile-verify-content'>
                                        <h1>Mobile Number Verification</h1>
                                    </div>

                                    <h4 className='mt-1  px-5'>Verification code has been sent to your {phoneNumberDisplay} mobile number</h4>

                                    <div className='otp-align mt-1'>
                                        {over ? "" : <OtpInput
                                            value={code}
                                            onChange={handleChangeMobOTP}
                                            numInputs={6}
                                            separator={<span style={{ width: "8px" }}></span>}
                                            isInputNum={true}
                                            shouldAutoFocus={true}


                                            inputStyle={{
                                                border: "1px solid #CFD3DB",
                                                borderRadius: "8px",
                                                width: "54px",
                                                height: "54px",
                                                fontSize: "17px",
                                                color: "#000",
                                                fontWeight: "400",
                                                caretColor: "blue"
                                            }}
                                            focusStyle={{
                                                border: "1px solid #CFD3DB",
                                                outline: "none"
                                            }}
                                            className="otp-input"
                                        />}

                                    </div>
                                    {over ? "" : <p className="mb-1 form-error">{error}</p>}
                                    <div className='mt-1  verify-timing '><div> {over ? "" : <div> Code expires in</div>}</div> <div className='counter'>
                                        {resetOTP ? <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={false} /> : <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={true} />}
                                    </div> </div>
                                    <div className='mt-1 '>
                                        {over ? " " : <button className='btn-verify' >Submit</button>}
                                    </div>
                                    <div className=' resend-otp'> {over ? <p><span onClick={() => resendMobOTP(phoneNumberDisplay)} className=' resend-otp'>Resend Code </span> </p> : <p> Didn’t receive the code? <span onClick={() => resendMobOTP(phoneNumberDisplay)} className=' resend-otp'>Resend Code </span> </p>}</div>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showEmail} onHide={handleCloseEmail}

                        size="xl"
                        dialogClassName="modal-100w"
                        centered
                        aria-labelledby="example-custom-modal-styling-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title-lg" className='modal-heading'>
                                Verify Email {emailOTPdisplay.VerificationCode}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={OTPverifyEmail}>
                                <div className='verification-layout-css align-verify'>
                                    <div className='mobile-verify-content'>
                                        <h1>Email Verification</h1>
                                    </div>

                                    <h4 className='mt-1  px-5'>Verification code has been sent to your {EmailDisplay} email</h4>

                                    <div className='otp-align mt-1'>
                                        {over ? "" : <OtpInput
                                            value={codeEmail}
                                            onChange={handleChangeEmailOTP}
                                            numInputs={6}
                                            separator={<span style={{ width: "8px" }}></span>}
                                            isInputNum={true}
                                            shouldAutoFocus={true}
                                            inputStyle={{
                                                border: "1px solid #CFD3DB",
                                                borderRadius: "8px",
                                                width: "54px",
                                                height: "54px",
                                                fontSize: "17px",
                                                color: "#000",
                                                fontWeight: "400",
                                                caretColor: "blue"
                                            }}
                                            focusStyle={{
                                                border: "1px solid #CFD3DB",
                                                outline: "none"
                                            }}
                                            className="otp-input"
                                        />}

                                    </div>
                                    {over ? "" : <p className="mb-1 form-error">{error}</p>}
                                    <div className='mt-1  verify-timing '><div>{over ? "" : <div> Code expires in</div>}</div> <div className='counter'>
                                        {resetOTP ? <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={false} /> : <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={true} />}
                                    </div> </div>
                                    <div className='mt-1 '>
                                        {over ? " " : <button className='btn-verify' >Submit</button>}
                                    </div>

                                    <div className=' resend-otp'> {over ? <p><span onClick={() => resendEmailOTP(EmailDisplay)} className=' resend-otp'>Resend Code </span> </p> : <p> Didn’t receive the code? <span onClick={() => resendEmailOTP(EmailDisplay)} className=' resend-otp'>Resend Code </span> </p>}</div>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
                <Footer />
            </SideNavbar>
        </div>
    )
}

export default UserManagement