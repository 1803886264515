import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, useMemo } from 'react'
import { Pagination } from 'react-bootstrap'
import { FaAccessibleIcon, FaImages, FaClipboard } from "react-icons/fa";
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import ModalOfferDetails from '../ModalPopup/ModalOfferDetails';
import imgViewIcon from '../../assets/img/imgView.svg'
import { postRequest, USERID,postRequestOffer } from '../Services/api';
import Moment from 'moment';
import PaginationComponent from './Pagination';
import Search from './Search';
import _, { truncate } from "lodash"
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import ImageViewer from '../ModalPopup/ImageViewer'

const SharedProfilePage = forwardRef((props, ref) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const [filterDataList,setFilterDataList] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sharedProfileList, getSharedProfileList] = useState([]);
  const [loading, setLoading] = useState(true)
  const [selectItemsPerPage, setselectItemsPerPage] = useState(10);
  const [offerStatus, setOfferStatus] = useState()
  const [showImg, setShowImg] = useState(false);
  const [imgView, setImageView] = useState()
  const handleShowImg = async (profileId) => {
    setShowImg(true);
    setImageView("")
    await postRequest('api/Profile/GetDocumentView?ProfileId=' + profileId).then((res) => {
      setImageView('data:application/pdf;base64,' + JSON.parse(res?.data?.Data))
      setLoading(false);
    });

  }
  const handleCloseImg = () => setShowImg(false);
  const handleChangeItem = (e) => {
    setselectItemsPerPage(e.target.value);
    setCurrentPage(1);
  }
  const handleStatusOnChange = (event, profileId) => {

  }
  useEffect(() => {
    setTotalItems(sharedProfileList.length);

  }, [sharedProfileList, offerStatus])

  let filteredData = useMemo(() => {
    let filterData = sharedProfileList?.filter((x) => Object.keys(x).some(y => String(x[y]).toLowerCase().includes(search.toLowerCase())));
    setTotalItems(filterData.length);
    return filterData
  }, [search, selectItemsPerPage, currentPage, sharedProfileList]);

  const [offerStatusChange, setOfferStatusChange] = useState()
  useEffect(() => {
    getSharedProfile();
    setCurrentPage(1);
    setOfferStatusChange()
  }, []);



  useImperativeHandle(ref, () => ({
    getSharedProfileChild() {
      getSharedProfile();
    },
  }));
  const getSharedProfile = async () => {
    setLoading(truncate);
    var res = await postRequest('api/Profile/GetShareProfile');
    var result = JSON.parse(res?.data?.Data);
    result.map((item) => {
      item.CreatedDate = Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss')
    });
    getSharedProfileList(result);
    setLoading(false);
  }

  const lastSessionNumber = currentPage * selectItemsPerPage;
  const firstSessionIndex = lastSessionNumber - selectItemsPerPage;
  const limitedSessions = filteredData.slice(
    firstSessionIndex,
    lastSessionNumber
  );

  return (
    <div>
      <div>

        <div >
          <Container fluid >
            <Row>
              <Col className='bg-white mb-3'>
                <div className='searched-table-heading'>
                  <h3>Recently Shared Profile</h3>
                </div>
                <div className='search-filter-box-custom'>
                  <div className='searched-table-heading mt-3 mb-2'>
                    <Search
                      onSearch={value => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                  <div>
                    <div className="wrap-select-arrow mt-3 mb-2">
                      <select className="form-control sorting-select " onChange={handleChangeItem}>
                        <option value={10} selected="selected">10 Items per page</option>
                        <option value={25}>25 Items per page</option>
                        <option value={50}>50 Items per page</option>
                        <option value={100}>100 Items per page</option>
                      </select>
                      <div className="select-arrow">
                        <div className="arrow-up"></div>
                        <div className="arrow-down"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" wrapper">
                  <Table striped responsive borderless className='  searched-table search-table inner share-table'>

                    <tr>
                      <th >shared on</th>
                      <th>Candidate name</th>
                      <th>Email</th>
                      <th>mobile number</th>
                      <th>job title</th>
                      <th>expected start date</th>
                      <th>experience</th>
                      <th>salary</th>
                      <th >status</th>
                      <th colSpan={2}>action</th>
                      {/* <th>attachment</th> */}
                    </tr>

                    <tbody>
                      {loading ?
                        <tr>
                          <td class="text-center" colSpan={10}> <div class="positioning-spinner">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden text-center">Loading...</span>
                            </Spinner>
                          </div></td>
                        </tr>
                        :
                        limitedSessions.length == 0 ?
                          <tr>
                            <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                          </tr> :
                          limitedSessions.map((item, index) => {

                            return (
                              <tr>
                                <td>{Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss') + ' ' + item.TimeZone}</td>
                                <td> <Link to={`/shareprofile/${item.ProfileId}`} className='search-name-text-color'>{item.CandidateName}</Link></td>
                                <td className='line-break-cus'>{item.Email}</td>
                                <td>{item.PhoneNumber}</td>
                                <td>{item?.JobTitle == "null" || item?.JobTitle == "undefined" ? "" : item.JobTitle}</td>
                                <td>{item.JoinDate == null ? null : Moment(item.JoinDate).format('MM/DD/YYYY')}</td>
                                <td>{item.ExperienceValue}</td>
                                <td>{item.SalaryValue}</td>
                                <td>
                                  {
                                    item.Status == "Created" ?
                                      <select className='offered-select-option' value={item.Status.toLowerCase()} id='status'
                                        onChange={e => {
                                          postRequestOffer('api/Profile/AccountEnable?profileId=' + item.ProfileId + '&status=' + e.target.value + '&isextrenal=false').then((res) => {
                                            var result = JSON.parse(res?.data?.Data);
                                            setOfferStatus(result)
                                            console.log(result?.Status)

                                            // if (res?.data?.Status) {
                                            getSharedProfile();
                                            if (result?.Status == "pending" || result?.Status == "Pending") {
                                              toast.success("Offer shared successfully");
                                            }
                                            else {
                                              toast.success("Status updated successfully");
                                            }

                                            // }
                                            // if (result?.Status === "interview") {
                                            //   console.log(e.target.value)
                                            //   debugger;
                                            //   window.location.href = `/schedule-interview/${item.ProfileId}&&isStatusEdit=true&isUpdate=false`;
                                            // }

                                          })
                                          postRequest('/api/Notification/OfferNotificationUpdate?ProfileId=' + item.ProfileId + '&status=' + e.target.value).then((res) => {
                                            setOfferStatusChange(res.data)
                                            if (res?.data?.Status) {

                                            }

                                          })
                                        }
                                        }
                                      >
                                        <option value="" selected >--Select--</option>
                                        <option value="pending">Share Offer</option>
                                        {/* <option value="interview">Interview</option> */}
                                      </select>
                                      :
                                      item.Status == "Declined" || item.Status == "declined" ?
                                        <select className='offered-select-option' value={item.Status.toLowerCase()} id='status'>
                                          <option value="Declined">Declined</option>
                                        </select>
                                        :
                                        item.Status == "pending" ?
                                          <select className='offered-select-option' value={item.Status.toLowerCase()} id='status'>
                                            <option value="Pending">Pending</option>
                                          </select>
                                          :
                                          item.Status == "Interview" || item.Status == "interview" ?
                                            <select className='offered-select-option' value={item.Status.toLowerCase()} id='status'>
                                              <option value="Interview">Interview</option>
                                            </select>
                                            : <select className='offered-select-option' value={item.Status.toLowerCase()} id='status' onChange={e => {
                                              postRequestOffer('api/Profile/AccountEnable?profileId=' + item.ProfileId + '&status=' + e.target.value + '&isextrenal=false').then((res) => {
                                                var result = JSON.parse(res?.data?.Data);
                                                setOfferStatus(result)
                                              })
                                              postRequest('/api/Notification/OfferNotificationUpdate?ProfileId=' + item.ProfileId + '&status=' + e.target.value).then((res) => {
                                                setOfferStatusChange(res.data)
                                                toast.success("Status updated successfully");
                                                getSharedProfile();
                                              })
                                            }
                                            }>
                                              {/* <option value="Interview">Interview</option> */}
                                              <option value="offered">Offered</option>
                                              <option value="onboard">Onboard</option>
                                              <option value="revoke">Revoke</option>
                                            </select>
                                  }
                                </td>
                                <td className='text-center'> <Link to={`/schedule-interview/${item.ProfileId}&&isStatusEdit=${true}&isUpdate=${false}`} className='search-name-text-color'>{item.Status == "Created" || item.Status == "created" ? <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="tooltip4">Interview</Tooltip>}
                                ><span>
                                    <FaClipboard className='intericon-color' size={18} />

                                  </span>
                                </OverlayTrigger> : ""}</Link></td>
                                <td className='text-center'>
                                  <Link to="/shareprofile" className='search-name-text-color ' onClick={() => handleShowImg(item.ProfileId)}>
                                    {
                                      item.ProfileDocument == "" ? "" :
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id="tooltip4">Attachment</Tooltip>}
                                        >
                                          <span>
                                            <img src={imgViewIcon} className='viewIcon-file' />
                                          </span>
                                        </OverlayTrigger>
                                    }
                                  </Link>
                                </td>
                              </tr>
                            )
                          })
                      }
                    </tbody>
                  </Table>
                </div>


                <div className='pagination-box '>
                  <div className='pagination-showing-count-status'>
                    {limitedSessions.length == 0 ? " " : <p>  Results: {currentPage == 1 ? totalItems > 0 ? 1 : 0 : totalItems > 0 ? ((currentPage - 1) * selectItemsPerPage) + 1 : 0} - {(currentPage - 1) == 0 ? totalItems < selectItemsPerPage ? totalItems : selectItemsPerPage : ((currentPage) * selectItemsPerPage) > totalItems ? totalItems : (currentPage) * selectItemsPerPage}  of {totalItems} </p>}
                  </div>
                  <div>

                    <PaginationComponent
                      itemsCount={totalItems}
                      itemsPerPage={selectItemsPerPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      alwaysShown={false}

                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>

        </div>
      </div>
      <ModalOfferDetails show={show} onClose={handleClose} />
      <ImageViewer imgView={imgView} loading={loading} showImg={showImg} onCloseImg={handleCloseImg} />
    </div >
  )
});

export default SharedProfilePage