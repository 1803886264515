import React,{useState} from 'react'

const SelectCategory = ({ onSearch, categoryData, setSelectedCategory, selectedCategory }) => {
    // const [search, setSearch] = useState("");
    const onInputChange = (value) => {
        onSearch(value);
    };
    
    return (
        <div className="wrap-select-arrow ">
            <select
                // style={{ width: "240px" }}
                placeholder="Search"
                className="form-control sorting-select "
                onChange={(e) => {
                    onInputChange(e.target.value);
                    setSelectedCategory(e.target.value);
                }}
                value={selectedCategory}
            >
                <option value=''   selected>Select Category</option>
                {
                    categoryData.map((item) => <option key={item.CategoryId} value={item.CategoryName}>{item.CategoryName}</option>)
                }
                
            </select>
            <div className="select-arrow">
                <div className="arrow-up"></div>
                <div className="arrow-down"></div>
            </div>
        </div>
    )
}

export default SelectCategory