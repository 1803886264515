import React, {useState, useMemo,useEffect  } from 'react'
import { Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import { FaEnvelope, FaUsers } from "react-icons/fa"; 
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FaChevronRight } from 'react-icons/fa'
import { postRequest } from '../Services/api';
import {  Link } from "react-router-dom";
import Moment from 'moment';
import { toast } from 'react-toastify';
import SideNavbar from '../Dashboard/SideNavbar';
import Spinner from 'react-bootstrap/Spinner'; 
import PaginationComponent from '../Profiles/Pagination';
import Search from '../Profiles/Search';
import Footer from '../Dashboard/Footer';

const InviteCompany = () => {
    const searchModel = { Email: "", CompanyName: "" }
    const [show, setShow] = useState(false); 
    const [formValues, setFormValues] = useState(searchModel);
    const [formErrors, setFormErrors] = useState({});  
    const [loading, setLoading] = useState(false);

    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [selectItemsPerPage, setselectItemsPerPage] = useState(10);

    const [invitationList, getInvitationList] = useState([]);

    const handleChangeItem = (e) => {
     setselectItemsPerPage(e.target.value);
     setCurrentPage(1);
    }
    useEffect(() => {
        getInvitation();
        setCurrentPage(1);
      }, []);

    const handleChange = (e) => { 
        const { name, value } = e.target;
        setFormValues(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        }) 
    };

    const getInvitation = async() => {
        setLoading(true);
        var res = await postRequest('api/Profile/GetInviteUsers');
        var result = JSON.parse(res?.data?.Data);
        result.map((item) => {
            item.CreatedDate = Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss')
        }) ;
        getInvitationList(result); 
        setLoading(false);
    }
    const handleSubmit = (e) => { 
        e.preventDefault();
        setShow(true) ;
        setFormValues(formValues);
        setFormErrors(validate(formValues));
        if (Object.keys(validate(formValues)).length === 0){            
              postRequest('api/Verification/AdminInviteUser?CompanyName='+ formValues.CompanyName +'&Email=' + formValues.Email,formValues).then ((res) => {
                setShow(false) ;
                if(res?.data?.Status){ 
                    toast.success('Invitation send successfully'); 
                    setFormValues(searchModel); setFormErrors({}); getInvitation();   
                }
                else { 
                    toast.error(res?.data?.ErrorMessage); 
                }
            }) ; 
        }else{
            setShow(false); 
        } 
    } 
    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/; 

        if (!values.Email) {
            errors.Email = "Email is required";
        }
       
        if (!regex.test(values.Email)) {            
            errors.Email = "Invalid email";                    
        }         
        return errors;
    };

    const handleResetPassword = async (email,companyName) => {
       const data =  {
            CompanyName: companyName,
            Email: email
          }
        await postRequest('api/Verification/ResendInvitation',data).then((res) => {
            if (res?.data.Status) {
                toast.success('Invitation resent successfully');               
            }
            else {
                toast.error(res?.data.ErrorMessage)
            }
        })
    };


    let filteredData = useMemo(()=>{
        let filterData = invitationList?.filter((x) => Object.keys(x).some(y => String(x[y]).toLowerCase().includes(search.toLowerCase())));
        setTotalItems(filterData.length);
        return filterData
      },[search,selectItemsPerPage,currentPage,invitationList]);

      const lastSessionNumber = currentPage * selectItemsPerPage;
      const firstSessionIndex = lastSessionNumber - selectItemsPerPage;
      const limitedSessions = filteredData.slice(
        firstSessionIndex,
        lastSessionNumber
      );



    return (
        <SideNavbar>
            <div>
                <Container fluid className='p-0 g-0' >
                    <Row className='g-0'>
                        <Col lg={6} xs={12} sm={12} className="mob-none">
                            <div className='breadcrumps-box'>
                                <div className='breadcrump-heading'>
                                    <p>Invite Company</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} sm={12}  className='breadcrumps-box  d-flex justify-content-end '>
                            <Breadcrumbs separator={<FaChevronRight className='text-white-breadcrums ' style={{ fontSize: "15px" }} />} >
                                <Link to='/' className='breadcrumps-links breadcrumps-links'>Dashboard</Link>
                                <Typography className='text-white-breadcrums cus-mr-5' >Invite Company</Typography>
                            </Breadcrumbs>
                        </Col>
                    </Row>
                </Container>
                <div>
                <Container fluid className='px-4 py-3'>
                    <Row>
                        <Col lg={12}>
                            <div className='bg-white px-4 py-3'>
                                <form onSubmit={handleSubmit}>
                                    <div className='search-profile-form'>
                                        <div className="did-floating-label-content mb-0 col-lg-5 col-sm-12 mb-2" > 
                                            <input className="did-floating-input" name='Email' value={formValues.Email} onChange={handleChange}  maxLength="75" type="text" placeholder=" " />
                                            <div className='icon-background' > <FaEnvelope className='user-input-icon' /></div>
                                            <span className='iconchange'></span>
                                            <label className="did-floating-label">Email *</label> 
                                            <p className="mb-1 form-error">{formErrors.Email}</p>
                                        </div> 
                                        <div className="did-floating-label-content mb-0 col-lg-5 col-sm-12 mb-2" > 
                                            <input className="did-floating-input" name='CompanyName' value={formValues.CompanyName} onChange={handleChange}  maxLength="75" type="text" placeholder=" " />
                                            <div className='icon-background ' > <FaUsers className='user-input-icon styel-organization-icon' /></div>
                                            <span className='iconchange'></span>
                                            <label className="did-floating-label">Organization Name</label> 
                                            <p className="mb-1 form-error">{formErrors.CompanyName}</p>
                                        </div> 
                                        <div className="search-reset-btn">
                                            <button className='search-btn' disabled={show}>{show ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span></> : null }
                                            &nbsp; Invite</button>
                                            {/* <input type="reset" className='reset-btn' value="Reset" onClick={() => {
                                                setFormValues(() => ""); setFormErrors({}); setShowNewCandidate(false); 
                                                setShow(false);
                                                }}/> */}
                                        </div>
                                    </div> 
                                </form>
                            </div>
                             
                       
                        </Col>
                    </Row>
                </Container>
            </div>
                <div>
                    <div>
                        <div >
                            <Container fluid className=' py-3 px-4' >
                                <Row className='px-3' >
                                <Col className='bg-white mb-3'>
                                    <div className='search-filter-box-custom'>
                                    <div className='searched-table-heading mt-3 mb-2'>
                                    <Search
                                                    onSearch={value => {
                                                        setSearch(value);
                                                        setCurrentPage(1);
                                                    }}
                                                /> 
                                    </div>
                                    <div>
                                        <div className="wrap-select-arrow mt-3 mb-2">
                                        <select className="form-control sorting-select " onChange={handleChangeItem}>
                                            <option value={10} selected="selected">10 Items per page</option>
                                            <option value={25}>25 Items per page</option>
                                            <option value={50}>50 Items per page</option>
                                            <option value={100}>100 Items per page</option> 
                                        </select>
                                        <div className="select-arrow">
                                            <div className="arrow-up"></div>
                                            <div className="arrow-down"></div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className=" wrapper">
                                    <Table striped responsive borderless className='  searched-table search-table inner share-table'>

                                        <tr>                      
                                        <th>Email</th>
                                        <th>Organization Name</th> 
                                        <th >Invited on</th> 
                                        <th>Action</th> 
                                        </tr>

                                        <tbody>
                                        {loading ?
                                                        <tr> 
                                                        <td class="text-center" colSpan={10}> <div class="positioning-spinner">
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden text-center">Loading...</span>
                                                            </Spinner>
                                                        </div></td> 
                                                    </tr>
                                        :limitedSessions.length == 0 ?
                                        <tr>
                                            <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                                        </tr> :
                                        limitedSessions.map((item,index) => {

                                            return (
                                                <tr key={index}>                              
                                                <td>{item.Email}</td>
                                                <td>{item.CompanyName}</td>  
                                                <td>{Moment(item.InvitedDate).format('MM/DD/YYYY HH:mm:ss')}</td> 
                                                <td>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip4">Resend Invitation</Tooltip>}>
                                                    <span> {item.IsRegister ? " " : <FaEnvelope onClick={() => handleResetPassword(item.Email,item.CompanyName)} className='mx-2' style={{ cursor: "pointer" }} />}</span>
                                                    </OverlayTrigger>
                                                </td>
                                                </tr>
                                            )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                    </div>


                                    <div className='pagination-box '>
                                    <div className='pagination-showing-count-status'>
                                    {limitedSessions.length == 0 ? "" :  <p>  Results: {currentPage == 1 ? totalItems > 0 ? 1 : 0 : totalItems > 0 ? ((currentPage - 1) * selectItemsPerPage) + 1 : 0} - {(currentPage - 1) == 0 ? totalItems < selectItemsPerPage ? totalItems : selectItemsPerPage : ((currentPage) * selectItemsPerPage) > totalItems ? totalItems : (currentPage) * selectItemsPerPage}  of {totalItems}</p>}
                                    </div>
                                    <div>

                                    <PaginationComponent
                                                    itemsCount={totalItems}
                                                    itemsPerPage={selectItemsPerPage}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    alwaysShown={false}
                                                    
                                                />
                                    </div>
                                    </div>
                                </Col>
                                </Row>
                            </Container>
                        </div> 
                    </div> 
                </div>
                <Footer />
            </div>
        </SideNavbar>
    )
}
export default InviteCompany