import React, { useRef, useState, useEffect } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
// import {
//     RecordWebcam,
//     useRecordWebcam,
//     CAMERA_STATUS
// } from 'react-record-webcam';

import { Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import VideoNo from '../../../assets/img/videono.png'
import AudioOff from '../../../assets/img/videooff.png'
import VideoOff from '../../../assets/img/cameraon.png'
import VideoOn from '../../../assets/img/VideoOn.png'
import AudioOn from '../../../assets/img/AudioOn.png'
import Mic from '../../../assets/img/mic.png'
// const OPTIONS = {
//     mimeType: 'video/webm',
//     audioBitsPerSecond: 128000,
//     videoBitsPerSecond: 5000000,
//     bitsPerSecond: 5120000
// };
const VideoComponent = (
    {
        currentQuestionIndex,
        questions,
        countdownFinished,
        duration,
        setStartButton,
        instruction,
        timeLeft,
        nextButtonShow,
        totalTime,
        currentTime,
        recordWebcam,
        videoBlobFile
    }
) => {
    const [videoBlob, setVideoBlob] = useState(null);

    // const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({
    //     video: true,
    //     audio: { echoCancellation: false },
    //     screen: false,
    //     options: OPTIONS
    // });
    const [loading, setLoading] = useState(true);
    const [isMuted, setIsMuted] = useState(false);

    const [VideoRef, setVideoRef] = useState(recordWebcam.webcamRef)
    const [micDevice, setMicDevice] = useState(null);
    const [micPermission, setMicPermission] = useState(null);
	const [clicked, setClicked] = useState(false);
    // console.log(videoBlobFile)

    const testMic = () => {
        navigator?.mediaDevices?.getUserMedia({ audio: true })
            .then((micStream) => {
                alert('Mic test successful!');
                micStream.getTracks().forEach((track) => track.stop());
            })
            .catch(() => alert('Mic test failed. Please check your microphone settings.'));
    };
    // console.log(videoBlob)
    const saveFile = async () => {
        //    var stop = await recordWebcam?.stop();
        const blob = await recordWebcam?.getRecording();
        console.log(blob)
        setVideoBlob(blob);
        // var open = await recordWebcam?.open()
    };
    const cameraStop = () => {
        recordWebcam?.stop()
    }
    const cameraStart = () => {
        recordWebcam?.start()
    }
    // useEffect(() => {
    //     recordWebcam.start();

    //     return () => {
    //         recordWebcam.stop();
    //     }
    // }, [currentQuestionIndex]);
    // useEffect(() => {
    //     cameraStart()
    //     if (timeLeft === 0) {
    //         cameraStop()
    //         saveFile()
    //         handleOpenCamera()
    //     }
    // }, [timeLeft]);
    const [isRecording, setIsRecording] = useState(false);
    const [volume, setVolume] = useState(0);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const animationFrameRef = useRef(null);

    useEffect(() => {
        // initialize audio context and analyzer
        audioContextRef.current = new AudioContext();
        analyserRef.current = audioContextRef.current.createAnalyser();
        navigator?.mediaDevices?.getUserMedia({ audio: true })
            .then(stream => {
                const source = audioContextRef.current.createMediaStreamSource(stream);
                source.connect(analyserRef.current);
                animate();
            })
            .catch(error => {
                // console.error(error);
            });

        // cleanup
        return () => {
            cancelAnimationFrame(animationFrameRef.current);
            audioContextRef.current.close();
        };
    }, []);
    useEffect(() => {
        if (navigator.mediaDevices) {
            navigator?.mediaDevices?.getUserMedia({ audio: true })
                .then((micStream) => {
                    setMicPermission(true);
                    navigator.mediaDevices.enumerateDevices()
                        .then((devices) => {
                            const availableMics = devices.filter((device) => device.kind === 'audioinput');
                            for (let i = 0; i < availableMics.length; i++) {
                                if (micStream.getAudioTracks()[0].getSettings().deviceId === availableMics[i].deviceId) {
                                    setMicDevice(availableMics[i].label);
                                    break;
                                }
                            }
                        });
                })
                .catch(() => setMicPermission(false));
        }
    }, []);

    const animate = () => {
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyserRef.current.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((acc, val) => acc + val, 0);
        const avg = sum / bufferLength;
        setVolume(avg);

        // request animation frame
        animationFrameRef.current = requestAnimationFrame(animate);
    };
    const [cameraOpen, setCameraOpen] = useState(false)
    useEffect(() => {
        // handleOpenCamera()
        animate()
        // testMic()
    }, [])
    const handleOpenCamera = () => {

        recordWebcam.open()
        setCameraOpen(true)
        setStartButton(true)
		setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 1000);
          setClicked(true);

        if (audioContextRef.current && audioContextRef.current.state === 'running') {
            audioContextRef.current.resume();

        }
    }
    const handleCloseCamera = () => {
        recordWebcam.close()
        setCameraOpen(false)
    }
    useEffect(() => {
        if (recordWebcam?.status === 'INIT') {
            setLoading(false)

        } else if (recordWebcam?.status === 'OPEN') {
            setLoading(true)
            setVideoRef(recordWebcam.webcamRef)
            // animate()
        }
    }, [recordWebcam?.status])
    // useEffect(() => {
    //     // start recording the webcam when the currentQuestion changes

    //     recordWebcam.start();

    //     // stop recording the webcam and get the recording when the time is up
    //     if (timeLeft === 0) {
    //         saveFile()
    //     //   recordWebcam.stop();
    //     //   const blob = recordWebcam.getRecording();
    //     //   console.log(blob);
    //     //   recordWebcam.open()
    //     }
    //   }, [currentQuestionIndex,timeLeft,countdownFinished]);
    // console.log(recordWebcam?.status)
    // console.log(countdownFinished)

    // const [progress, setProgress] = useState(0);

    // useEffect(() => {
    //     const newProgress = (currentTime / totalTime) * 100;
    //     setProgress(newProgress);
    // }, [totalTime, currentTime]);
    // console.log(((currentQuestionIndex * duration + (duration - timeLeft)) / totalTime));
    const progress = ((currentQuestionIndex * duration + (duration - timeLeft)) / totalTime) * 100;
    // console.log(progress)
    const minutesLeft = Math.floor((totalTime - currentTime) / 60);
    const secondsLeft = (totalTime - currentTime) % 60;
    // console.log(minutesLeft)
    const minutesTotal = Math.floor(totalTime / 60);
    const secondsTotal = totalTime % 60;
    return (
        <Col lg={3} md={12} className="mt-sm-cus-10">
            {/* <p>Camera status: {recordWebcam.status}</p> */}
            {
                instruction ?
                    <div className='progressbar-timeleft-align'>

                        <p className='mt-2 response-time-text'>
                            Response Time:{" "}
                            {timeLeft >= 0
                                ? `${minutesLeft}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`
                                : "0:00"}
                        </p>
                        <div className="progress-bar-timeleft">
                            <div className="progress-timeleft" style={{ width: `${progress > 100 ? 100 : progress}%` }}></div>
                        </div>
                        <p className='mt-2 response-time-text'>
                            Total Time: {minutesTotal}:{secondsTotal < 10 ? "0" : ""}
                            {secondsTotal}
                        </p>
                    </div> : ""

            }

            {!cameraOpen ?
                <div className=' bg-dark-video align-items-center  justify-content-center h-60'>
                    <div>


                    </div>
                    <div>
                        <div className='d-flex align-items-center   videoNo-mob-height justify-content-center'>
                            <div className='videoinitialState'>
                                <img src={VideoNo} className='img-cus-video-icon' />
                            </div>
                        </div>

                    </div>
                    <div></div>
                </div> : <div className='video-recorder-align'>
                    {/* {!loading ?
                        <div className="video-container" >
                            <div className={`lazy-loading ${loading ? 'is-loading' : ''}`}>
                                <div className="spinner"></div>
                            </div>
                        </div>
                        : */}
                    <video ref={VideoRef} playsInline autoPlay muted className='video-interview-height' />
                    {/* } */}
                </div>
            }

            <Row>
                <Col>
                    {
                        !nextButtonShow && <div className='d-flex justify-content-center mt-3 gap-3'>
                            {!cameraOpen ?
                                <figure className='text-center'>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip4">Click here</Tooltip>}
                                    >
                                        <span className='text-center'>
                                            <img src={VideoOff} onClick={handleOpenCamera} className='img-sizevidoe-audio' />
                                        </span>
                                    </OverlayTrigger>
                                    <figcaption className='caption-for-img'>Turn camera on</figcaption>
                                </figure>
                                : ""}
                            {/* {cameraOpen ? <img src={AudioOn} className='img-sizevidoe-audio' /> : ""} */}
                        </div>
                    }

                </Col>
            </Row>
            <div className='d-flex align-items-center gap-2 mt-3'>
                {/* <button onClick={handleToggleMute}>
                    {!isMuted ? 'Unmute' : 'Mute'}
                </button> */}
                {/* <img src={Mic} className='img-fluid ' width='14px' /> */}
                {/* <div className="progress-bar" style={{ width: `${volume}%` }} /> */}
            </div>
            {/* {videoBlobFile && <video src={URL.createObjectURL(videoBlobFile)} controls muted />} */}
            {/* <video ref={recordWebcam.previewRef} autoPlay muted loop /> */}
            {/* <div>

                <p>Camera status: {recordWebcam.status}</p>
                <button onClick={handleOpenCamera}>Open camera</button>
                <button onClick={recordWebcam.close}>Close camera</button>
                <button onClick={recordWebcam.start}>Start recording</button>
                <button onClick={() => { saveFile(); }}>Stop recording</button>
                <button onClick={recordWebcam.retake}>Retake recording</button>
                <button onClick={recordWebcam.download}>Download recording</button>
                <button onClick={testMic}>Test microphone</button>
                {micDevice && <p>Microphone: {micDevice}</p>}
                {micPermission !== null && micPermission === false && <p>Microphone permission denied.</p>}

                <video ref={recordWebcam.previewRef} autoPlay muted loop />
                {videoBlob && <video src={URL.createObjectURL(videoBlob)} controls muted />}
                <br />
                <br />

            </div> */}
        </Col>
    )
}

export default VideoComponent