import React, { useEffect, useState } from 'react'
import { Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/img/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import { FaUserAlt, FaUserTie, FaInfoCircle, FaRegCheckCircle, FaKey, FaUsers, FaEnvelope, FaPhoneAlt, FaEyeSlash, FaEye } from "react-icons/fa";
import OtpInput from "react-otp-input";
import CountDown from './CountDown'
import { postVerify } from '../Services/api';
import { postRequest, postLogin } from '../Services/api';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { setToken } from '../../app/slice/userAuthenticate'
import { useDispatch } from 'react-redux'
import { setTime } from '../../app/slice/OtpTimer'

const Signup = (props) => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [iconChange, setIconChange] = useState(false);
    const [passwordShown1, setPasswordShown1] = useState(false);
    const [iconChange1, setIconChange1] = useState(false);

    const [mobileShow, setMobileshow] = useState(false);
    const [emailShow, setEmailShow] = useState(false);

    const [mobileLoader, setMobileLoader] = useState(false);
    const [emailLoader, setEmailLoader] = useState(false);

    const dispatch = useDispatch();
    const iconToggle = () => {
        setIconChange(!iconChange);
        setPasswordShown(!passwordShown);
    }
    const iconToggle1 = () => {
        setIconChange1(!iconChange1);
        setPasswordShown1(!passwordShown1);
    }
    const init = {
        VerificationValue: "",
        VerificationType: "m"
    }
    const [formData, setData] = useState(init);
    const [formErrors, setFormErrors] = useState({});
    const [mobileOTPDisplay, setmobileOTPDisplay] = useState([])
    const [PhoneNumber, setPhoneNumber] = useState();
    const [phoneCode, setPhoneCode] = useState();
    const [countryCode, setCountryCode] = useState();
    const [countryCodePhone, setCountryCodePhone] = useState();
    const [mobValue, setMobValue] = useState()
    const [dialCode, setDialCode] = useState()
    const handleChangeMob = (mobValue, data, event, validate, setvalidate) => {
        setMobValue(mobValue)
        let Phone = mobValue.slice(data.dialCode.length)
        setDialCode(data.dialCode)
        setCountryCodePhone(data.countryCode)
        // let concat = mobValue?.phoneNumber
        // let remove = concat?.toString()
        // let Phone = remove?.replace(/[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/ ]/gi, '')
        setPhoneNumber(Phone)

        // let concat = mobValue?.phoneNumber
        // let remove = concat?.toString()
        // let Phone = remove?.replace(/[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/ ]/gi, '')
        // setPhoneNumber(Phone)
        // setPhoneCode(mobValue?.countryCode);
        // setCountryCode(mobValue?.countryData?.countryCode.toUpperCase())
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setMobileshow(true);
        const PhoneInput = {
            VerificationValue: PhoneNumber,
            VerificationType: "m"
        }
        // if (mobValue?.phoneNumber == null) {
        //     errorMessageMob("Enter Mobile Number");
        //     setMobileshow(false);
        //     return;
        // }

        if (mobValue == undefined || mobValue.length == 0) {
            errorMessageMob("Mobile number is required");
            setMobileshow(false);
            return;
        }

        if (PhoneNumber.length < 8) {
            errorMessageMob("Invalid mobile number");
            setMobileshow(false);
        }
        else {
            setFormErrors(validate(mobValue));
            if (mobValue.length != " " || errorMob.length == 0) {
                // console.log(PhoneInput)
                errorMessageMob("");
                setMobileLoader(true);
                postVerify(`api/Verification/PhoneNumberExists?phoneNumber=${PhoneNumber}`, PhoneInput).then((existsMob) => {
                    if (existsMob.data.ErrorMessage == "Already exists") {
                        setMobileshow(false);
                        setMobileLoader(false);
                        toast.error(" Mobile number already exists")
                    }
                    else {
                        postVerify('/api/Verification/SendVerification', PhoneInput).then((res) => {
                            if (res) {
                                let result = JSON.parse(res?.data?.Data)
                                setMobileLoader(false);
                                dispatch(setTime(res?.data?.Data));
                                setmobileOTPDisplay(result)
                                toast.success("Mobile verification SMS sent successfully")
                                errorMessageMob("")
                            }
                        });
                    }
                });
            }
        }

    }

    const [mins, setMinutes] = useState("15")

    const [reset, setReset] = useState(false)
    const [resetOTP, setResetOTP] = useState(false)
    useEffect(() => {
        resendMobOTP()
    }, [])
    const resendMobOTP = async () => {
        setCode(() => "")
        setOver(false)
        errorMessage("");
        setReset(!reset)
        setResetOTP(!resetOTP)
        const PhoneInput = {
            VerificationValue: PhoneNumber,
            VerificationType: "m"
        }
        await postVerify('/api/Verification/SendVerification', PhoneInput).then((res) => {



            if (res) {
                let result = JSON.parse(res?.data?.Data)
                dispatch(setTime(res?.data?.Data));
                toast.success("Mobile verification SMS resent successfully");
                setmobileOTPDisplay(result)

            }

        });
    }
    const [code, setCode] = useState("");
    const [codeEmail, setCodeEmail] = useState("")
    const [RegisterFormDisplay, setRegisterFormDisplay] = useState()
    const [emailverifyDisplay, setEmailverifyDisplay] = useState()
    const handleChangeMobOTP = (code) => setCode(code);
    const handleChangeEmailOTP = (codeEmail) => setCodeEmail(codeEmail);
    const OTPverifyMob = (e) => {
        e.preventDefault();
        if (code.length == " ") {
            errorMessage("Enter code");
            return;
        }
        else if (code.length !== 6) {
            errorMessage("Enter valid code");
            return;
        }
        if (code === mobileOTPDisplay?.VerificationCode) {
            setEmailverifyDisplay(PhoneNumber)
            setmobileOTPDisplay(!mobileOTPDisplay)
        }


        else {
            errorMessage("Enter valid code");
        }

    };

    const OTPverifyEmail = (e) => {
        e.preventDefault();
        if (codeEmail.length == " ") {
            errorMessage("Enter Code");
            return;
        }
        else if (codeEmail.length !== 6) {
            errorMessage("Enter valid code");
            return;
        }
        if (codeEmail === EmailOTPdisplay?.VerificationCode) {
            setRegisterFormDisplay(formEmailOTPData.VerificationValue)
            setmobileOTPDisplay(!mobileOTPDisplay)
            setEmailverifyDisplay(!emailverifyDisplay)
            errorMessage(" ");
        }


        else {
            errorMessage("Enter valid code");
        }
    };
    let [error, setError] = React.useState("");
    const errorMessage = (message) => {
        setError(message);
    }
    let [errorMob, setErrorMob] = React.useState("");
    const errorMessageMob = (message) => {
        setErrorMob(message);
    }




    const validate = (values) => {
        const errors = {};
        const phone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        if (!values.VerificationValue) {
            errors.VerificationValue = "Mobile Number is required.";
        }
        else if (!phone.test(values.VerificationValue)) {
            errors.VerificationValue = "Enter a valid Mobile Number.";
        }
        return errors;
    };



    // email otp verification    
    const initE = {
        VerificationValue: "",
        VerificationType: "e"
    }

    const [formEmailOTPData, setEmailOTPData] = useState(initE)
    const [formEmailError, setFormEmailErrors] = useState({})
    const [EmailOTPdisplay, setEmailOTPDisplay] = useState([])

    const handleChangeEmail = (event) => {
        const { name, value } = event.target
        setEmailOTPData(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        });
    }

    const handleEmailOTPSubmit = (e) => {
        e.preventDefault()
        setEmailShow(true)
        errorMessage("")
        setFormEmailErrors(validateEmail(formEmailOTPData));
        if (Object.keys(validateEmail(formEmailOTPData)).length === 0) {
            setEmailLoader(true);
            postVerify(`api/Verification/EmailExists?email=${formEmailOTPData.VerificationValue}`, formEmailOTPData).then((existsEmail) => {
                if (existsEmail.data.ErrorMessage == "Already exists") {
                    setEmailShow(false)
                    setEmailLoader(false);
                    toast.error(" Email already exists")
                }
                else {
                    postVerify('/api/Verification/SendVerification', formEmailOTPData).then((res) => {
                        if (res) {
                            let result = JSON.parse(res?.data?.Data)
                            setEmailLoader(false);
                            setEmailOTPDisplay(result)
                            toast.success("Email verification sent successfully")
                        }

                    });
                }
            });
        }
        else {
            setEmailShow(false);
        }
    }
    const resendEmailOTP = async () => {
        setCodeEmail(() => "")
        errorMessage("")
        setOver(false)
        setReset(!reset)
        setResetOTP(!resetOTP)
        await postVerify('/api/Verification/SendVerification', formEmailOTPData).then((res) => {



            if (res) {
                let result = JSON.parse(res?.data?.Data)
                toast.success("Email verification resent successfully");
                setEmailOTPDisplay(result)

            }

        });
    }
    const validateEmail = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        if (!values.VerificationValue) {
            errors.VerificationValue = "Email is required";
        }
        else if (!regex.test(values.VerificationValue)) {
            errors.VerificationValue = "Invalid email";
        }


        return errors;
    };

    // registration form funcationality code
    const intialValue = {
        PhoneNumber: formData?.VerificationValue,
        Email: formEmailOTPData?.VerificationValue,
        FirstName: "",
        LastName: "",
        CompanyName: "",
        ConfirmPassword: "",
        JobTitle: "",
        Password: "",
        accept: false
    }
    const [registerFormData, setRegisterFormData] = useState(intialValue)
    const [RegisterFormErrors, setRegisterFormErrors] = useState({});

    const handleFormChange = (event) => {
        const { name, value, type, checked } = event.target
        setRegisterFormData(prevForm => {
            return {
                ...prevForm,
                [name]: type === 'checkbox' ? checked : value
            }
        });
    }
    let navigate = useNavigate()
    const handleRegisterFormSubmit = (e) => {
        e.preventDefault();
        setRegisterFormErrors(validateForm(registerFormData));
        if (Object.keys(validateForm(registerFormData)).length === 0) {
            const data = {
                PhoneNumber: PhoneNumber,
                Email: formEmailOTPData?.VerificationValue,
                FirstName: registerFormData.FirstName,
                LastName: registerFormData.LastName,
                CompanyName: registerFormData.CompanyName,
                JobTitle: registerFormData.JobTitle,
                Password: registerFormData.Password,
                EmailVerified: true,
                PhoneNumberVerified: true,
                PhoneCode: dialCode,
                CountryCode: countryCodePhone
            };

            postRequest('api/Authentication/Register', data).then((res) => {
                if (res?.data?.ErrorMessage == "Already exists") {
                    toast.error("Organization name already exists");
                }
                else {
                    toast.success("Registered successfully")
                    redirectToMyProfile();


                }
                // navigate('/login')
            });
        }
    }
    const redirectToMyProfile = () => {
        const init = {
            email: formEmailOTPData?.VerificationValue,
            password: registerFormData.Password,
        }
        postLogin('api/Authentication/Login', init).then((res) => {
            dispatch(setToken(res?.data?.data));
            let token = JSON.stringify(res?.data?.data)
            localStorage.setItem("token", token)
            if (res?.data?.data?.token) {
                navigate('/myprofile', { replace: true })
            }
        });
    };


    const validateForm = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const nameregex = /^[A-Za-z]+$/;
        const passregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!values.FirstName) {
            errors.FirstName = "First name is required";
        } else if (!nameregex.test(values.FirstName)) {
            errors.FirstName = "Enter a valid first name";
        }
        if (!values.CompanyName) {
            errors.CompanyName = "Organization name is required";
        }
        if (!values.Password) {
            errors.Password = "Password is required"
        }

        if (!values.ConfirmPassword) {
            errors.ConfirmPassword = "Confirm password is required"
        }
        else if (!passregex.test(values.Password)) {
            errors.Password = "Please strengthen the password"
        }

        //    else if (!passregex.test(values.ConfirmPassword)) {
        //         errors.ConfirmPassword = "Enter a valid password."
        //     }
        else if (values.Password != values.ConfirmPassword) {
            errors.ConfirmPassword = "Confirm password do not match"
        }
        if (values.accept != true) {
            errors.accept = "accept terms and condition"
        }


        return errors;
    };

    const [over, setOver] = React.useState(false);

    const handleKeyPressalpha = (e) => {
        var key = e.key;
        var regex = /^[a-zA-Z]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
            e.toUpperCase();
        }

    }
    const handleKeyPressalphaLastName = (e) => {
        var key = e.key;
        var regex = /^[a-zA-Z ]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }

    }

    const handleKeyPressnumber = (e) => {
        var key = e.key;
        var regex = /^[0-9\b]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }


    }

    return (
        <div>
            <div className='bg-signup'>

                <div className=''>
                    <Container fluid className='p-lg-5'>
                        <Row >
                            {/* <Col lg={1}></Col> */}
                            <Col className='bg-white rounded-box p-lg-2 pt-1 pb-1 px-lg-4 py-2'>
                                <div className='d-flex  justify-content-between'>
                                    <div >
                                        <img src={Logo} className='img-fluid' />
                                    </div>
                                    <div className='already-user-login'>
                                        <p>Already a user?<Link to='/login' className='link-text-decoration'><span>Login</span></Link></p>
                                    </div>
                                </div>
                                <div className='text-center signin-heading'>
                                    <h1>Get Started</h1>
                                    <p>Create an account {mobileOTPDisplay?.VerificationCode} {EmailOTPdisplay?.VerificationCode}

                                    </p>
                                </div>
                                {/* mobile verification code */}
                                {
                                    RegisterFormDisplay ? "" : emailverifyDisplay ? "" : <Row>
                                        <Col lg={3}>
                                        </Col>
                                        <Col lg={6} className="mt-2">
                                            <form onSubmit={handleSubmit}>

                                                <div className='align-signup-form' disabled={mobileShow}>
                                                    <div>

                                                        <PhoneInput
                                                            label="MobileNumber"
                                                            onChange={handleChangeMob}
                                                            placeholder="Mobile Number *"
                                                            value={mobValue}
                                                            defaultCountry={"in"}
                                                            disabled={mobileShow}
                                                            autoCorrect="off"
                                                            country={'us'}
                                                        />
                                                        <p className="mb-1 form-error">{errorMob}</p>
                                                    </div>
                                                    <button className='btn-verify' disabled={mobileShow}>{mobileLoader ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></> : null} Send verification SMS</button>
                                                    <input type="reset" className='btn-verify btn-secondary' id="reset" value="Clear" onClick={() => {
                                                        setmobileOTPDisplay(!mobileOTPDisplay);
                                                        errorMessage("");
                                                        setMobileshow(false);
                                                        setMobValue(() => "");
                                                        setCode(() => "");
                                                        errorMessageMob("")

                                                    }} />

                                                </div>

                                            </form>

                                        </Col>
                                        <Col lg={3}>
                                        </Col>
                                    </Row>
                                }

                                {
                                    mobileOTPDisplay?.VerificationCode &&
                                    <Row >
                                        <Col lg={2}>
                                        </Col>
                                        <Col lg={8}>
                                            <form onSubmit={OTPverifyMob}>
                                                <div className='verification-layout-css align-verify mt-lg-2'>
                                                    <div className='mobile-verify-content'>
                                                        <h1>Mobile Number Verification</h1>
                                                    </div>

                                                    <h4 className='mt-1  px-5'>Verification code has been sent to your {PhoneNumber} mobile number</h4>

                                                    <div className='otp-align mt-1'>
                                                        {over ? "" :
                                                            <OtpInput
                                                                value={code}
                                                                onChange={handleChangeMobOTP}
                                                                numInputs={6}
                                                                separator={<span style={{ width: "8px" }}></span>}
                                                                isInputNum={true}
                                                                shouldAutoFocus={true}
                                                                inputStyle={{
                                                                    border: "1px solid #CFD3DB",
                                                                    borderRadius: "8px",
                                                                    width: "54px",
                                                                    height: "54px",
                                                                    fontSize: "17px",
                                                                    color: "#000",
                                                                    fontWeight: "400",
                                                                    caretColor: "blue"
                                                                }}
                                                                focusStyle={{
                                                                    border: "1px solid #CFD3DB",
                                                                    outline: "none"
                                                                }}
                                                                className="otp-input"
                                                                disabledStyle={"disabled"}
                                                            />}

                                                    </div>
                                                    {over ? "" : <p className="mb-1 form-error">{error}</p>}
                                                    <div className='mt-1  verify-timing '>{over ? "" : <div> Code expires in</div>}  <div className='counter' >
                                                        {resetOTP ? <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={false} /> : <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={true} />}
                                                    </div> </div>
                                                    <div className='mt-1 '>
                                                        {over ? "" : <button className='btn-verify' >Submit</button>}
                                                    </div>
                                                    <div className=' resend-otp'>
                                                        {over ? <p><span onClick={() => resendMobOTP()}>Resend Code</span> </p> : <p> Didn’t receive the code? <span onClick={() => resendMobOTP()}>Resend Code</span> </p>}
                                                    </div>
                                                </div>
                                            </form>


                                        </Col>
                                        <Col lg={2}>
                                        </Col>
                                    </Row>
                                }


                                {/* email verification code  */}


                                {emailverifyDisplay && <div><Row>
                                    <Col lg={3}>
                                    </Col>
                                    <Col lg={6} className="mt-2">
                                        <form onSubmit={handleEmailOTPSubmit}>

                                            <div className='align-signup-form'>

                                                <div className="did-floating-label-content  col-lg-6 col-sm-9" >
                                                    <input className="did-floating-input" disabled={emailShow} autocomplete="new-password" onChange={handleChangeEmail} value={formEmailOTPData.VerificationValue} maxLength="75" name="VerificationValue" type="text" placeholder=" " />
                                                    <div className='icon-background' > <FaEnvelope className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label" >Email *</label>
                                                    <p className="mb-1 form-error">{formEmailError.VerificationValue}</p>
                                                </div>

                                                <button className='btn-verify' disabled={emailShow}>{emailLoader ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></> : null} Send verification email</button>
                                                <input type="reset" className='btn-verify btn-secondary mb-2' id="reset" value="Clear" onClick={() => {
                                                    setEmailOTPDisplay(!EmailOTPdisplay);
                                                    setFormEmailErrors("");
                                                    setEmailShow(false);
                                                    setEmailOTPData({
                                                        VerificationValue: "",
                                                        VerificationType: "e"
                                                    });
                                                    setCodeEmail(() => "");
                                                    errorMessage("");

                                                }} />
                                            </div>

                                        </form>

                                    </Col>
                                    <Col lg={3}>
                                    </Col>
                                </Row>
                                </div>
                                }


                                {EmailOTPdisplay?.VerificationCode && !RegisterFormDisplay && <Row >
                                    <Col lg={2}>
                                    </Col>
                                    <Col lg={8}>
                                        <form onSubmit={OTPverifyEmail}>


                                            <div className='verification-layout-css align-verify'>
                                                <div className='mobile-verify-content'>
                                                    <h1>Email Verification</h1>
                                                </div>

                                                <h4 className='mt-1  px-5'>Verification code has been sent to your&nbsp;
                                                    {formEmailOTPData.VerificationValue} email </h4>

                                                <div className='otp-align mt-1'>
                                                    {over ? "" :
                                                        <OtpInput
                                                            value={codeEmail}
                                                            onChange={handleChangeEmailOTP}
                                                            numInputs={6}
                                                            separator={<span style={{ width: "8px" }}></span>}
                                                            isInputNum={true}
                                                            shouldAutoFocus={true}
                                                            inputStyle={{
                                                                border: "1px solid #CFD3DB",
                                                                borderRadius: "8px",
                                                                width: "54px",
                                                                height: "54px",
                                                                fontSize: "17px",
                                                                color: "#000",
                                                                fontWeight: "400",
                                                                caretColor: "blue"
                                                            }}
                                                            focusStyle={{
                                                                border: "1px solid #CFD3DB",
                                                                outline: "none"
                                                            }}
                                                            className="otp-input"
                                                        />}
                                                </div>
                                                {over ? "" : <p className="mb-1 form-error">{error}</p>}
                                                <div className='  verify-timing '><div>{over ? "" : <div> Code expires in</div>} </div> <div className='counter'>
                                                    {resetOTP ? <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={false} /> : <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={true} />}
                                                </div> </div>
                                                <div className=''>
                                                    {over ? "" : <button className='btn-verify' >Submit </button>}
                                                </div>

                                                <div className='mt-1 resend-otp'>
                                                    {over ? <p><span onClick={resendEmailOTP}>Resend Code </span> </p> : <p> Didn’t receive the code? <span onClick={resendEmailOTP}>Resend Code </span> </p>}


                                                </div>
                                            </div>
                                        </form>

                                    </Col>
                                    <Col lg={2}>
                                    </Col>
                                </Row>}




                                {/* register form UI code */}

                                {RegisterFormDisplay && <Row>
                                    <Col lg={2}>
                                    </Col>
                                    <Col lg={8} className="mt-2">
                                        <form onSubmit={handleRegisterFormSubmit}>

                                            <div className='search-profile-form'>
                                                <div className='align-register-form-mobile'>
                                                    <div className="did-floating-label-content position-mob-verify-icon  col-lg-12 col-sm-12 col-xs-12 mobile-number-country-code"  >
                                                        <input className="did-floating-input" autocomplete="new-password" value={PhoneNumber} name="PhoneNumber" maxLength="25" type="text" placeholder=" " />
                                                        <div className='icon-background' > <FaPhoneAlt className='user-input-icon' /></div>
                                                        <span className='iconchange'></span>
                                                        <label className="did-floating-label position-mob-verify-icon " >Mobile Number</label>
                                                        <FaRegCheckCircle className='veryfied-icon' />
                                                    </div>
                                                </div>
                                                <div className="did-floating-label-content  position-mob-verify-icon col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" value={formEmailOTPData?.VerificationValue} name="Email" maxLength="25" type="email" placeholder=" " />
                                                    <div className='icon-background' > <FaEnvelope className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label position-mob-verify-icon " >Email</label>
                                                    <FaRegCheckCircle className='veryfied-icon' />
                                                </div>

                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" name="FirstName" onChange={handleFormChange} onKeyDown={(e) => handleKeyPressalpha(e)} maxLength="25" type="text" placeholder=" " />
                                                    <div className='icon-background' > <FaUserAlt className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">First Name *</label>
                                                    <p className="mb-1 form-error form-error">{RegisterFormErrors.FirstName}</p>
                                                </div>
                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" onChange={handleFormChange} onKeyDown={(e) => handleKeyPressalphaLastName(e)} maxLength="25" name="LastName" type="text" placeholder=" " />
                                                    <div className='icon-background' > <FaUserAlt className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Last Name </label>
                                                </div>
                                                <div className="did-floating-label-content col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" onChange={handleFormChange} name="CompanyName" type="text" placeholder=" " />
                                                    <div className='icon-background' > <FaUsers className='user-input-icon styel-organization-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Organization Name *</label>
                                                    <p className="mb-1 form-error form-error">{RegisterFormErrors.CompanyName}</p>
                                                </div>
                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" onChange={handleFormChange} name="JobTitle" maxLength="75" type="text" placeholder=" " />
                                                    <div className='icon-background' > <FaUserTie className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Job Title</label>
                                                </div>
                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" onChange={handleFormChange} name="Password" maxLength="20" type={passwordShown ? "text" : "password"} placeholder=" " />
                                                    <div className='icon-background'> <FaKey className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label passtooltip-align-mob">Password *</label>
                                                    <p className="mb-1 form-error form-error passtooltip-align-mob">{RegisterFormErrors.Password}</p>
                                                    {iconChange ? <div className='icon-login-mob'> <FaEyeSlash className='user-input-icon-eye-subuser' onClick={iconToggle} /> </div> : <div className='icon-login-mob'><FaEye className='user-input-icon-eye-subuser' onClick={iconToggle} /></div>}
                                                    <OverlayTrigger
                                                        placement="top"

                                                        overlay={<Tooltip > Password must contain minimum 8-20 characters,<br /> at least one lowercase,<br /> at least one uppercase, <br />at least one number and<br /> at least one special character</Tooltip>}>
                                                        
                                                            <span className="tooltip-alignpassword">
                                                                {<FaInfoCircle />}
                                                            </span>
                                                    </OverlayTrigger>
                                                </div>

                                                <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                    <input className="did-floating-input" autocomplete="new-password" onChange={handleFormChange} name="ConfirmPassword" maxLength="20" type={passwordShown1 ? "text" : "password"} placeholder=" " />
                                                    <div className='icon-background'> <FaKey className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Confirm Password *</label>
                                                    <p className="mb-1 form-error form-error">{RegisterFormErrors.ConfirmPassword}</p>
                                                    {iconChange1 ? <div className='icon-login-mob'> <FaEyeSlash className='user-input-icon-eye-subuser' onClick={iconToggle1} /> </div> : <div className='icon-login-mob'><FaEye className='user-input-icon-eye-subuser' onClick={iconToggle1} /></div>}
                                                </div>

                                            </div>
                                            <div className='text-center terms-condition-check'>
                                                <input type="checkbox" name="accept" onChange={handleFormChange} checked={registerFormData.accept} className='checkbox-termsandcondition' />
                                                <p className='text-center'>By creating an account, you agree to our <Link to='/terms-and-condition' className='text-color-privacy-terms' target="_blank">Terms & Conditions </Link>  and <Link to='/privacy-policy' className='text-color-privacy-terms' target="_blank">Privacy Policy</Link></p>

                                            </div>
                                            {
                                                registerFormData.accept ?
                                                    <div className='d-flex justify-content-center'>
                                                        <button className='btn-register-form'>Register</button>
                                                    </div>
                                                    :
                                                    <div className='d-flex justify-content-center'>
                                                        <button className='btn-register-form btn-secondary' disabled={!registerFormData.accept}>Register</button>
                                                    </div>
                                            }

                                        </form>

                                    </Col>
                                    <Col lg={2}>
                                    </Col>
                                </Row>
                                }
                            </Col>
                        </Row>
                    </Container>

                </div>

            </div>
        </div>
    )
}

export default Signup