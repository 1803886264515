import React, { useState, useEffect, useRef } from 'react';
import TestVideo from '../../../assets/video/test.mp4';
import './InterviewVideos.css'
import Spinner from 'react-bootstrap/Spinner';
import playButton from '../../../assets/img/Vector.svg'
import pauseButton from '../../../assets/img/pause.svg'
import Expand from '../../../assets/img/expand.svg'
import VideoPreview from '../../ModalPopup/VideoPreview';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download'
import axios from 'axios'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toast } from 'react-toastify';
const InterviewVideo = ({ profileId, videoData, loading, handleVideoDelete }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [notesPerPage, setNotesPerPage] = useState(1);
    const [currentNotes, setCurrentNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDownload, setIsLoadingDownload] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setVolume] = useState(100);
    const videoRef = useRef(null);
    const handleLoader = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };
    useEffect(() => {
        const indexOfLastNote = currentPage * notesPerPage;
        const indexOfFirstNote = indexOfLastNote - notesPerPage;
        setCurrentNotes(videoData?.length > 0 ? videoData?.slice(indexOfFirstNote, indexOfLastNote) : []);
    }, [currentPage, notesPerPage, videoData]);

    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setIsWaiting(false);
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
        setCurrentTime(0)
        setIsPlaying(false)
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(videoData.length / notesPerPage); i++) {
        pageNumbers.push(i);
    }


    const maxPageNumbersToShow = 5;
    const halfOfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);

    let pageNumbersToShow = [];

    if (pageNumbers.length <= maxPageNumbersToShow) {
        pageNumbersToShow = pageNumbers;
    } else if (currentPage <= halfOfMaxPageNumbersToShow) {
        pageNumbersToShow = [...pageNumbers.slice(0, maxPageNumbersToShow - 1), '...', pageNumbers[pageNumbers.length - 1]];
    } else if (currentPage >= pageNumbers.length - halfOfMaxPageNumbersToShow) {
        pageNumbersToShow = [pageNumbers[0], '...', ...pageNumbers.slice(pageNumbers.length - (maxPageNumbersToShow - 1), pageNumbers.length)];
    } else {
        pageNumbersToShow = [pageNumbers[0], '...', ...pageNumbers.slice(currentPage - halfOfMaxPageNumbersToShow + 1, currentPage + halfOfMaxPageNumbersToShow - 1), '...', pageNumbers[pageNumbers.length - 1]];
    }
    const [showImg, setShowImg] = useState(false);
    const [VideoPreviewFile, setVideoExpand] = useState("");
    // const [loading, setLoading] = useState(true)
    const handleCloseImg = () => {
        const video = videoRef.current;
        setShowImg(false)
        video.pause()
    };
    const handleShowImg = (profileId, videoPre) => {
        const video = videoRef.current;
        setShowImg(true);
        setVideoExpand(videoPre)
        video.pause()
    }

    const handleVolumeChange = (event) => {
        const newVolume = event.target.value;
        setVolume(newVolume);
        videoRef.current.volume = newVolume / 100;
    };
    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${String(seconds).padStart(2, '0')}`;
    }

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;
        video.addEventListener('timeupdate', handleTimeUpdate);
        return () => {
            video.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, []);

    const handlePlayClick = () => {
        const video = videoRef.current;
        if (!video) return;
        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = (event) => {
        setCurrentTime(event.target.currentTime);
    };
    const handleTimelineClick = (event) => {
        const video = videoRef.current;
        if (!video) return;
        const timeline = event.currentTarget;
        const rect = timeline.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const ratio = x / rect.width;
        video.currentTime = ratio * video.duration;
    };
    const [isBuffering, setIsBuffering] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [durationSec, setDurationSec] = useState(1);
    const [elapsedSec, setElapsedSec] = useState(1);
    const [waitingProgress, setWaitingProgress] = useState(0);
    const [waitingIntervalId, setWaitingIntervalId] = useState(null);
    const [waitingTime, setWaitingTime] = useState(0);
    const progressRef = useRef < HTMLDivElement > (null);
    const bufferRef = useRef < HTMLDivElement > (null);
    const waitingIntervalRef = useRef(null);
    const [waitingPercentage, setWaitingPercentage] = useState(0);
    const [bufferingPercentage, setBufferingPercentage] = useState(0);
    const bufferingIntervalRef = useRef(null);
    const isEventListenersAddedRef = useRef(false);
    // useEffect(() => {
    //     const handleWaiting = () => {
    //         setIsBuffering(true);
    //         startBufferingTimer();
    //     };

    //     const handlePlaying = () => {
    //         setIsBuffering(false);
    //         clearInterval(bufferingIntervalRef.current);
    //         setBufferingPercentage(100);
    //     };

    //     const startBufferingTimer = () => {
    //         let startTime = performance.now();
    //         let bufferedTime = 0;

    //         bufferingIntervalRef.current = setInterval(() => {
    //             const currentTime = performance.now();
    //             const elapsedTime = currentTime - startTime;

    //             // Calculate buffering time in seconds
    //             const bufferingSeconds = Math.floor(elapsedTime / 1000);

    //             // Calculate the buffering percentage
    //             const maxBufferingTime = videoRef.current ? videoRef.current.duration : 0;
    //             const currentBufferingPercentage = (bufferingSeconds / maxBufferingTime) * 100;

    //             // Update buffering percentage if it's less than the current value
    //             if (currentBufferingPercentage > bufferedTime) {
    //                 bufferedTime = currentBufferingPercentage;
    //                 setBufferingPercentage(bufferedTime);
    //             }
    //         }, 1000); // Adjust the interval duration as needed
    //     };

    //     const videoElement = videoRef.current;
    //     if (videoElement) {
    //         videoElement.addEventListener("waiting", handleWaiting);
    //         videoElement.addEventListener("playing", handlePlaying);
    //     }

    //     return () => {
    //         clearInterval(bufferingIntervalRef.current);
    //         if (videoElement) {
    //             videoElement.removeEventListener("waiting", handleWaiting);
    //             videoElement.removeEventListener("playing", handlePlaying);
    //         }
    //     };
    // }, [videoRef.current]);
    useEffect(() => {
        const handleWaiting = () => {
            setIsWaiting(true);
            startWaitingTimer();
        };

        const handlePlaying = () => {
            setIsWaiting(false);
            clearInterval(waitingIntervalRef.current);
            setWaitingPercentage(100);
        };

        const startWaitingTimer = () => {
            let startTime = performance.now();
            let waitingTime = 0;

            waitingIntervalRef.current = setInterval(() => {
                const currentTime = performance.now();
                const elapsedTime = currentTime - startTime;

                // Calculate waiting time in seconds
                const waitingSeconds = Math.floor(elapsedTime / 1000);

                // Calculate the waiting percentage
                const maxWaitingTime = videoRef.current ? videoRef.current.duration : 0;
                const currentWaitingPercentage = Math.min(
                    Math.floor((waitingSeconds / maxWaitingTime) * 100),
                    100
                );

                // Update waiting percentage if it's less than the current value
                if (currentWaitingPercentage > waitingTime) {
                    waitingTime = currentWaitingPercentage;
                    setWaitingPercentage(waitingTime);
                }
            }, 1000); // Adjust the interval duration as needed
        };

        const videoElement = videoRef.current;

        if (videoElement && !isEventListenersAddedRef.current) {
            videoElement.addEventListener("waiting", handleWaiting);
            videoElement.addEventListener("playing", handlePlaying);
            isEventListenersAddedRef.current = true;
        }

        return () => {
            clearInterval(waitingIntervalRef.current);
            if (videoElement && isEventListenersAddedRef.current) {
                videoElement.removeEventListener("waiting", handleWaiting);
                videoElement.removeEventListener("playing", handlePlaying);
                isEventListenersAddedRef.current = false;
            }
        };
    }, [videoRef.current, currentPage, isWaiting, isPlaying]);


    // useEffect(() => {
    //     if (!videoRef.current) {
    //         return;
    //     }

    //     const onWaiting = () => {
    //         if (isPlaying) setIsPlaying(false);
    //         setIsWaiting(true);
    //         setWaitingProgress(0); // Reset waiting progress when buffering starts
    //         const intervalId = setInterval(() => {
    //             setWaitingProgress(prevProgress => prevProgress + 1);
    //         }, 100); // Update the waiting progress every 100 milliseconds
    //         setWaitingIntervalId(intervalId);
    //     };

    //     const onPlay = () => {
    //         if (isWaiting) setIsWaiting(false);
    //         setIsPlaying(true);

    //     };

    //     const onPause = () => {
    //         setIsPlaying(false);
    //         setIsWaiting(false);
    //     };

    //     const element = videoRef.current;



    //     const onTimeUpdate = () => {
    //         setIsWaiting(false);
    //         if (!element.buffered || !progressRef.current) return;
    //         const duration = element.duration;
    //         setDurationSec(duration);
    //         setElapsedSec(element.currentTime);
    //         if (progressRef && duration > 0) {
    //             progressRef.current.style.width =
    //                 (element.currentTime / duration) * 100 + "%";
    //         }
    //     };

    //     element.addEventListener("timeupdate", onTimeUpdate);

    //     element.addEventListener("waiting", onWaiting);
    //     element.addEventListener("play", onPlay);
    //     element.addEventListener("playing", onPlay);
    //     element.addEventListener("pause", onPause);

    //     // clean up
    //     return () => {
    //         element.removeEventListener("waiting", onWaiting);
    //         element.removeEventListener("play", onPlay);
    //         element.removeEventListener("playing", onPlay);
    //         element.removeEventListener("pause", onPause);
    //         element.removeEventListener("timeupdate", onTimeUpdate);
    //     };
    // }, [videoRef.current]);


   // console.log(isBuffering)
    const handleDownload = (url, filename) => {
        // console.log(url)
        setIsLoadingDownload(true)
        toast.success(" Video download start")
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
              //  console.log(res)
                fileDownload(res.data, filename)
                setIsLoadingDownload(false)
            })
    }
    const handleVideoLoad = () => {
        setIsLoading(false);
    };

    //console.log(waitingPercentage)
    const [loadProgress, setLoadProgress] = useState(0);
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const handleVideoProgress = (event) => {
        const video = event.target;
        const loadedPercentage = (video.buffered.end(0) / video.duration) * 100;
        setLoadProgress(loadedPercentage);
    };
    const observerRef = useRef(null);
    useEffect(() => {
        const options = {
            threshold: 0.5, // Adjust the threshold as needed
        };

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    startVideoLoadProgress();
                } else {
                    pauseVideoLoadProgress();
                }
            });
        };

        if (videoRef.current) {
            observerRef.current = new IntersectionObserver(handleIntersection, options);
            observerRef.current.observe(videoRef.current);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);


    const startVideoLoadProgress = () => {
        videoRef.current.addEventListener('progress', handleVideoLoadProgress);
    };

    const pauseVideoLoadProgress = () => {
        videoRef.current.removeEventListener('progress', handleVideoLoadProgress);
    };

    const handleVideoLoadProgress = () => {
        const video = videoRef.current;
        if (video && video.buffered && video.buffered.length > 0) {
            const loadedBytes = video.buffered.end(0);
            const totalBytes = videoRef.current.duration;
            const percentage = (loadedBytes / totalBytes) * 100;
            setLoadingPercentage(percentage);
        }
    };


    return (
        <div>
            {/* <div className="title-interview-docs mt-4">
                <h4>Interview Video</h4>
            </div> */}

            {
                videoData[0]?.MergeVideo !== null && videoData[1]?.MergeVideo !== null && videoData[3]?.MergeVideo !== null ?
                    <div>
                        {
                            loading ?
                                <div className='d-flex justify-content-center'>
                                    <Spinner animation="border" role="status">
                                        < span className="visually-hidden text-center">Loading...</span>
                                    </Spinner >
                                </div>
                                :

                                <div>
                                    {isLoading ? (
                                        <div className='d-flex justify-content-center'>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden text-center">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : <div>
                                        {

                                            currentNotes?.map((item) => {
                                                return (
                                                    <div className='position-relative'>
                                                        {/* {isWaiting &&
                                                            <div className='position-relative'>
                                                                <Spinner animation="border" role="status" className='spinner-video-buffer'>
                                                                    <span className="visually-hidden text-center">Loading...</span>
                                                                </Spinner>
                                                            </div>
                                                        }
                                                        <div>Video Loading Percentage: {loadingPercentage.toFixed(2)}%</div>
                                                        <div className="load-spinner">
                                                            <div className="spinner-progress" style={{ width: `${loadProgress}%` }} />
                                                            <div className="spinner-percentage">{Math.round(loadProgress)}%</div>
                                                        </div> */}
                                                        {/* {isWaiting && <div> */}
                                                        {
                                                            isWaiting ? <div className='d-flex video-percentage-main' >
                                                                <div style={{ width: 50, height: 50 }}>
                                                                    <CircularProgressbar value={waitingPercentage} text={`${waitingPercentage}%`}
                                                                    // styles={buildStyles({
                                                                    //     // Rotation of path and trail, in number of turns (0-1)
                                                                    //     rotation: 0.25,

                                                                    //     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                    //     strokeLinecap: 'butt',

                                                                    //     // Text size
                                                                    //     textSize: '16px',

                                                                    //     // How long animation takes to go from one percentage to another, in seconds
                                                                    //     pathTransitionDuration: 0.5,

                                                                    //     // Can specify path transition in more detail, or remove it entirely
                                                                    //     // pathTransition: 'none',

                                                                    //     // Colors
                                                                    //     pathColor: `rgba(247, 163, 64, ${4 / 100})`,
                                                                    //     textColor: '#ffffff',
                                                                    //     trailColor: '#ffff',
                                                                    //     backgroundColor: '#ffffff',
                                                                    //   })}
                                                                    />
                                                                </div>
                                                                {/* <div className="progress-bar-container-video-buffer">

                                                                <div
                                                                    className="progress-bar-fill-buffer"
                                                                    style={{ width: `${waitingPercentage}%` }}
                                                                />
                                                            </div>
                                                            <div className='text-white'><span>{waitingPercentage}%</span></div> */}
                                                            </div> : ""
                                                        }


                                                        {/* </div>
                                                        } */}
                                                        {/* {isBuffering && <div>Buffering...</div>}
                                                        <div>Buffering Percentage: {bufferingPercentage.toFixed(2)}%</div> */}

                                                        {
                                                            item?.MergeVideoPath === null || item?.MergeVideoPath === "" ? <h5> There is no video</h5> :
                                                                <div className="video-player">


                                                                    {/* <video controls >
                                                                <source src='http://72.249.77.205:8082/StaticFiles/11465_638212833377071979.mp4'>
                                                                </source>
                                                            </video> */}


                                                                    <video ref={videoRef} onPlay={() => setIsPlaying(true)} onProgress={handleVideoProgress} onPause={() => setIsPlaying(false)} onTimeUpdate={handleTimeUpdate} onClick={handlePlayClick} preload="auto" onLoadedData={handleVideoLoad}>
                                                                        <source src={'D:/RPortal_Backups/Work/API/RPortal.API/VideoDocument/' + item?.MergeVideoPath} type="video/mp4" />
                                                                        {/* <source src={'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'} type="video/mp4" /> */}
                                                                    </video>

                                                                    <div className="controls">
                                                                        <div className="play-button" onClick={handlePlayClick}>
                                                                            {isPlaying ? <img src={pauseButton} className='pausebutton-icon' /> : <img src={playButton} className='playbutton-icon' />}
                                                                        </div>


                                                                        <div className='text-white'>{formatTime(currentTime)}</div>
                                                                        <div className="timeline" onClick={handleTimelineClick}>
                                                                            <div
                                                                                className="timeline-progress"
                                                                                style={{ width: `${(currentTime / (videoRef.current ? videoRef.current.duration : 0)) * 100}%` }}
                                                                            />
                                                                        </div>

                                                                        <div className='text-white'>{formatTime(videoRef.current && !isNaN(videoRef.current.duration) ? videoRef.current.duration : 0)}</div>

                                                                        <div className="volume-control">
                                                                            <input
                                                                                type="range"
                                                                                min={0}
                                                                                max={100}
                                                                                value={volume}
                                                                                onChange={handleVolumeChange}
                                                                                className="volume-slider"
                                                                                style={{
                                                                                    background: `-webkit-linear-gradient(left, #ffa500 0%, #ffa500 ${volume}%, #fff ${volume}%, #fff 100%)`
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="fullscreen-button"><Link to={`/schedule-interview/interview-profile-view/${profileId}`} onClick={() => handleShowImg(profileId, item?.MergeVideoPath)}><img src={Expand} /> </Link></div>
                                                                    </div>
                                                                </div>
                                                        }
                                                        {
                                                            item?.MergeVideoPath === null || item?.MergeVideoPath === "" ? "" :
                                                                <div className='d-flex justify-content-end gap-4 mt-2'>
                                                                    <button className='mt-1 border border-0 px-2 py-1 fs-6 ' style={{backgroundColor:"#F7A340",color:"#fff"}} onClick={() => {
                                                                      handleDownload(`https://gateway.knila.com/projectr_api/${item?.MergeVideoPath}`, item?.MergeVideoPath)
                                                                      //handleDownload(`http://localhost:8170/interview/VideoDocument/${item?.MergeVideoPath}`, item?.MergeVideoPath)
                                                                       //handleDownload(`https://dev.knila.com:8016/StaticFiles/${item?.MergeVideoPath}`, item?.MergeVideoPath)
                                                                        // const link = document.createElement("a");
                                                                        // link.href = `http://72.249.77.205:8082/StaticFiles/${item?.MergeVideoPath}`;
                                                                        // link.download = item?.MergeVideoPath;
                                                                        // link.click(); 
                                                                    }}>{isLoadingDownload ? <Spinner animation="border" size="sm" role="status" >
                                                                        < span className="visually-hidden text-center">Loading...</span>
                                                                    </Spinner > : ""} Download</button>
                                                                    {
                                                                        item?.MergeVideoPath === null || item?.MergeVideoPath === "" ? "" :
                                                                            <button className='mt-1 border border-0 px-2 py-1 fs-7 btn-danger ' onClick={() => { handleVideoDelete(item?.InterviewLinkid, item?.MergeVideoPath) }}>Delete</button>}
                                                                </div>
                                                        }
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>
                                    }

                                </div>

                        }


                    </div> : <h6 className='text-center'>No Interview Videos available</h6>

            }

            {videoData.length > 0 &&
                videoData[0]?.MergeVideo !== null && videoData[1]?.MergeVideo !== null && videoData[3]?.MergeVideo !== null && (
                    <div className="pagination">
                        <button
                            className="pagination-button"
                            onClick={() => {
                                handleLoader();
                                handlePaginationClick(currentPage - 1);

                            }}
                            disabled={currentPage === 1}
                        >
                            {"<"}
                        </button>
                        {/* Render page numbers */}
                        {Array.from({ length: Math.ceil(videoData.length / notesPerPage) }).map((_, index) => (
                            <button
                                key={index}
                                className={`pagination-button ${currentPage === index + 1 ? "active-notes" : ""}`}
                                onClick={() => {
                                    handleLoader();
                                    handlePaginationClick(index + 1);
                                }}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            className="pagination-button"
                            onClick={() => {
                                handleLoader();
                                handlePaginationClick(currentPage + 1);
                            }}
                            disabled={currentPage === Math.ceil(videoData.length / notesPerPage)}
                        >
                            {">"}
                        </button>
                    </div>
                )}
            <VideoPreview videoData={videoData} loading={loading} showImg={showImg} onCloseImg={handleCloseImg} VideoPreviewFile={VideoPreviewFile} />
        </div >
    );
};




export default InterviewVideo;
