import React, { useState } from 'react'
import { Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/img/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import googleicon from '../../assets/img/grommet-icons_google.png'
import twitticon from '../../assets/img/logos_twitter.png'
import fbicon from '../../assets/img/facebook.png'
import { FaEyeSlash, FaEye, FaUserAlt, FaEnvelope, FaPhoneAlt, FaUserTie, FaInfoCircle } from "react-icons/fa";
import OtpInput from "react-otp-input";
import OtpTimer from "otp-timer";
import CountDown from './CountDown'
import { postVerify } from '../Services/api';
import { postRequest, postFormData } from '../Services/api';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const init = {
        email: ""
    }
    const [formData,setData] = useState(init);
    const [formErrors, setFormErrors] = useState({});
    const handleChangeEmail = (event, values) => {
        const { name, value } = event.target
        setData(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        });
    }

    const handleForgotPasswordSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formData));
        if (Object.keys(validate(formData)).length === 0) {
            postRequest('api/Authentication/ForgotPassword',formData).then((res) => {
             if(res.data.status != "200"){
               toast.error(res.data.message);
               return;
             }
             else{
                toast.success(' Recovery password sent to the email');
        setData({
            email: ""
        })

               return;
             }
             
        //    console.log()

               });
       
           }
    }
    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      

        if (!values.email) {
            errors.email = "Email is required";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid email";
        }

        

        return errors;
    };
    return (
        <div>
            <div>
                <div className='bg-signup'>

                    <div className=''>
                        <Container fluid className='p-lg-5'>
                            <Row >
                                <Col lg={3}></Col>
                                <Col lg={6} className='bg-white rounded-box2 p-lg-2 pt-1 pb-1 px-4 py-2'>
                                    <div className='d-flex  justify-content-between'>
                                        <div >

                                        </div>
                                        {/* <div className='already-user-login'>
                                            <p>Already user?<Link to='/login' className='link-text-decoration'><span>Login</span></Link></p>
                                        </div> */}
                                    </div>
                                    <div className='text-center mt-5 signin-heading'>
                                        <img src={Logo} className='img-fluid' />
                                        <h4>Forgot Password</h4>

                                    </div>
                                    {/* mobile verification code */}
                                    <Row>

                                        <Col lg={12} className="mt-2">
                                            <form onSubmit={handleForgotPasswordSubmit}>

                                                <div className='align-signup-form'>

                                                    <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                        <input className="did-floating-input" autocomplete="new-password" onChange={handleChangeEmail} name="email" value={formData.email} maxLength="75" type="text" placeholder=" " />
                                                        <div className='icon-background' > <FaEnvelope className='user-input-icon' /></div>
                                                        <span className='iconchange'></span>
                                                        <label className="did-floating-label">Email *</label>
                                                        <p className="mb-1 form-error">{formErrors.email}</p>
                                                    </div>
                                                    <div className="did-floating-label-content  col-lg-6 col-sm-12" >
                                                        <button className='btn-forgotpassword' >Get recovery password in email</button>
                                                    </div>

                                                </div>

                                            </form>
                                           <p className="text-center backtosingin mt-2"> <Link to="/login" style={{textDecoration:"none",color:"#E0701A"}}>Back to Login </Link></p>

                                        </Col>
                                        {/* <Col lg={3}>
                                        </Col> */}
                                    </Row>


                                </Col>
                                <Col lg={3}></Col>
                                {/* <Col lg={1}></Col> */}
                            </Row>
                        </Container>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ForgotPassword