import React, { useState } from 'react'
import imgViewIcon from '../../../assets/img/imgView.svg'
import ImageViewer from '../../ModalPopup/ImageViewer'
import { Link } from 'react-router-dom'
import { postRequest, postFormData } from '../../Services/api';
import ProofIdImgView from '../../ModalPopup/ProofIdImgView';

const InterviewDocuments = ({ profileId, interviewLevelProof }) => {
    const [showImg, setShowImg] = useState(false);
    const [imgView, setImageView] = useState()
    const [imgProof, setImageProof] = useState()
    const [loading, setLoading] = useState(true)
    const handleShowImg = async (profileId) => {
        setShowImg(true);
        setImageView("")
        await postRequest('api/Profile/GetDocumentView?ProfileId=' + profileId).then((res) => {
            setImageView('data:application/pdf;base64,' + JSON.parse(res?.data?.Data))
            setLoading(false);
        });

    }
    const handleCloseImg = () => setShowImg(false);

    const [show, setShow] = useState(false);
    const [imgViewProof, setImageViewProof] = useState()
    const handleShow = async (profileId) => {
        if (interviewLevelProof != ""){
            setShow(true);
            await postRequest('api/Interview/GetInterviewDocuments?ProfileId=' + profileId).then((res) => {
                let image = JSON.parse(res?.data?.Data)[0]
                setImageProof(image?.Picture)
                setLoading(false);
            });
        }
        else{
            setShow(false);
        }
       


    }

    const handleClose = () => setShow(false);
    return (
        <div>
            {/* <div className='title-interview-docs'>
                <h4>Interview Documents</h4>
            </div> */}
            <div>
                <div className='align-interview-documents mt-4'>
                    <div className='col-5 title-interview-docs-resume'>
                        <h4>Candidate Resume </h4>
                    </div>
                    <div className='col-3'>
                        <div className='border-img-interviewdocs'>
                            <Link to={`/schedule-interview/interview-profile-view/${profileId}`} className='search-name-text-color ' onClick={() => handleShowImg(profileId)}><img src={imgViewIcon} className='viewIcon-file' /></Link></div>
                    </div>
                </div>
                <div className='align-interview-documents'>
                    <div className='col-5 title-interview-docs-resume'>
                        <h4>Candidate ID proof</h4>
                    </div>
                    <div className='col-3'>

                        <div className='border-img-interviewdocs'>
                            <Link to={`/schedule-interview/interview-profile-view/${profileId}`} className='search-name-text-color ' onClick={() => handleShow(profileId)}>
                                <img src={imgViewIcon} className='viewIcon-file' />
                            </Link>
                        </div>


                    </div>
                </div>
            </div>
            <ProofIdImgView loading={loading} show={show} onClose={handleClose} imgProof={imgProof}/>
            <ImageViewer imgView={imgView} loading={loading} showImg={showImg} onCloseImg={handleCloseImg} />

        </div>
    )
}

export default InterviewDocuments