import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png'
const PrivacyPolicy = () => {
    return (
        <div>

<header class="appie-header-area appie-header-page-area appie-sticky shadow-lg">
                <div class="container pb-4 pt-4">
                    <div class="header-nav-box header-nav-box-3 header-nav-box-inner-page">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                                <div class="appie-logo-box">
                                <Link to="/">
                                    <a href="#">
                                        <img src={Logo} alt="" />
                                    </a>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
            <div className='container mt-5 mb-3 '>
                <div class="col-lg-12">
                    <div>
                        <h3 class="title">Privacy & Data Use Policy</h3>

                    </div>
                </div>


            </div>
            <div className='container shadow'>
                <div className='col-lg-12 ' style={{ padding: "30px", textAlign: "justify", boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px;" }}>
                    <div class="row pt-3 terms-para-tag-css">
                        <p className='terms_condition_css_page'>THE TERMS OF THIS PRIVACY & DATA USE POLICY ("POLICY") ARE LEGALLY
                            BINDING. IF YOU USE OUR SERVICE
                            (AS DEFINED BELOW), YOU AGREE TO BEBOUND TO ALL OF THE TERMS AND CONDITIONS OF THIS POLICY.</p>
                        <p className='lastupdateOn-css'>Last Updated 29/07/2022</p>
                        <p>Project R respects the privacy of its users and has developed this Policy to be transparent about
                            how we may collect, use,
                            and share information and demonstrate our commitment in protecting your privacy.</p>
                        <p>This Policy applies to you, as an individual, and also as the user of our genuine platform. While
                            using any of the
                            Project R services, our web application (www.knila.com), our mobile applications, our premium
                            services,
                            or any other of our web-based or mobile services or offerings (collectively, our "Services").
                        </p>
                        <p>We encourage you to read this Policy carefully when using our Services as our beloved users.
                            If you have any questions about our privacy practices, please refer to the end of this Policy
                            for information on how to contact us.
                        </p>
                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>Overview</strong></h4>
                        <p>The kind of data we collect about you can be different based on the user of Project R in our
                            mobile applications.
                            When you create a user login, data like your name, age, mobile number, email id might be
                            collected for
                            the official purpose.</p>
                        <p>Project R Services are available only to our registered users. However, the user can add and
                            share
                            their recruited profiles as a key part of this application to let the other employers know.</p>
                        <p>Ultimately, the information we collect and process from you and about you and about the profiles
                            you
                            add are used in various ways to operate our Services. From sending a notification and serving
                            more
                            relevant advertising in order to allow us to continue offering our core Services free of charge,
                            to
                            all our worldwide users. Each use serves a different and important purpose allowing us to
                            operate the
                            Services and our business. Our ultimate goal is to keep your stress down with our trustworthy
                            recruiting app.</p>
                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>Information we receive about you </strong></h4>
                        <p>In General, we receive several different types of information from you as our reputed user, when
                            you use our Services
                            or about you via someone else (like sub users) using our Services (like when you share or search
                            a profile).
                            Some of which is personally identifying and some are not. The information received through our
                            Services may
                            be sent to servers located in the United States and other countries around the world.</p>

                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>Information you provide</strong></h4>
                        <p>We collect and store personal information and other identifiers when you log in to our Services
                            or that is provided
                            to us in some other manner. This includes identifying information, such as your full name,
                            mailing address, location,
                            email address, images, telephone number and other related information. During and after the
                            registration process,
                            we may also request non-personal information about your interests and activities, your gender,
                            your age, your location
                            (such as ZIP code and/or country), other demographic or profile information, or we may ask you
                            to complete a survey or send us a review.</p>

                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>Information from other sources </strong></h4>
                        <p>We also obtain both personal and non-personal information about you from other users,
                            and other third parties (the user that you gave access to), and may add it to our account
                            information or other information we have collected about you. The most frequent piece of
                            information
                            that Project R receives under this section is user email addresses or cell phone numbers that
                            are provided by
                            the main user when you are the sub users.</p>
                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>Information collected automatically</strong></h4>
                        <p>We automatically receive information from the browser, computer, and device used to access our
                            Services.
                            This information most often includes (without limitation) your IP address, your geographic
                            location, your
                            browser type and language, access times, the content of any saved cookies that your browser
                            previously accepted
                            from us and the referring website address.</p>
                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>Mobile Devices</strong></h4>
                        <p>We may receive information from your mobile devices when you visit our Services, such as
                            identifiers
                            for advertising ("IDFA") and geographic or the geo-location information.</p>
                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>How we protect your personal information</strong></h4>
                        <p>We take necessary security measures (including physical, electronic and procedural measures)
                            intended to help safeguard your personal information from unauthorized access and disclosure.
                            For example, only authorized users and service providers are permitted to access personal
                            information,
                            and they may do so only for permitted business functions. In addition, we use firewalls to help
                            prevent
                            unauthorized persons from gaining access to your personal information.</p>
                        <p>We want you to feel confident and trust us when using our Services. However, no system can be
                            completely secure. Therefore, although we take steps to secure your information, we do not
                            promise,
                            and you should not expect, that your personal information, or searches, or other information
                            will
                            always remain secure. Please be advised that whenever you voluntarily disclose personal
                            information
                            on publicly-viewable web pages, that information will be publicly available and can be collected
                            and
                            used by others. For example, if you post your email address, you may receive unsolicited
                            messages. </p>
                        <p>We cannot control who reads your posting or what other users may do with the information you
                            voluntarily
                            post, so we encourage you to exercise discretion and caution with respect to your personal
                            information.
                            Once you have posted information, you may not be able to edit or delete such information.</p>
                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>Children's Privacy</strong></h4>
                        <p>Our Services assures that the services provided by Project R are for the general audience
                            (adults)
                            as it is used for official purpose. Project R is an user friendly application and does not
                            support
                            or encourage Child labor, considering the Child Labor law. Hence, Children below the age of 18
                            serve no purpose in this app. We Prioritize children’s safety and ensure that their privacy is
                            sealed.</p>
                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>No Rights of Third Parties</strong></h4>
                        <p>This Policy does not create rights enforceable by third parties or require disclosure of any
                            personal information relating to users of the Services.</p>
                    </div>
                    <div class="row terms-para-tag-css">
                        <h4><strong>Changes to this Policy</strong></h4>
                        <p>Project R reserves the right to amend this Policy at its sole discretion and any modifications
                            shall be
                            effective immediately upon posting. When we revise this Policy, we will notify you by posting
                            the new
                            Policy on the Services with the "Last Updated" legend at the top of this page. By continuing to
                            use
                            the Services following such changes, you will be deemed to have agreed to such changes. If you
                            do
                            not agree with the terms and conditions of this Policy or any changes thereto, you may not
                            continue
                            using the Services.</p>
                    </div>
                </div>
            </div>
            <div className='container-fluid footer-area-terms mt-4'>
                <div className='row'>
                    <div className='col-lg-12 text-center'>
                        <div class="copyright-text terms-para-tag-css">
                            <p>Copyright © 2022<Link to="/" style={{textDecoration:"none",color: "#e0701a"}}> projectR</Link>. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy