import React, { useEffect } from 'react'
import thankyouicon from '../../../assets/img/thankyouicon.png'
import FooterInterView from './FooterInterView'
import Spinner from 'react-bootstrap/Spinner';
import { postRequest, postFormData } from '../../Services/api'
import { useState } from 'react'
const ThankYouPage = ({ countdownFinished, profileId }) => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        let timeout;

        if (countdownFinished) {
            timeout = setTimeout(() => {
                setLoading(false);
            }, 15000);
        }

        return () => clearTimeout(timeout); // Clear the timeout if the component unmounts or the countdownFinished value changes before the timeout expires
    }, [countdownFinished])

    // const handleMergeVideo = async () => {
    //     // setLoading(true)
    //     let message = "success"
    //     await postRequest(`api/ScheduleInterview/MergeVideo?profileId=${profileId}&IsMessage=${message}`).then((res) => {
    //         console.log(res)
    //         if (res?.data?.Status) {
    //             setLoading(false)
    //         }

    //     })
    // }
    // useEffect(() => {
    //     let timeout;

    //     if (countdownFinished) {
    //       timeout = setTimeout(() => {
    //         handleMergeVideo();
    //       }, 5000);
    //     }

    //     return () => clearTimeout(timeout); // Clear the timeout if the component unmounts or the countdownFinished value changes before the timeout expires
    //   }, [countdownFinished, handleMergeVideo]);

    return (
        <div>
            <div className='thankyou-h-100'>
                <div className='thank__page__ui'>
                    <div className='box-of-thankyoupage'>
                        {
                            loading ? <Spinner animation="border" role="status">
                                < span className="visually-hidden text-center">Loading...</span>
                            </Spinner > : <div className='box-of-thankyoupage'>
                                <img src={thankyouicon} className='img-icon-thankyou' />
                                <h1 >Thank You!</h1>
                                <p > Your virtual interview has been submitted successfully </p>
                            </div>
                        }

                    </div>

                </div>
            </div>
            <FooterInterView />
        </div>

    )
}

export default ThankYouPage