import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './interviewpage.css'
import QuestionTimer from "./QuestionTimer";
import VideoComponent from './VideoComponent'
import VideoNo from '../../../assets/img/videono.png'
import VideoOff from '../../../assets/img/videooff.png'
import AudioOff from '../../../assets/img/audiooff.png'
import ImageCapture from './ImageCapture'
import FooterInterView from './FooterInterView'
import { postRequest, postFormData } from '../../Services/api'
import {
    RecordWebcam,
    useRecordWebcam,
    CAMERA_STATUS
} from 'react-record-webcam';
import ThankYouPage from './ThankYouPage';
const InstructionAndVideo = (
    {
        startButton,
        setStartButton,
        setNextButtonShow,
        nextButtonShow,
        setQuestions,
        questions,
        setCurrentQuestionIndex,
        currentQuestionIndex,
        recordWebcam,
        timeLeft,
        setTimeLeft,
        totalTime,
        setTotalTime,
        duration,
        setDuration,
        setCountdownFinished,
        countdownFinished,
        setVideoRecordHandle,
        videoRecordHandle,
        setVideoBlob,
        videoBlob,
        setNextButtonVideo,
        NextButtonVideo,
        setInstruction,
        instruction,
        handleCheckBox,
        isChecked,
        profileId,
        startInterview,
        CompanyId,
        setImageSrc,
        setIsTrueStatus,
        videoMergeApi,
        interviewLinkId
    }
) => {

    const [imgaeCaptureApi, setImageCaptureApi] = useState(false)
    const [checkBoxValidate, setCheckBoxValidate] = useState(false)

    // const [nextButtonShow, setNextButtonShow] = useState(false)





  
  const handleNextQuestion = async () => {

        var stop = await recordWebcam?.stop();
        if (recordWebcam.status == "CLOSED") {
            setVideoRecordHandle(false)
        }
       /// console.log(currentQuestionIndex);
        if (recordWebcam) {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            const blob = await recordWebcam.getRecording();
         //   console.log(blob)

            setTimeLeft(duration);
            setVideoBlob(blob);
            setNextButtonVideo(false)
            if (blob?.size.toString().length > 0) {
                const data = new FormData()
                data.append('VideoDocument', blob)
                postFormData(`api/ScheduleInterview/VideoDocument?ProfileId=${10986}`, data).then((res) => {
                //    console.log(res)

                })
            }

        }
        var open = await recordWebcam?.open()
        if (recordWebcam.status == "OPEN") {
            setVideoRecordHandle(true)
        }
        setNextButtonVideo(true)        
    };  
    useEffect(() => {
        const interval = setInterval(() => {
            if (currentQuestionIndex === questions.length - 1) {
                clearInterval(interval);
                setCountdownFinished(true);
                postRequest(`api/ScheduleInterview/MergeStatus?IsMessage=${true}&ProfileId=${profileId}&InterviewLinkId=${interviewLinkId}`).then((res) => {
        
                })
            } else if (instruction) {
                setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                setTimeLeft(duration);

            }
        }, duration * 1075);
        return () => clearInterval(interval);
    }, [currentQuestionIndex, duration, questions]);
   
     const saveFile = async () => {
        var stop = await recordWebcam?.stop();
        if (recordWebcam.status === "CLOSED") {
            setVideoRecordHandle(false);
        }

        if (recordWebcam) {
            const blob = await recordWebcam.getRecording();
           // console.log(blob);
            setVideoBlob(blob); 
            setNextButtonVideo(false);

            if (blob?.size.toString().length > 0) {
                // Check the browser and set the desired format accordingly
                const isFirefox = typeof InstallTrigger !== 'undefined'; // Detect Firefox
                const desiredFormat = isFirefox ? 'webm' : 'mp4';

                // Generate filename with current time
                const currentDate = new Date();
                const timestamp = currentDate.getTime();

                // Format Indian Date and Time
                const options = {
                    timeZone: 'Asia/Kolkata',
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                };
                const indianDateTime = currentDate.toLocaleString('en-US', options);


                // Format the filename as yyyymmdd_hhmmss
                const formattedDate = indianDateTime
                    .replace(/[^\d]/g, '') // Remove all non-digit characters
                    .replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1$2$3_$4$5$6'); // Rearrange the digits

                const fileName = `recorded_video_${formattedDate}.${desiredFormat}`;

              //  console.log('Filename with Indian Date and Time:', fileName);


                const videoBlob = new Blob([blob], { type: `video/${desiredFormat}` });

                const data = new FormData();
                data.append('VideoDocument', videoBlob, fileName);

                // Update the API endpoint to accept the video in the desired format
                postFormData(`api/ScheduleInterview/VideoDocument?ProfileId=${profileId}`, data).then((res) => {
               //     console.log(res);
                });
            }
        }

        var open = await recordWebcam?.open();
        if (recordWebcam.status === "OPEN") {
            setVideoRecordHandle(true);
        }
    };
	
	const handleVideoApi = () => {

    }

    useEffect(() => {
        if (recordWebcam.status == "OPEN") {
            recordWebcam?.start();
        }
        if (timeLeft === 0) {
            saveFile()
        }
    }, [timeLeft]);

    // useEffect(() => {
    //     recordWebcam?.start();
    // }, [currentQuestionIndex])
    // console.log(timeLeft)
    // console.log(imgaeCaptureApi)
    // const startInterview = async () => {
    //     var res = await postRequest(`api/Interview/GetRandomQuestion?CompanyId=10029&CategoryId=238`);
    //     var result = JSON.parse(res?.data?.Data);
    //     setInstruction(true)
    //     setNextButtonShow(true)
    //     setQuestions(result)
    //     // recordWebcam?.start();
    //     // console.log(result)
    // }
    // console.log(questions)

    const listInstruction = [
        " Use tested equipment and an uninterrupted Internet connection.",
        "  Camera and microphone are required. Do not turn it off or mute.  ",
        " Interview location is mandatory. Do not turn off the location until the interview is completed.  ",
        " The interview session may expire by switching browsers / no internet connection / if the device is engaged with any other activities. ",
        " The total duration of the session will be 3 minutes with five technical questions and one introductory session. ",
        " To activate video capture, click on the “Turn camera on” button. ",
        " The “Start” button on the top right will begin the interview questions. ",
        "The “Next” button on the top right will further the next question. ",
        " For any interruption, kindly contact the company HR. ",


    ]
    return (
        <div>

            <div>
                <Container fluid className='padding-mob-desktop-interview-header'>
                    <Row className='row-eq-height '>
                        <Col lg={9} md={12} className="">
                            <div className='d-flex  flex-column  justify-content-between'>
                                <div className='border-for-instruction'>
                                    <div className='instruction-box-design'>
                                        {
                                            nextButtonShow ? <h1 className='header-general-instruction'>
                                                Question {currentQuestionIndex + 1} of {questions.length}
                                            </h1> : <h1 className='header-general-instruction'>General Instruction</h1>
                                        }


                                        {
                                            instruction ? <QuestionTimer
                                                questions={questions}
                                                duration={duration}
                                                onNextQuestion={handleNextQuestion}
                                                currentQuestionIndex={currentQuestionIndex}
                                                setCurrentQuestionIndex={setCurrentQuestionIndex}
                                                timeLeft={timeLeft}
                                                setTimeLeft={setTimeLeft}
                                                setTotalTime={setTotalTime}
                                                totalTime={totalTime}

                                            />
                                                :
                                                <div className='header-general-instruction-allthebest'>
                                                    <ul>
                                                        {listInstruction.map(item => <li className='list-of-instruction'>{item}</li>)}
                                                    </ul>
                                                    <h4 className='text-center'>ALL THE VERY BEST!</h4>
                                                </div>

                                        }


                                    </div>

                                </div>
                                {imgaeCaptureApi ? "" :
                                    <div className='mt-3 mb-3' checkBoxValidate style={{ color: checkBoxValidate ? 'red' : '', }}>
                                        <input type='checkbox' onChange={handleCheckBox} /> I have read and accept the general instructions




                                    </div>
                                }
                                <div></div>

                            </div>

                        </Col>
                        {/* <VideoComponent /> */}
                        {
                            imgaeCaptureApi ?
                                <VideoComponent
                                    setStartButton={setStartButton}
                                    nextButtonShow={nextButtonShow}
                                    questions={questions}
                                    duration={duration}
                                    onNextQuestion={handleNextQuestion}
                                    currentQuestionIndex={currentQuestionIndex}
                                    setCurrentQuestionIndex={setCurrentQuestionIndex}
                                    timeLeft={timeLeft}
                                    setTimeLeft={setTimeLeft}
                                    totalTime={totalTime}
                                    currentTime={totalTime - timeLeft}
                                    instruction={instruction}
                                    countdownFinished={countdownFinished}
                                    recordWebcam={recordWebcam}
                                    videoBlobFile={videoBlob}
                                    profileId={profileId}
                                /> :
                                <ImageCapture
                                    setImageCaptureApi={setImageCaptureApi}
                                    imgaeCaptureApi={imgaeCaptureApi}
                                    isChecked={isChecked}
                                    nextButtonShow={nextButtonShow}
                                    setCheckBoxValidate={setCheckBoxValidate}
                                    profileId={profileId}
                                    setImageSrc={setImageSrc}
                                />
                        }
                    </Row>
                </Container>


                <Container fluid>
                    <Row>
                        {/* <Col>
                        {
                            startButton && <div className='d-flex justify-content-center'>
                                {
                                    nextButtonShow ? <div> {
                                        questions.length > 0 && currentQuestionIndex < questions.length - 1 && (
                                            <button className='btn-start-interview' onClick={handleNextQuestion}>Next</button>
                                        )
                                    } </div> : <button className='btn-start-interview' disabled={startButton == true ? false : true} onClick={startInterview}>Start</button>
                                }

                             

                            </div>
                        }

                    </Col> */}
                    </Row>
                </Container>
                <footer className='position-relative-footer'>
                    <FooterInterView />
                </footer>
            </div>



        </div>
    )
}

export default InstructionAndVideo