import React from 'react'
import FooterInterView from './FooterInterView'
import browser from '../../../assets/img/LinkExp.png'
const LinkExpiryPage = () => {
  return (
    <div>
          <div className='thankyou-h-100'>
              <div className='browser__page__ui'>
                  <div className='box-of-browserchange'>
                      <img src={browser} className='img-icon-thankyou' />
                      <h1 className='mt-4'>Interview session expired</h1>
                      <p >Please contact organization HR to reschedule an interview.</p>
                  </div>

              </div>
          </div>

          <FooterInterView />
    </div>
  )
}

export default LinkExpiryPage