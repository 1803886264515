import React, {useEffect} from 'react'
import { Outlet,Navigate,useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';

const PrivateRoutes = () => {
    let location = useLocation()
    let tokens =  useSelector((state) => state.userAuth.value);
    useEffect(()=>{
      // setAuthToken(tokens)
      tokens = tokens;
    },[tokens]); 
    let  isLoggedIn = JSON.parse(localStorage.getItem('token'))  
    // console.log(isLoggedIn)
  return (
    isLoggedIn?.token  ? <Outlet /> : <Navigate to='/login' state={{from:location}} replace/>
  )


}

export default PrivateRoutes