import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { postRequest } from '../Services/api';
import Spinner from 'react-bootstrap/Spinner';
import { pdfTest} from './pdfTest'
import { Document, Page,pdfjs  } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SharedData = () => {
    const history = useNavigate()
    const [loading, setLoading] = useState(true)
    const [sharedList, getSharedList] = useState([]);
    useEffect(() => {
        getShared();

    }, []);
    const getShared = async () => {
        var res = await postRequest('api/Profile/GetShareProfile?isDashboard=true');
        getSharedList(JSON.parse(res?.data?.Data));
        setLoading(!loading);
    }

    

    return (
        <div>
            <div >
                
                <Container fluid className=''>
                    <Row>
                        <Col className='bg-white'>
                            <div className='searched-table-heading'>
                                <h3>Recently Shared Profile</h3>
                            </div>

                            <Table striped responsive borderless className='searched-table'>
                                <thead>
                                    <tr>
                                        <th>Shared On</th>
                                        <th>Candidate name</th>
                                        <th>email</th>
                                        <th>mobile number</th>
                                        <th>Job Title</th>
                                        <th>Expected start Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ?
                                        <tr>
                                            <td class="text-center" colSpan={10}> <div class="positioning-spinner">
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden text-center">Loading...</span>
                                                </Spinner>
                                            </div></td>
                                        </tr>
                                        : sharedList.length == 0 ?
                                            <tr>
                                                <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                                            </tr> :
                                            sharedList.map((item) => (
                                                <tr>
                                                    <td>{Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss') + ' ' + item.TimeZone}</td>
                                                    <td> <Link to={`/shareprofile/${item.ProfileId}`} className='search-name-text-color'>{item.CandidateName}</Link></td>
                                                    <td>{item.Email}</td>
                                                    <td>{item.PhoneNumber}</td>
                                                    <td>{item?.JobTitle == "null" || item?.JobTitle == "undefined" ? "" : item.JobTitle}</td>
                                                    <td>{item.JoinDate == null ? null : Moment(item.JoinDate).format('MM/DD/YYYY')}</td>
                                                </tr>

                                            ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default SharedData