import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import InstructionAndVideo from './InstructionAndVideo'
import { Container, Row, Col } from 'react-bootstrap'
import { FaShareSquare } from 'react-icons/fa'
import Logo from '../../../assets/img/logo.png'
import { Offline } from "react-detect-offline";
import { postRequest, postFormData } from '../../Services/api'
import {
  RecordWebcam,
  useRecordWebcam,
  CAMERA_STATUS
} from 'react-record-webcam';
import OfflineStatus from './OfflineStatus';
import BrowserChanged from './BrowserChanged';
import ThankYouPage from './ThankYouPage';
import LinkExpiryPage from './LinkExpiryPage';
const Interview = () => {
  const location = useLocation();
  // console.log()
  const searchParams = location.pathname.split("/")[2];
  const paramValues = searchParams.split("&");
  const [profileId, setProfileId] = useState(paramValues[0])
  let token = paramValues[1].replace('/', '-');
  let category = paramValues[2]
  let subcategory = paramValues[3]
  let CompanyId = paramValues[4]


  const [startButton, setStartButton] = useState(false)
  const [nextButtonShow, setNextButtonShow] = useState(false)
  const [questions, setQuestions] = useState([])
  const [interviewLinkId, setInterviewLinkId] = useState(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [duration, setDuration] = useState(30);
  const [timeLeft, setTimeLeft] = useState(duration);
  const [totalTime, setTotalTime] = useState(0);
  const [countdownFinished, setCountdownFinished] = useState(false);
  const [isTrueStatus, setIsTrueStatus] = useState(false);
  const [videoRecordHandle, setVideoRecordHandle] = useState(true);
  const [videoBlob, setVideoBlob] = useState(null);
  const [NextButtonVideo, setNextButtonVideo] = useState(false)
  const [instruction, setInstruction] = useState(false)
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine);
  const [browserChanged, setBrowserChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState(null);
  const [LinkExpiry, setLinkExpiry] = useState(false)
  const [locationPermission, setLocationPermission] = useState(null);
  const [cameraPermission, setCameraPermission] = useState(null);
  const [microphonePermission, setMicrophonePermission] = useState(null);

  // console.log(locationPermission)
  // console.log(cameraPermission)
  // console.log(microphonePermission)
  // console.log(nextButtonShow)

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(null);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      }, () => {
        setStatus('Unable to retrieve your location');
      });
    }
  }

  const videoMergeApi = async () => {
   // console.log(isTrueStatus)
    if (isTrueStatus) {
      await postRequest(`api/ScheduleInterview/MergeStatus?IsMessage=${isTrueStatus}&ProfileId=${profileId}&InterviewLinkId=${interviewLinkId}`).then((res) => {

      })
    }

  }
  useEffect(() => {
    if (lng != null || lat != null) {
      handleLinkExpiry()
    }

  }, [lat])
  const handleLinkExpiry = async () => {
    setIsLoading(true)
    await postRequest(`api/ScheduleInterview/LinkValidation?Token=${token}`).then((res) => {

      let message = JSON.parse(res?.data?.Data)[0]
      let interviewLinkId = JSON.parse(res?.data?.Data)[0]
      setInterviewLinkId(interviewLinkId['InterviewLinkId'])

      if (message['Message'] == "Already exists ") {
        setIsLoading(false)
        setLinkExpiry(true)
      }
      else if (message['Message'] === "Success") {
        setIsLoading(false)
        // setIsLoading(false)
      }
      else {
        setIsLoading(false)
      }
    })
  }
  useEffect(() => {
    getLocation()
  }, [])
  useEffect(() => {
    const handleNetworkChange = () => {
      setIsOffline(true);
      setIsTrueStatus(true)
      postRequest(`api/ScheduleInterview/MergeStatus?IsMessage=${true}&ProfileId=${profileId}&InterviewLinkId=${interviewLinkId}`).then((res) => {

      })
    };

    window.addEventListener('offline', handleNetworkChange);
    window.addEventListener('online', handleNetworkChange);

    return () => {
      window.removeEventListener('offline', handleNetworkChange);
      window.removeEventListener('online', handleNetworkChange);
    };
  }, []);
  // console.log(isOffline)
  const getRandomQuestion = async () => {
    const data = new FormData()
    data.append('CompanyId', CompanyId)
    data.append('CategoryId', category)
    data.append('SubCategoryId', subcategory)
    // ?CompanyId=${CompanyId}&CategoryId=${category}&SubCategoryId=${subcategory}
    const res = await postFormData(`api/Interview/GetRandomQuestion`, data);


    return res;
  }
  const handleImageApi = async () => {

    const data = new FormData();
    data.append('ProfileId', profileId);
    data.append('Picture', imageSrc);
    data.append('CompanyId', CompanyId);
    // setIsLoadingAPi(true);

    await postFormData('api/Interview/InsertInterviewDocuments', data).then((res) => {
      var result = JSON.parse(res?.data?.Status);
      // setImageCaptureApi(result);
      if (result) {
        // setIsLoadingAPi(false);
      }
    });


  }
  const tokenTimeExpiration = async () => {
    // await postRequest(`api/ScheduleInterview/LinkValidation?Token=${token}`).then((res) => {

    // })
  }

  const startInterview = async () => {
    // console.log(imageSrc)
    await postRequest(`api/ScheduleInterview/LinkValidation?Token=${token}`).then((res) => {
      setIsLoading(false)
      let message = JSON.parse(res?.data?.Data)[0];

      if (message['Message'] === "Success") {
        let data = {
          "ProfileId": profileId,
          "LinkURL": location.pathname,
          "Latitude": lat.toString(),
          "Longitude": lng.toString(),
          "CompanyId": CompanyId,
          "Category": category, subcategory,
          "Token": token
        }

        postRequest(`api/ScheduleInterview/UpdateInterviewLink`, data).then((res) => {
          getRandomQuestion().then((res) => {
            const result = JSON.parse(res?.data?.Data);
            if (res?.data?.Status) {
              setInstruction(true)
              setNextButtonShow(true)
              setQuestions([...result, { Questions: 'Tell me about yourself' }])
              handleImageApi()
            }
          })
        })
      } else {
        setLinkExpiry(true)
      }
    })

    // recordWebcam?.start();
    // console.log(result)
  }
 const handleNextQuestion = async () => {
    setTimeout(async () => {

      var stop = await recordWebcam?.stop();

      if (recordWebcam.status == "CLOSED") {
        setVideoRecordHandle(false)
      }

      if (recordWebcam) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        const blob = await recordWebcam.getRecording();
       // console.log(blob)


        setTimeLeft(duration);
        setVideoBlob(blob);
        setNextButtonVideo(false)
        if (blob?.size.toString().length > 0) {
          // Check the browser and set the desired format accordingly
          const isFirefox = typeof InstallTrigger !== 'undefined'; // Detect Firefox
          const desiredFormat = isFirefox ? 'webm' : 'mp4';



          // Generate filename with current time
          const currentDate = new Date();
          const timestamp = currentDate.getTime();

          // Format Indian Date and Time
          const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          };
          const indianDateTime = currentDate.toLocaleString('en-US', options);


          // Format the filename as yyyymmdd_hhmmss
          const formattedDate = indianDateTime
            .replace(/[^\d]/g, '') // Remove all non-digit characters
            .replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1$2$3_$4$5$6'); // Rearrange the digits

          const fileName = `recorded_video_${formattedDate}.${desiredFormat}`;

         // console.log('Filename with Indian Date and Time:', fileName);



          const videoBlob = new Blob([blob], { type: `video/${desiredFormat}` });

          const data = new FormData();
          data.append('VideoDocument', videoBlob, fileName);

          // Update the API endpoint to accept the video in the desired format
          postFormData(`api/ScheduleInterview/VideoDocument?ProfileId=${profileId}`, data).then((res) => {
           // console.log(res);
          });
        }

      }
      var open = await recordWebcam?.open()
      if (recordWebcam.status == "OPEN") {
        setVideoRecordHandle(true)
      }
      setNextButtonVideo(true)

    }, 2000);
  };
   
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckBox = () => {
    setIsChecked(!isChecked);
  }

  const OPTIONS = { frameRate: 60, audio: true, mimeType: 'video/mp4' }
  const recordWebcam = useRecordWebcam(OPTIONS);
  useEffect(() => {
    const handleBackButton = (e) => {
      e.preventDefault();
      e.stopPropagation();
      window.history.forward();
    };

    window.addEventListener('popstate', handleBackButton);
    window.history.pushState(null, null, window.location.pathname);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "visible") {
  //       console.log("checking");
  //       alert("checking-blue")
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);
  useEffect(() => {
    // console.log(nextButtonShow)
    const handleWindowBlur = () => {
      if (nextButtonShow) {
        setBrowserChanged(true)
        setIsTrueStatus(true)
        postRequest(`api/ScheduleInterview/MergeStatus?IsMessage=${true}&ProfileId=${profileId}&InterviewLinkId=${interviewLinkId}`).then((res) => {

        })
      }

      // console.log("checking");
      // alert("checking")
    };

    window.addEventListener("blur", handleWindowBlur);

    return () => {
      window.removeEventListener("blur", handleWindowBlur);
    };
  }, [nextButtonShow]);
  const handlePermissionAll = () => {
    if (navigator.permissions && navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((permission) => {
        setLocationPermission(permission.state);
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationPermission('granted');
        },
        (error) => {
          setLocationPermission('denied');
        }
      );
    }

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then(() => {
          setCameraPermission('granted');
          setMicrophonePermission('granted');
        })
        .catch(() => {
          setCameraPermission('denied');
          setMicrophonePermission('denied');
        });
    }
  };


  const handlePermission = () => {
    if (navigator.permissions && navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((permission) => {
        setLocationPermission(permission.state);
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationPermission('granted');
        },
        (error) => {
          setLocationPermission('denied');
        }
      );
    }

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(() => {
        setCameraPermission('granted');
      }).catch(() => {
        setCameraPermission('denied');
      });

      navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
        setMicrophonePermission('granted');
      }).catch(() => {
        setMicrophonePermission('denied');
      });
    }
  };
  return (
    <div className='img-banner-interview'>
      <div className='d-flex overflow-hidden justify-content-between padding-mob-desktop-interview-header'>
        <div>
          <img src={Logo} onClick={handlePermissionAll} />
        </div>
        {
          isOffline || browserChanged ? "" :
            <div>
              {
                startButton && <div className='d-flex justify-content-center'>
                  {
                    nextButtonShow ? <div> {
                      questions.length > 0 && currentQuestionIndex < questions.length - 1 && (
                        <button className='btn-register' onClick={handleNextQuestion}>Next</button>
                      )
                    } </div> : <button className='btn-register' disabled={startButton == true ? false : true} onClick={startInterview}>Start</button>
                  }

                  {/* disabled={startButton == true ? false : true} */}

                </div>
              }
              {/* <button className='btn-register'>Exit &nbsp;&nbsp; <FaShareSquare style={{ marginTop: "-4px" }} /></button> */}
            </div>
        }

      </div>
      <hr></hr>

      {
        isLoading ?
          <div className='lazy-loading-interview'>
            <div className='spinner-interview'>

            </div>
          </div> :

          <Container fluid>
            <Row>
              {
                LinkExpiry ?
                  <Col >


                    <LinkExpiryPage />
                  </Col> :

                  <div>
                    {
                      !countdownFinished ?
                        <Col>
                          {
                            isOffline ?

                              <OfflineStatus isOffline={isOffline} />
                              :
                              browserChanged ?
                                <BrowserChanged browserChanged={browserChanged} /> :
                                <InstructionAndVideo
                                  setStartButton={setStartButton}
                                  startButton={startButton}
                                  setNextButtonShow={setNextButtonShow}
                                  nextButtonShow={nextButtonShow}
                                  setQuestions={setQuestions}
                                  questions={questions}
                                  setCurrentQuestionIndex={setCurrentQuestionIndex}
                                  currentQuestionIndex={currentQuestionIndex}
                                  recordWebcam={recordWebcam}
                                  timeLeft={timeLeft}
                                  setTimeLeft={setTimeLeft}
                                  setTotalTime={setTotalTime}
                                  totalTime={totalTime}
                                  duration={duration}
                                  countdownFinished={countdownFinished}
                                  setCountdownFinished={setCountdownFinished}
                                  setVideoRecordHandle={setVideoRecordHandle}
                                  videoRecordHandle={videoRecordHandle}
                                  setVideoBlob={setVideoBlob}
                                  videoBlob={videoBlob}
                                  setNextButtonVideo={setNextButtonVideo}
                                  NextButtonVideo={NextButtonVideo}
                                  setInstruction={setInstruction}
                                  instruction={instruction}
                                  handleCheckBox={handleCheckBox}
                                  setIsChecked={setIsChecked}
                                  isChecked={isChecked}
                                  profileId={profileId}
                                  CompanyId={CompanyId}
                                  startInterview={startInterview}
                                  setImageSrc={setImageSrc}
                                  setIsTrueStatus={setIsTrueStatus}
                                  videoMergeApi={videoMergeApi}
                                  interviewLinkId={interviewLinkId}
                                />

                          }

                        </Col> :
                        <Col>
                          <ThankYouPage countdownFinished={countdownFinished} profileId={profileId} />
                        </Col>

                    }
                  </div>

              }


            </Row>
          </Container>
      }


      <div>

      </div>


    </div>
  )
}

export default Interview