import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, useMemo } from 'react'
import { Pagination } from 'react-bootstrap'
import { FaAccessibleIcon, FaImages, FaClipboard, FaEnvelope, FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import ModalOfferDetails from '../ModalPopup/ModalOfferDetails';
import imgViewIcon from '../../assets/img/imgView.svg'
import { postRequest, USERID } from '../Services/api';
import Moment from 'moment';
import PaginationComponent from './Pagination';
import Search from './Search';
import _, { truncate } from "lodash"
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import ImageViewer from '../ModalPopup/ImageViewer'
import SelectCategory from './Common/SelectCategory';

const QuestionaryTable = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const [filterDataList,setFilterDataList] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sharedProfileList, getSharedProfileList] = useState([]);
    const [loading, setLoading] = useState(true)
    const [selectItemsPerPage, setselectItemsPerPage] = useState(10);
    const [offerStatus, setOfferStatus] = useState()
    const [showImg, setShowImg] = useState(false);
    const [imgView, setImageView] = useState()
    const handleShowImg = async (profileId) => {
        setShowImg(true);
        setImageView("")
        await postRequest('api/Profile/GetDocumentView?ProfileId=' + profileId).then((res) => {
            setImageView('data:application/pdf;base64,' + JSON.parse(res?.data?.Data))
            setLoading(false);
        });

    }
    const handleCloseImg = () => setShowImg(false);
    const handleChangeItem = (e) => {
        setselectItemsPerPage(e.target.value);
        setCurrentPage(1);
    }
    const handleStatusOnChange = (event, profileId) => {

    }
    useEffect(() => {
        setTotalItems(sharedProfileList.length);

    }, [sharedProfileList, offerStatus])
    console.log(props.tableReload)
    useEffect(() => {
        if (props.tableReload) {
            getQuestions();
            getQuestions();
            props.settableReload(false)
        }
    }, [props.tableReload])
    let filteredData = useMemo(() => {
        let filterData = sharedProfileList?.filter((x) => Object.keys(x).some(y => String(x[y]).toLowerCase().includes(search.toLowerCase())));
        // let filterData = sharedProfileList?.filter((x)=> x?.CategoryName === search)
        setTotalItems(filterData.length);
        return filterData
    }, [search, selectItemsPerPage, currentPage, sharedProfileList]);
    // console.log(search)
    const [offerStatusChange, setOfferStatusChange] = useState()
    useEffect(() => {
        getQuestions();
        setCurrentPage(1);
        setOfferStatusChange()
    }, []);

    useImperativeHandle(ref, () => ({
        getQuestionsChild() {
            getQuestions();
        },
    }));
    let isLoggedIn = JSON.parse(localStorage.getItem('token'))

    const getQuestions = async () => {
        setLoading(truncate);
        var res = await postRequest(`/api/Interview/GetQuestions?CompanyId=${isLoggedIn?.CompanyId}`);
        var result = JSON.parse(res?.data?.Data);
        // result.map((item) => {
        //     item.CreatedDate = Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss')
        // });
        getSharedProfileList(result);
        setLoading(false);
    }
    const [limitedSessions, setLimitedSessions] = useState([]);

    useEffect(() => {

        const lastSessionNumber = currentPage * selectItemsPerPage;
        const firstSessionIndex = lastSessionNumber - selectItemsPerPage;
        setLimitedSessions(filteredData.slice(firstSessionIndex, lastSessionNumber));

    }, [currentPage, selectItemsPerPage, filteredData]);


    useEffect(() => {

        setCurrentPage(currentPage);

        // set currentPage to 1 when the component mounts
    }, [filteredData, sharedProfileList]);





    const [checkboxState, setCheckboxState] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [numChecked, setNumChecked] = useState(0);
    const [deletesingleQuesId, setDeletesingleQuesId] = useState(null);

    const handleCheckboxChange = (id, event) => {
        setDeletesingleQuesId(id)
        setCheckboxState((prevState) => {
            const newState = { ...prevState };
            newState[id] = !newState[id];
            return newState;
        });
        setNumChecked((prevNumChecked) =>
            checkboxState[id] ? prevNumChecked - 1 : prevNumChecked + 1
        );
        setSelectAll(false);

    };


    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setNumChecked(selectAll ? 0 : limitedSessions.length);
        const newCheckboxState = {};
        filteredData.forEach((item) => {
            newCheckboxState[item.QuestionId] = !selectAll;
        });
        setCheckboxState(newCheckboxState);
        if (limitedSessions.length === 1 && !selectAll) {
            setDeletesingleQuesId(sharedProfileList[0].QuestionId);

        } else {
            setDeletesingleQuesId(null);
        }
    };
    const handleResetSelectAll = () => {
        setSelectAll(false);
        setCheckboxState({});
    };
    const [selectedCategory, setSelectedCategory] = useState('');

    const handleDeleteQuestion = async (id) => {

        var res = await postRequest(`/api/Interview/DeleteQuestions?QuestionId=${id}`);
        let error = JSON.parse(res?.data?.Data)[0]
        // console.log(error['ErrorMessage'] != "")
        // console.log(error['Questions'] == "The selected Category Assinged to the candidate. Could not be delete.");
        var result = JSON.parse(res?.data?.Status);
        // let err = error['ErrorMessage'] != "" ? false : true

        // console.log(err)
       // if (error['Questions'] != "Cannot delete category/question. Currently assigned to candidate. ") {
        if (error['Questions'] != "Cannot delete the category assigned to the candidate.") {
            toast.success(res?.data?.SuccessMessage);
            getQuestions()
            handleResetSelectAll()
            setNumChecked(0)
            props.getCategory()
            setSearch("")
            setSelectedCategory('')
            // const updatedSharedProfileList = sharedProfileList.filter((question) => question.QuestionId !== id);
            // // update sharedProfileList state
            // getSharedProfileList(updatedSharedProfileList);
        }
        else {
            toast.error(error?.Questions)
        }
    }
    const handleDeleteAll = async () => {

        const ids = Object.keys(checkboxState)
            .filter((key) => checkboxState[key])
            .join(",");
        const res = await postRequest(
            `api/Interview/DeleteMultipleQuestions?MultipleQuestionId=${ids}`
        );
       // console.log(res)
        let error = JSON.parse(res?.data?.Data)[0]
        // & CompanyId=${isLoggedIn.CompanyId }
        const result = JSON.parse(res?.data?.Status);
        if (error['Error'] == "Category Questions has been deleted") {
            toast.success("Category Questions have been deleted");
            // const updatedProfileList = sharedProfileList.filter((question) => !checkboxState[question.QuestionId]);
            // getSharedProfileList(updatedProfileList);

            await getQuestions();
            props.getCategory()
            setSearch("")
            setSelectedCategory('')
            setCheckboxState({});
            setSelectAll(false);
            setNumChecked(0);

        }
        else if (error['Error'].includes('-')) {

            let err = error['Error'].split('-')
           // console.log(err)
            toast.success(err[0]);
            toast.error(err[1]);

            await getQuestions();
            props.getCategory()
            setSearch("")
            setSelectedCategory('')
            setCheckboxState({});
            setSelectAll(false);
            setNumChecked(0);
        }
        else{
            toast.error(error['Error']);
            await getQuestions();
            props.getCategory()
            setSearch("")
            setSelectedCategory('')
            setCheckboxState({});
            setSelectAll(false);
            setNumChecked(0);
        }

    };

    // console.log(limitedSessions.length)


    return (
        <div>
            <div>

                <div >
                    <Container fluid >
                        <Row>
                            <Col className='bg-white mb-3'>
                                <div className='searched-table-heading'>
                                    <h3>Recently Added Questions</h3>
                                </div>
                                <div className='search-filter-box-custom'>
                                    <div className='searched-table-heading delete-btn-align mt-3 mb-2'>
                                        <SelectCategory
                                            onSearch={value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }}
                                            categoryData={props.categoryData}
                                            selectedCategory={selectedCategory}
                                            setSelectedCategory={setSelectedCategory}
                                        />

                                        {numChecked > 0 ? <button className='btn-delete-que' onClick={() => {
                                            if (numChecked === 1) {
                                                handleDeleteQuestion(deletesingleQuesId);
                                            } else {
                                                handleDeleteAll();
                                            }
                                        }}>Delete</button> : ""}

                                    </div>
                                    <div>
                                        <div className="wrap-select-arrow mt-3  mb-2">
                                            <select className="form-control sorting-select " onChange={handleChangeItem}>
                                                <option value={10} selected="selected">10 Items per page</option>
                                                <option value={25}>25 Items per page</option>
                                                <option value={50}>50 Items per page</option>
                                                <option value={100}>100 Items per page</option>
                                            </select>
                                            <div className="select-arrow">
                                                <div className="arrow-up"></div>
                                                <div className="arrow-down"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className=" wrapper">
                                    <Table striped responsive borderless className='  searched-table search-table inner share-table'>

                                        <tr>
                                            <th><input type="checkbox" onChange={handleSelectAll} checked={selectAll} className='mx-1' /></th>
                                            <th >Skill Category</th>
                                            <th>question</th>
                                            <th >action</th>
                                        </tr>

                                        <tbody>
                                            {loading ?
                                                <tr>
                                                    <td class="text-center" colSpan={10}> <div class="positioning-spinner">
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden text-center">Loading...</span>
                                                        </Spinner>
                                                    </div></td>
                                                </tr>
                                                :
                                                limitedSessions.length == 0 ?
                                                    <tr>
                                                        <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                                                    </tr> :
                                                    limitedSessions.map((item, index) => {

                                                        return (
                                                            <tr>
                                                                <td> <input
                                                                    type="checkbox"
                                                                    checked={checkboxState[item.QuestionId] || false}
                                                                    onChange={() => handleCheckboxChange(item.QuestionId)}
                                                                /></td>
                                                                <td className='line-break-cus'>{item.CategoryName}</td>
                                                                <td> {item.Questions}</td>

                                                                <td className="padding-auto-cus">
                                                                    <div style={{ display: "flex", gap: "10px" }} >
                                                                        <Link to={`/questionnaires/${item.QuestionId}`} className='search-name-text-color' >
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip id="tooltip4">Edit</Tooltip>}
                                                                            ><span>
                                                                                    <FaRegEdit className='intericon-color' size={18} />
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </Link>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip id="tooltip4">Delete</Tooltip>}
                                                                        ><span>
                                                                                <FaTrashAlt className='intericon-color color-delete-btn' onClick={() => {
                                                                                    handleDeleteQuestion(item.QuestionId);

                                                                                }}
                                                                                    size={18} />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                    </Table>
                                </div>


                                <div className='pagination-box '>
                                    <div className='pagination-showing-count-status'>
                                        {limitedSessions.length == 0 ? " " : <p>  Results: {currentPage == 1 ? totalItems > 0 ? 1 : 0 : totalItems > 0 ? ((currentPage - 1) * selectItemsPerPage) + 1 : 0} - {(currentPage - 1) == 0 ? totalItems < selectItemsPerPage ? totalItems : selectItemsPerPage : ((currentPage) * selectItemsPerPage) > totalItems ? totalItems : (currentPage) * selectItemsPerPage}  of {totalItems} </p>}
                                    </div>
                                    <div>

                                        <PaginationComponent
                                            itemsCount={totalItems}
                                            itemsPerPage={selectItemsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            alwaysShown={false}

                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div>

                </div>
            </div>
            <ModalOfferDetails show={show} onClose={handleClose} />
            <ImageViewer imgView={imgView} loading={loading} showImg={showImg} onCloseImg={handleCloseImg} />
        </div >
    )
})

export default QuestionaryTable