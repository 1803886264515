import React, { useRef, useState, useEffect } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import {
    RecordWebcam,
    useRecordWebcam,
    CAMERA_STATUS
} from 'react-record-webcam';
import './interviewpage.css'

// const OPTIONS = {
//     mimeType: 'video/webm',
//     audioBitsPerSecond: 128000,
//     videoBitsPerSecond: 5000000,
//     bitsPerSecond: 5120000
// };

const TestVideo = () => {
    const [videoBlob, setVideoBlob] = useState(null);

    // const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({
    //     video: true,
    //     audio: true,
    //     screen: false,
    //     options: OPTIONS
    // });

    const recordWebcam = useRecordWebcam({ frameRate: 60 });
    const [micDevice, setMicDevice] = useState(null);
    const [micPermission, setMicPermission] = useState(null);

    useEffect(() => {
        if (navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((micStream) => {
                    setMicPermission(true);
                    navigator.mediaDevices.enumerateDevices()
                        .then((devices) => {
                            const availableMics = devices.filter((device) => device.kind === 'audioinput');
                            for (let i = 0; i < availableMics.length; i++) {
                                if (micStream.getAudioTracks()[0].getSettings().deviceId === availableMics[i].deviceId) {
                                    setMicDevice(availableMics[i].label);
                                    break;
                                }
                            }
                        });
                })
                .catch(() => setMicPermission(false));
        }
    }, []);

    const testMic = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((micStream) => {
                alert('Mic test successful!');
                micStream.getTracks().forEach((track) => track.stop());
            })
            .catch(() => alert('Mic test failed. Please check your microphone settings.'));
    };

    const saveFile = async () => {
        const blob = await recordWebcam.getRecording();
        setVideoBlob(blob);
    };
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(0);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const animationFrameRef = useRef(null);

    useEffect(() => {
        // initialize audio context and analyzer
        audioContextRef.current = new AudioContext();
        analyserRef.current = audioContextRef.current.createAnalyser();
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const source = audioContextRef.current.createMediaStreamSource(stream);
                source.connect(analyserRef.current);
                animate();
            })
            .catch(error => {
           //     console.error(error);
            });

        // cleanup
        return () => {
            cancelAnimationFrame(animationFrameRef.current);
            audioContextRef.current.close();
        };
    }, []);

    const handleToggleMute = () => {
        setIsMuted(!isMuted);
        if (audioContextRef.current && audioContextRef.current.state === 'running') {
            audioContextRef.current.suspend();
        } else {
            audioContextRef.current.resume();
        }
    };

    const animate = () => {
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyserRef.current.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((acc, val) => acc + val, 0);
        const avg = sum / bufferLength;
        setVolume(avg);

        // request animation frame
        animationFrameRef.current = requestAnimationFrame(animate);
    };
    return (
        <div>
            <div>
                <button onClick={handleToggleMute}>
                    {isMuted ? 'Unmute' : 'Mute'}
                </button>
                <div className="progress-bar" style={{ width: `${volume}%` }} />
            </div>
            <p>Camera status: {recordWebcam.status}</p>
            <button onClick={recordWebcam.open}>Open camera</button>
            <button onClick={recordWebcam.close}>Close camera</button>
            <button onClick={recordWebcam.start}>Start recording</button>
            <button onClick={() => { recordWebcam.stop(); saveFile(); }}>Stop recording</button>
            <button onClick={recordWebcam.retake}>Retake recording</button>
            <button onClick={recordWebcam.download}>Download recording</button>
            <button onClick={testMic}>Test microphone</button>
            {micDevice && <p>Microphone: {micDevice}</p>}
            {micPermission !== null && micPermission === false && <p>Microphone permission denied.</p>}
            <video ref={recordWebcam.webcamRef} autoPlay muted={isMuted} />
            {/* <video ref={recordWebcam.previewRef} autoPlay muted loop /> */}
            {videoBlob && <video src={URL.createObjectURL(videoBlob)} controls />}
            <br />
            <br />

        </div>
    );
};

export default TestVideo;