import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import Moment from 'moment';
import { postRequest } from '../Services/api';
import Spinner from 'react-bootstrap/Spinner';
import { pdfTest } from '../datatables/pdfTest'
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ImageViewer = (props) => {

    const { showImg, onCloseImg, imgView, loading } = props;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
    return (
        <div className='image-view'>
            <Modal className='img-modal'
                show={showImg} onHide={onCloseImg}
                dialogClassName="modal-lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loading ?
                        <div>
                            <Document
                                file={imgView}
                                options={{ workerSrc: "/pdf.worker.js" }}
                                onLoadSuccess={onDocumentLoadSuccess}
                                // canvasBackground="transparent"
                            // onLoadError={console.error}
                            customTextRenderer="imman"
                            renderTextLayer="asdsad"
                            renderAnnotationLayer= ""
                            noData = {  <div class="positioning-spinner-imgView">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden text-center">Loading...</span>
                            </Spinner>
                        </div>}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                            {/* <p className='waterMark'>projectR</p> */}
                            <div className='btn-align-imgview'>
                                <div>
                                    <p>
                                        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                    </p>
                                </div>
                                <div>
                                    <button type="button" className='btn-verify' disabled={pageNumber <= 1} onClick={previousPage}>
                                        Previous
                                    </button>
                                    <button
                                        type="button"
                                        className='btn-verify m-1'
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                    >
                                        Next
                                    </button>
                                </div>

                            </div>
                        </div> 
                        :
                        <div class="positioning-spinner">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden text-center">Loading...</span>
                            </Spinner>
                        </div>



                    }


                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ImageViewer