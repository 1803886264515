import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Alert } from 'reactstrap'
import SearchedData from '../datatables/SearchedData'
import SharedData from '../datatables/SharedData'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FaChevronRight } from 'react-icons/fa'
import { FaInfoCircle, FaTimes } from 'react-icons/fa'
import { postRequest } from '../Services/api';
import SideNavbar from './SideNavbar';
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import AdminDashboard from './AdminDashboard'
import Footer from './Footer';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from 'chart.js';
import { Bar, Line, Doughnut, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-plugin-datalabels';


const Dashboard = () => {
  let tokens = useSelector((state) => state.userAuth.value);
  let LocalToken = JSON.parse(localStorage.getItem('token'))
  useEffect(() => {
    // setAuthToken(tokens)
    tokens = tokens;
  }, [tokens]);
  let rollId = LocalToken?.RollId;
  const [alert, setAlert] = useState(true);
  const closeAlert = () => {
    setAlert(!alert);
  }
  const [dashboardList, getDashboardList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [monthShareList, setMonthShareList] = useState([]);
  const [PendingCount, setPendingCount] = useState([]);
  const [PendingTotalCount, setPendingTotalCount] = useState(null);
  const [RevokeCount, setRevokeCount] = useState([]);
  const [DeclinedCount, setDeclinedCount] = useState([]);
  const [InterviewCount, setInterviewCount] = useState([]);
  const [InterviewTotalCount, setInterviewTotalCount] = useState(null);
  const [OfferedCount, setOfferedCount] = useState([]);
  const [OfferedTotalCount, setOfferedTotalCount] = useState(null);
  const [OnboardCount, setOnboardCount] = useState([]);
  const [OnboardTotalCount, setOnboardTotalCount] = useState(null);
  const [SharedCountChart, setSharedCountChart] = useState([]);
  const [SearchCountChart, setSearchCountChart] = useState([]);
  useEffect(() => {
    getDashboard();
    getChartData()
    getShareChartData()
  }, []);
  const getDashboard = async () => {
    var res = await postRequest('api/Profile/GetDashboard');
    getDashboardList(JSON.parse(res?.data?.Data));
  }
  const getChartData = async () => {
    var res = await postRequest(`api/ScheduleInterview/StatusChart?CompanyId=${LocalToken.CompanyId}`);
    let data = JSON.parse(res?.data?.Data)
    let month = data?.map((item) => item?.Month?.slice(0, 3) + " " + item?.YEARS)
    
    setMonthList(month[0]=== "undefined null"?"":month)
    setPendingTotalCount(data.reduce((accumulator, object) => {
      return accumulator + object.PendingCount;
    }, 0));
    setInterviewTotalCount(data.reduce((accumulator, object) => {
      return accumulator + object.InterviewCount;
    }, 0));
    setOnboardTotalCount(data.reduce((accumulator, object) => {
      return accumulator + object.OnboardCount;
    }, 0));
    setOfferedTotalCount(data.reduce((accumulator, object) => {
      return accumulator + object.OfferedCount;
    }, 0));


    setPendingCount(data.map((item) => item?.PendingCount))
    setOnboardCount(data.map((item) => item?.OnboardCount))
    setRevokeCount(data.map((item) => item?.RevokeCount))
    setDeclinedCount(data.map((item) => item?.DeclinedCount))
    setOfferedCount(data.map((item) => item?.OfferedCount))
    setInterviewCount(data.map((item) => item?.InterviewCount))

    // getDashboardList(JSON.parse(res?.data?.Data));
  }
  const getShareChartData = async () => {
    var res = await postRequest(`api/ScheduleInterview/ShareCount?CompanyId=${LocalToken.CompanyId}`);
    let data = JSON.parse(res?.data?.Data)
    let month = data?.map((item) => item?.Month?.slice(0, 3) + " " + item?.YEARS)
    
    setMonthShareList(month)
    setSharedCountChart(data.map((item) => item?.SharedCount))
    setSearchCountChart(data.map((item) => item?.SearchCount))


    // getDashboardList(JSON.parse(res?.data?.Data));
  }
  let message = ' '
  let AvailablePoints = 0;
  let SearchCount = 0;
  let SharedCount = 0;
  let companyName = '';
  let bonusPoints = 0;
  if (dashboardList != null && rollId >= 2 && dashboardList.CompanyData) {
    message = 'Your trial bonus will expire in ' + dashboardList.CompanyData.BalanceDays + ' days.';
    AvailablePoints = dashboardList.CompanyData.AvailablePoints;
    SearchCount = dashboardList.CompanyData.SearchCount;
    SharedCount = dashboardList.CompanyData.SharedCount;
    companyName = dashboardList.CompanyData.CompanyName;
    bonusPoints = dashboardList.CompanyData.BonusPoints
  }
  if (dashboardList?.CompanyData?.BalanceDays < 0) {
    message = 'Your trial period has expired'
    bonusPoints = 0
  }


  const data = {
    labels: monthList,
    datasets: [
      {
        label: 'Interview',
        data: InterviewCount,

        backgroundColor: ' #e0701a73',
        borderColor: '#E0701A',
        borderWidth: 1,
      },
      {
        label: 'Pending',
        data: PendingCount,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Offered',
        data: OfferedCount,
        backgroundColor: '#27a2434d ',
        borderColor: ' #27A243',
        borderWidth: 1,
      },
      {
        label: 'Onboard',
        data: OnboardCount,
        backgroundColor: ' #feeecd',
        borderColor: '#FAC144',
        borderWidth: 1,
      },
      {
        label: 'Declined',
        data: DeclinedCount,
        backgroundColor: '#f5bcbc',
        borderColor: ' #de2121',
        borderWidth: 1,
      },
      {
        label: 'Revoke',
        data: RevokeCount,
        backgroundColor: '#d1eafa',
        borderColor: '#5db4ef',
        borderWidth: 1,
      },
     

      
      
    ],
  };
  const data2 = {
    labels: monthShareList,
    datasets: [
      {
        label: 'Search profile',
        data: SearchCountChart,
        backgroundColor: '#112b3c3d',
        borderColor: '#112B3C ',
        borderWidth: 1,
      },
      {
        label: 'Share profile',
        data: SharedCountChart,
        backgroundColor: ' #e0701a73',
        borderColor: '#E0701A',
        borderWidth: 1,
      },

    ],
  };

  const data1 = {
    labels: ['Interview ','Pending ', 'Offered ','Onboard ',  ],
    datasets: [
      {
        label: 'Total count',
        data: [InterviewTotalCount,PendingTotalCount, OfferedTotalCount, OnboardTotalCount ],
        backgroundColor: [
          '#e0701a73',
          'rgba(75, 192, 192, 0.2)',
          '#27a2434d',
          '#feeecd',
          
          
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          '#E0701A',
          'rgba(75, 192, 192, 1)',
          '#27A243',
          '#FAC144',
          
         
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const maxDataValue = Math.max(
    ...data.datasets.map((dataset) => Math.max(...dataset.data))
  );
  const suggestedMaxValue = maxDataValue > 50 ? maxDataValue : 50;
  const maxDataValue1 = Math.max(
    ...data2.datasets.map((dataset) => Math.max(...dataset.data))
  );
  const suggestedMaxValue1 = maxDataValue1 > 50 ? maxDataValue1 : 50;

  const chartHeight = 400;
  const chartHeight1 = 400;
  const options1 = {
    maintainAspectRatio: false, // Disable the default aspect ratio
    responsive: true, // Make the chart responsive
    plugins: {
      legend: {
        display: true, // Set to true or false based on your requirements
      },
      
    },
    scales: {
      y: {
        // min: 0, // Set the minimum value for the y-axis
        // max: , // Set the maximum value for the y-axis
        suggestedMax: maxDataValue1,
        stepSize: maxDataValue1 > 50 ? undefined : 5,
        max: maxDataValue1 > 50 ? undefined : 50,
      },
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
    },
    // Set the height of the chart
    // The height value should be a number, not a string
    // You can adjust the chartHeight variable as needed
    height: chartHeight,
  };

  const options2 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            const dataset = context.dataset || {};
            const total = dataset.data.reduce((acc, val) => acc + val, 0);
            const percentage = ((value / total) * 100).toFixed(2);
            return ` ${label} ${value} `;
          },
        },
      },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold',
        },
        formatter: (value, ctx) => {
          const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
          const total = dataset.data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(2);
          return `${percentage}%`;
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
    },
    height: chartHeight1,
  };





  const options = {
    maintainAspectRatio: false, // Disable the default aspect ratio
    responsive: true, // Make the chart responsive
    plugins: {
      legend: {
        display: true, // Set to true or false based on your requirements
      },
    },
    scales: {
      y: {
        // min: 0, // Set the minimum value for the y-axis
        // max: , // Set the maximum value for the y-axis
        suggestedMax: suggestedMaxValue,
        stepSize: suggestedMaxValue > 50 ? undefined : 5,
        max: suggestedMaxValue > 50 ? undefined : 50,
      },
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
      backgroundColor:"#fff",
    },
    // Set the height of the chart
    // The height value should be a number, not a string
    // You can adjust the chartHeight variable as needed
    height: chartHeight,
  };
  return (
    <SideNavbar >
      <Outlet />
      {
        rollId == 2 || rollId == 3 ?
          <div className='bg-dashboard'>
            <Container fluid className='p-0 g-0' >
              <Row className='g-0'>
                <Col lg={6} xs={12} sm={12} className="mob-none">
                  <div className='breadcrumps-box'>
                    <div className='breadcrump-heading'>
                      <p>Dashboard</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} xs={12} sm={12} className='breadcrumps-box  d-flex justify-content-end '>
                  <Breadcrumbs separator={<FaChevronRight className='text-white-breadcrums ' style={{ fontSize: "15px" }} />} >
                    {/* <Link to='/' className='breadcrumps-links breadcrumps-links'>Home</Link> */}
                    <Typography className='text-white-breadcrums cus-mr-5' >Dashboard</Typography>

                  </Breadcrumbs>
                </Col>
              </Row>
            </Container>

            <div>
              <Container fluid className='p-0'>
                <Row>
                  <Col lg={12}>

                    <div>
                      <div className='mt-2'>
                        <Container fluid>
                          <Row>
                            <Col>
                              {alert && <Alert className='alert-points-box  m-0 my-2'>
                                <FaInfoCircle style={{ fontSize: "24px" }} /><span style={{ fontSize: "14px" }}>{message}</span>
                                <FaTimes className='close-alert-notification' onClick={closeAlert} />
                              </Alert>}

                            </Col>
                          </Row>
                        </Container>
                      </div>
                      <Container fluid className='px-4 py-2'>
                        <Row className='g-lg-5 g-md-2 g-3'>

                          <Col md={12} lg={4} sm={12}>
                            <div className=' count-status  '>
                              <div className='count-box'>
                                <div className='available-count-heading'>
                                  <h4>Available Points</h4>
                                  <p>Total number of available points</p>

                                </div>
                                <div className='available-count-status'>
                                  <h1>{AvailablePoints + bonusPoints} </h1>
                                </div>
                              </div>
                              <div>
                                <p className='cart-content'>(Earned points {AvailablePoints} + Trial points {bonusPoints})</p>
                              </div>
                            </div>


                          </Col>
                          <Col md={12} lg={4} sm={12}>
                            <div className=' count-status  '>
                              <div className='count-box'>
                                <div className='available-count-heading'>
                                  <h4>Search Profile</h4>
                                  <p>Total number of searched profiles</p>

                                </div>
                                <div className='available-count-status'>
                                  <h1>{SearchCount} </h1>
                                </div>
                              </div>
                              <div>
                                <p className='cart-content '>Search candidate with Mobile number / Email</p>
                              </div>
                            </div>


                          </Col>
                          <Col md={12} lg={4} sm={12}>
                            <div className=' count-status  '>
                              <div className='count-box'>
                                <div className='available-count-heading'>
                                  <h4>Share Profile</h4>
                                  <p>Total number of shared profiles</p>

                                </div>
                                <div className='available-count-status'>
                                  <h1>{SharedCount} </h1>
                                </div>
                              </div>
                              <div>
                                <p className='cart-content'>Share candidate profile to earn search points</p>
                              </div>
                            </div>


                          </Col>

                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div>
              <Container fluid className='mt-2'>
                <Row>
                  <Col lg={12} sm={12} className='h-50 mb-5'>
                    <div className='py-2 status-chart-heading' style={{ height: "400px", backgroundColor: "#fff" }}>
                      <h3 className=' px-4 '>STATUS</h3>
                      <Bar data={data} options={options} />
                    </div>

                  </Col>

                  <Col lg={6} sm={12} className='p-lg-4 mb-5'>
                    <div className='py-2 status-chart-heading' style={{ height: "400px", backgroundColor: "#fff" }}>
                      <h3 className=' px-4 '>OFFER STATUS</h3>
                      <Pie data={data1} options={options2} />
                    </div>
                  </Col>
                  <Col lg={6} sm={12} className='p-lg-4 mb-5'>
                    <div className='py-2 status-chart-heading' style={{ height: "400px", backgroundColor: "#fff" }}>
                    <h3 className=' px-4 '>POINT USAGE</h3>
                      <Line data={data2} options={options1} />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div>
              <Container fluid className='mt-2'>
                <Row>
                  <Col lg={12}>
                    <SearchedData />
                  </Col>
                </Row>
              </Container>
            </div>
            <div>
              <Container fluid className='mt-4'>
                <Row>
                  <Col lg={12}>
                    <SharedData />
                  </Col>
                </Row>
              </Container>
            </div>
            <Footer />
          </div>
          : <AdminDashboard />
      }

    </SideNavbar>
  )
}

export default Dashboard